import React from 'react'

const GradiantCard = (props) => {
  return (
    <div className='border border-dark-gray rounded-xl gradiant-card-bg h-60 w-full'>
        {props.children}
    </div>
  )
}

export default GradiantCard