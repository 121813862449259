import React from 'react'

const ImageWithDescriptionCard = ({ title, description, image }) => {
    return (
        <div className='w-full space-y-16 font-Sanspro md:pr-14'>
            <div className='w-full space-y-7'>
                <h1 className='text-2xl font-Publico font-bold'>{title}</h1>
                <p>{description}</p>
            </div>
            <div className='w-full h-64 relative object-contain aspect-auto'>
                <img src={`/images/staticPage/${image}`} className='' alt="digital security" />
            </div>
        </div>
    )
}

export default ImageWithDescriptionCard