import React from 'react'
import CardSlider from '../common/card-slider'

const MenuList = () => {
    const menuList = {
        1: {
            name: "My transaction",
            path: ""
        },
        2: {
            name: "Help & Support",
            path: ""
        },
        3: {
            name: "Friend finder",
            path: ""
        },
    }
    return (
        <CardSlider>
            {Object.entries(menuList).map((menu) => (
                <div className='flex items-center space-x-2 py-2 px-3.5 rounded-xl border border-medium-gray text-slate-700' key={menu[0]}>
                    <div className='h-5 w-5 rounded-full bg-dark-gray' />
                    <p className='text-sm min-w-max'>{menu[1].name}</p>
                </div>
            ))}
        </CardSlider>
    )
}

export default MenuList