import React, { useContext } from 'react'
import Header from '../components/staticPage/header'
import ImageWithDescriptionCard from '../components/staticPage/imageWithDescriptionCard'
import DownloadSectionCard from '../components/staticPage/downloadSectionCard'
import ContentSection from '../components/staticPage/contentSection'
import CardWrapper from '../components/staticPage/cardWrapper'
import Footer from '../components/staticPage/footer'
import { DataContext } from '../contexts/dataContext'
import { InnerHeader } from '../components/common/inner-header';

const DigitalSecurityRating = () => {

  const context = useContext(DataContext);

  let mainHead = context?.siteData?.digitalSecurityRating?.header?.mainHead ?? ''
  let subHead = context?.siteData?.digitalSecurityRating?.header?.subHead ?? ''
  let imageDescriptionCardTitle = context?.siteData?.digitalSecurityRating?.imageWithDescriptionCard?.title ?? ''
  let imageDescriptionCardDescription = context?.siteData?.digitalSecurityRating?.imageWithDescriptionCard?.description ?? ''
  let imageDescriptionCardImage = context?.siteData?.digitalSecurityRating?.imageWithDescriptionCard?.image ?? 'digitalSecurityRating.png'
  let DownloadSectionCardTitle = context?.siteData?.digitalSecurityRating?.downloadSectionCard?.title ?? ''
  let DownloadSectionCardDescription = context?.siteData?.digitalSecurityRating?.downloadSectionCard?.description ?? ''
  let DownloadSectionCardBorderColor = context?.siteData?.digitalSecurityRating?.downloadSectionCard?.borderColor ?? 'blueGreen'
  let ContentSectionSec1Content = context?.siteData?.digitalSecurityRating?.ContentSection?.sec1Content ?? ''
  let ContentSectionSec2Content = context?.siteData?.digitalSecurityRating?.ContentSection?.sec2Content ?? ''
  let footerContent = context?.siteData?.digitalSecurityRating?.footerContent ?? ''
  let cardsData = context?.siteData?.digitalSecurityRating?.cardsData ?? []
  let appStoreLink = context?.siteData?.common?.appStoreLink ?? ''
  let playStoreLink = context?.siteData?.common?.playStoreLink ?? ''

  return (
    <div className='w-full'>
        <InnerHeader
          hasBackBtn={true}
          hasHomeBtn={true}
        />
      <Header
        mainHead={mainHead}
        subHead={subHead}
      />
      <div className='w-full'>
        <div className='w-full bg-gray-100 grid justify-center'>
          <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 py-16 max-w-5xl px-5'>
            <ImageWithDescriptionCard
              title={imageDescriptionCardTitle}
              description={imageDescriptionCardDescription}
              image={imageDescriptionCardImage}
            />
            <DownloadSectionCard
              title={DownloadSectionCardTitle}
              description={DownloadSectionCardDescription}
              borderColor={DownloadSectionCardBorderColor}
              appStoreLink={appStoreLink}
              playStoreLink={playStoreLink}
            />
          </div>
        </div>
        <ContentSection
          sec1Content={ContentSectionSec1Content}
          sec2Content={ContentSectionSec2Content}
        />
        <CardWrapper
          cardsData={cardsData}
        />
        <Footer
          footerContent={footerContent}
        />
      </div>
    </div>
  )
}

export default DigitalSecurityRating