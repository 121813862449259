import React, { useContext, useState, useEffect } from 'react'
import ResultPageBtns from './result-page-btns'
import ResultWithMsg from './result-with-msg'
import { DataContext } from '../../../contexts/dataContext';

const AssessmentResult = (props) => {
  const context = useContext(DataContext);

  //site data
  const cyberSafetyAssessmentResultLoadingMsg = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentResultLoadingMsg ?? ''
  const errMsg = context?.siteData?.common?.defaultErrorMsg ?? ''
  const draEmailMessageFail = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draEmailMessageFail ?? ''
  const draEmailMessageSuccess = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draEmailMessageSuccess ?? ''
  const draEmailBtnText = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draEmailBtnText ?? ''

  //site styles
  const cyberSafetyAssessmentResultLoadingMsgCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentResultLoadingMsgCol ?? 'simple-gray'
  const draEmailBgOverlay = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailBgOverlay ?? 'simple-gray'
  const draEmailBgAlert = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailBgAlert ?? 'white'
  const draEmailBtnStyle = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailBtnStyle ?? 'orange-border-btn'

  let emailBgOverlay = `bg-${draEmailBgOverlay}`
  let emailBgAlert = `bg-${draEmailBgAlert}`

  const cyberSafetyAssessmentResultLoadingMsgColor = `text-${cyberSafetyAssessmentResultLoadingMsgCol}`

  const { isPass, resultMsg, description, isResultVerified, isResultApiFailed, handleRestartAssessment, isResultToMail, assessmentResultMailPreview, mailsendingstatus } = props;

  const [sendMailFail, setPopupMessage] = useState(mailsendingstatus);

  const [message, setMessage] = useState(mailsendingstatus ? draEmailMessageSuccess : draEmailMessageFail);

  useEffect(() => {
    setPopupMessage(mailsendingstatus);
    setMessage(mailsendingstatus ? draEmailMessageFail : draEmailMessageSuccess);
  }, [mailsendingstatus, draEmailMessageFail, draEmailMessageSuccess]);

  const handlePopupVisibility = () => {
    setPopupMessage(undefined);
    setMessage("");
  };
  useEffect(() => {
    document.body.style.overflow = sendMailFail !== undefined ? "hidden" : "auto";
  }, [sendMailFail])


  return (
    <div className='w-full py-10'>
      <div>
        {(sendMailFail !== undefined && isResultToMail) && (
          <div className={`fixed inset-0 flex items-center justify-center z-50  ${emailBgOverlay} bg-opacity-50`}>
            <div className={`${emailBgAlert} rounded-lg shadow-lg p-4 w-300 m-5`}>
              <p>{message}</p>
              <div className="mt-4 flex justify-center">
                <button className={draEmailBtnStyle}
                  onClick={handlePopupVisibility} >{draEmailBtnText}</button>
              </div>
            </div>
          </div>
        )}
      </div>
      {
        !isResultApiFailed ?
          isResultVerified ?
            <div className='w-full space-y-12'>
              {!isResultToMail ?
                <ResultWithMsg
                  isPass={isPass}
                  resultMsg={resultMsg}
                  description={description}
                />
                :
                <div className='w-full' dangerouslySetInnerHTML={{ __html: assessmentResultMailPreview }} />
              }
              <ResultPageBtns
                isPass={isPass}
                handleRestartAssessment={handleRestartAssessment}
              />
            </div>
            :
            <h1 className={`${cyberSafetyAssessmentResultLoadingMsgColor} font-semibold text-lg text-center`}>{cyberSafetyAssessmentResultLoadingMsg}</h1>
          :
          <h1 className={`text-err-text font-semibold text-lg text-center`}>{errMsg}</h1>
      }
    </div>
  )
}

export default AssessmentResult