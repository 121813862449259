import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/layouts/layout';
import PageNotFound from './pages/page_not_found';
import DigitalSafety from './pages/cyber_training/digital-safety';
import OnlineRisks from './pages/cyber_training/online-risks';
import SensitiveData from './pages/cyber_training/sensitive-data';
import CyberTraining from './pages/cyber_training';
import InsuranceCoverage from './pages/insurance_coverage';
import DigitalRiskAssessment from './pages/digital_risk_assessment';
import PrivateRoute from './hoc-auth/PrivateRoute';
import CheckBreachesResults from './pages/check_for_breaches/results';
import CheckForBreaches from './pages/check_for_breaches';
import AssessmentPg from './pages/digital_risk_assessment/assessment';
import UserProfile from './pages/user_profile';
import Home from './pages/home';
import TeamActivityReview from './pages/cyber_training/team-activity-review';
import WorkFromHome from './pages/cyber_training/wfh';
import DigitalSecurityRating from './pages/digital_security_rating';
import DarkWebMonitoring from './pages/dark_web_monitoring';
import LoginSignup from './pages/login-signup';
import TermsAndConditions from './pages/terms_and_conditions';
import PrivacyPolicy from './pages/privacy_policy';
import CookiePolicy from './pages/cookie_policy';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LoginSignup />} />
          <Route path='/home' element={<PrivateRoute><Home/></PrivateRoute>} />
          <Route path='/profile' element={<PrivateRoute><UserProfile/></PrivateRoute>} />
          <Route path='/check_for_breaches' element={<PrivateRoute><CheckForBreaches /></PrivateRoute>} />
          <Route path='/check_for_breaches/results' element={<PrivateRoute><CheckBreachesResults /></PrivateRoute>} />
          <Route path='/cyber_training' element={<PrivateRoute><CyberTraining/></PrivateRoute>} />
          <Route path='/digital_security_rating' element={<PrivateRoute><DigitalSecurityRating/></PrivateRoute>} />
          <Route path='/dark_web_monitoring' element={<PrivateRoute><DarkWebMonitoring/></PrivateRoute>} />
          <Route path='/cyber_training/team-activity-review' element={<PrivateRoute><TeamActivityReview/></PrivateRoute>} />
          <Route path='/cyber_training/digital-safety' element={<PrivateRoute><DigitalSafety/></PrivateRoute>} />
          <Route path='/cyber_training/online-risks' element={<PrivateRoute><OnlineRisks/></PrivateRoute>} />
          <Route path='/cyber_training/sensitive-data' element={<PrivateRoute><SensitiveData/></PrivateRoute>} />
          <Route path='/cyber_training/wfh' element={<PrivateRoute><WorkFromHome/></PrivateRoute>} />
          <Route path='/insurance_coverage' element={<PrivateRoute><InsuranceCoverage/></PrivateRoute>} />
          <Route path='/digital_risk_assessment' element={<PrivateRoute><DigitalRiskAssessment/></PrivateRoute>} />
          <Route path='/digital_risk_assessment/assessment' element={<PrivateRoute><AssessmentPg/></PrivateRoute>} />
          <Route path='/terms_and_conditions' element={<TermsAndConditions/>} />
          <Route path='/privacy_policy' element={<PrivacyPolicy/>} />
          <Route path='/cookie_policy' element={<CookiePolicy/>} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
