import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { DataContext } from '../../contexts/dataContext';

const Card = (props) => {
    const { header, image, description, link } = props;

    const context = useContext(DataContext);

    //site data
    const cyberSafetyCardBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyCardBtnText ?? ''
    const cyberSafetyCommonModlueDetails = context?.siteData?.cyberSafetyTraining?.cyberSafetyCommonModlueDetails ?? ''

    //site style 
    const cardBgCol = context?.siteStyles?.cyberSafetyTraining?.cardBgCol ?? 'medium-light-blue'
    const cardHeadTextCol = context?.siteStyles?.cyberSafetyTraining?.cardHeadTextCol ?? 'shaded-white'
    const cardContentTextCol = context?.siteStyles?.cyberSafetyTraining?.cardContentTextCol ?? 'shaded-white'
    const cardLinkTextCol = context?.siteStyles?.cyberSafetyTraining?.cardLinkTextCol ?? 'shaded-white'
 
    const cardBgColor = `bg-${cardBgCol}`
    const cardHeadTextColor = `text-${cardHeadTextCol}`
    const cardContentTextColor = `text-${cardContentTextCol}`
    const cardLinkTextColor = `text-${cardLinkTextCol}`

    return (
        <div className={`w-full  pl-5 pr-2 py-2.5 space-y-3 ${cardBgColor}`}>
            <h1 className={`text-lg ${cardHeadTextColor} font-Publico font-normal`}>{header}</h1>
            <div className='flex items-start space-x-3 font-SansPro'>
                <div className='w-20 h-20 min-w-[80px]'>
                    <img src={`/images/${image}`} alt={image} />
                </div>
                <div className='w-full space-y-2 flex flex-col justify-between'>
                    <div className={`text-xs font-normal ${cardContentTextColor}`}>
                        <p>{description}</p>
                        {cyberSafetyCommonModlueDetails && <p className='pt-3'>{cyberSafetyCommonModlueDetails}</p>}
                    </div>
                    <div className='inline-block'>
                        <Link to={`/cyber_training${link !== "" ? `/${link}` : ``}`}>
                            <div className='inline-flex items-center space-x-3'>
                                <p className={`font-normal text-xs ${cardLinkTextColor} uppercase`}>{cyberSafetyCardBtnText}</p>
                                <img src={`/images/eastaxa.png`} className='h-3.5 w-3.5 object-contain' alt={'arrow circle'} />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card