import { Fragment, useContext, useRef, useState } from "react"
import { DataContext } from "../../contexts/dataContext";
import LoaderEllipse from "../common/loader-ellipse";
import api from "../../api/axiosConfig";
import { header } from '../../helpers/constants'
import { localStorageUtils } from "../../helpers/storageHelper";

const ForgotPassword = ({ toggleForm }) => {

    const context = useContext(DataContext);
    const formData = context.siteData?.axaSpecific?.ForgotPasswordForm?.formData ?? []
    const otpFormData = context.siteData?.axaSpecific?.ForgotPasswordOtpForm?.formData ?? []
    const passwordFormData = context.siteData?.axaSpecific?.ForgotPasswordPasswordForm?.formData ?? []
    const { backToLoginTxt = "", submitBtnTxt = "", submitOtpBtnTxt = "", resendVerificationBtnTxt: resendVerification = "", resetPasswordBtnTxt = "" } = context.siteData?.axaSpecific?.ForgotPasswordForm || {};
    const defaultConnectionErrorMsg = context?.siteData?.common?.defaultConnectionErrorMsg ?? ''
    const { formWrapperBg = 'cyan-blue', formBg = 'white', inputBg = 'input-bg', inputHoverBg = 'input-hover-bg', invalidInputBgCol = 'extra-lite-red', loginSignUpBtnCol = 'btn-blue', loginSignUpBtnHoverCol = 'dark-blue', formTitleCol = 'extra-dark-gray', formTitleDescriptionCol = 'dark-gray', loginSignUpBtnTextCol = 'white', infoTextCol = 'dark-gray', placeholderCol = 'extra-dark-gray', inputTextCol = 'extra-dark-gray', } = context.siteStyles?.axaSpecific || {};
    const styles = {
        formWrapperBackground: `bg-${formWrapperBg}`,
        formBackground: `bg-${formBg}`,
        formTitleColor: `text-${formTitleCol}`,
        formTitleDescriptionColor: `text-${formTitleDescriptionCol}`,
        infoTextColor: `text-${infoTextCol}`,
        inputBackground: `bg-${inputBg}`,
        inputHoverBackground: `hover:bg-${inputHoverBg}`,
        invalidInputBgColor: `invalid:bg-${invalidInputBgCol}`,
        loginSignUpBtnColor: `bg-${loginSignUpBtnCol}`,
        loginSignUpBtnHoverColor: `bg-${loginSignUpBtnHoverCol}`,
        loginSignUpBtnTextColor: `text-${loginSignUpBtnTextCol}`,
        placeholderColor: `placeholder:text-${placeholderCol}`,
        inputTextColor: `text-${inputTextCol}`,
        inputErrorBorderCol: context?.siteStyles?.common?.inputs?.errTextCol ?? 'err-input-border',
        inputBorderCol: context?.siteStyles?.common?.inputs?.selectBorderCol ?? 'input-border',
        inputErrorBorderColor: `border-${context?.siteStyles?.common?.inputs?.errTextCol ?? 'err-input-border'}`,
        inputBorderColor: `border-${context?.siteStyles?.common?.inputs?.selectBorderCol ?? 'input-border'}`,
        validationErrTextCol: context?.siteStyles?.common?.inputs?.validationErrTextCol ?? 'err-text',
        validationErrTextColor: `text-${context?.siteStyles?.common?.inputs?.validationErrTextCol ?? 'err-text'}`,
    };
    const [passwordFormRequirments, setPasswordFormRequirments] = useState(context.siteData?.axaSpecific?.ForgotPasswordPasswordForm?.requirments ?? [])
    const isPasswordFormSubmitDisabled = !passwordFormRequirments.every((requirement) => requirement.isMet);
    const [formTitle, setFormTitle] = useState(context.siteData?.axaSpecific?.ForgotPasswordForm?.title ?? "")
    const [description, setDescription] = useState(context.siteData?.axaSpecific?.ForgotPasswordForm?.description ?? "")
    const [isValid, setIsValid] = useState(true)
    const [invalidList, setInvalidList] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isOverlayLoading, setIsOverlayLoading] = useState(false);
    const [whichForm, setWhichForm] = useState('email')
    const [accessToken, setAccessToken] = useState('');
    const [userForgotDetails, setUserForgotDetails] = useState({ email: null })
    const [userOtpDetails, setUserOtpDetails] = useState({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', });
    const [userPasswordDetails, setUserPasswordDetails] = useState({ password: null, confirm: null })
    const inputRefs = useRef([]);
    const language = localStorageUtils.getLocalStoragItem('lang') ?? "EN"
    const [passwordVisibility, setPasswordVisibility] = useState({});
    const [isActive, setIsActive] = useState(true);

    function handlEmailInputs(evt) {
        let tempInvalidList = [...invalidList];
        setUserForgotDetails((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value
        }))
        setIsValid(true)
        if (invalidList.includes(evt.target.name)) {
            tempInvalidList.splice(tempInvalidList.indexOf(evt.target.name), 1)
            setInvalidList(tempInvalidList)
        }
    }

    function handleOtpInputs(evt, index) {
        const otpValue = evt.target.value;
        const numericValue = otpValue.replace(/\D/g, '');
        const otpMaxLength = otpFormData[0]?.maxLength ?? 6;
        const truncatedValue = numericValue.slice(0, otpMaxLength);
        evt.target.value = truncatedValue;
        const { name, value } = evt.target;
        setUserOtpDetails({
            ...userOtpDetails,
            [name]: value,
        });
        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
        setIsValid(true)
    }

    function handlePasswordInputs(evt) {
        let tempInvalidList = [...invalidList];
        const password = evt.target.value;
        if (evt.target.name === 'password') {
            const updatedRequirements = passwordFormRequirments.map((req) => {
                if (req.requirement) {
                    const regexPattern = new RegExp(req.requirement);
                    return {
                        ...req,
                        isMet: regexPattern.test(password),
                    };
                } else {
                    return {
                        ...req,
                        isMet: false,
                    };
                }
            });
            setPasswordFormRequirments(updatedRequirements)
        }
        if (evt.target.name === 'confirmPassword') {
            const indexToUpdate = passwordFormRequirments.findIndex((req) => req.isPasswordMatching === true);
            if (indexToUpdate !== -1) {
                passwordFormRequirments[indexToUpdate] = {
                    ...passwordFormRequirments[indexToUpdate],
                    isMet: userPasswordDetails.password === password ? true : false,
                };
            }
            setPasswordFormRequirments(passwordFormRequirments)
        }
        setUserPasswordDetails((prevState) => ({
            ...prevState,
            [evt.target.name]: evt.target.value
        }))
        setIsValid(true)
        if (invalidList.includes(evt.target.name)) {
            tempInvalidList.splice(tempInvalidList.indexOf(evt.target.name), 1)
            setInvalidList(tempInvalidList)
        }
    }

    function handleFormSubmit(evt) {
        evt.preventDefault();
        setIsLoading(true);
        setIsValid(true);
        api.post('auth/user/request-verification-code', userForgotDetails, { headers: { 'Accept-Language': language } }).then((res => {
            if (res.status === 200) {
                setWhichForm('otp')
                setFormTitle(context.siteData?.axaSpecific?.ForgotPasswordOtpForm?.title ?? 'Verify your account')
                setDescription(context.siteData?.axaSpecific?.ForgotPasswordOtpForm?.description ?? "To continue, please enter the verification code we sent to your provided email address.")
                setIsLoading(false)
            }
        })).catch((err) => {
            setIsLoading(true);
            document.getElementById('validation_error').innerText = err?.response?.data?.message?.message ?? defaultConnectionErrorMsg
            setIsValid(false)
            setIsLoading(false);
        });
    }

    function handleOtpSubmit(evt) {
        evt.preventDefault();
        setIsLoading(true);
        setIsValid(true);
        const otpMaxLength = otpFormData[0]?.maxLength || 6;
        const otpPattern = new RegExp(`^[0-9]{${otpMaxLength}}$`);
        const enteredOtp = Object.values(userOtpDetails).join('');
        if (otpPattern.test(enteredOtp)) {
            api.post('/auth/user/verify-code', { code: enteredOtp, ...userForgotDetails }, { headers: { 'Accept-Language': language } })
                .then((res) => {
                    if (res.status === 200) {
                        const { data } = res.data;
                        setAccessToken(data.token?.accessToken)

                        setWhichForm('password');
                        setFormTitle(
                            context.siteData?.axaSpecific?.ForgotPasswordPasswordForm?.title ?? 'Reset password'
                        );
                        setDescription(
                            context.siteData?.axaSpecific?.ForgotPasswordPasswordForm?.description ??
                            'Set the new password for your account so you can login and access all the features.'
                        );
                        setIsLoading(false);
                    }
                })
                .catch((err) => {
                    setIsLoading(false);
                    document.getElementById('validation_error').innerText = err?.response?.data?.message?.message ?? context.siteData?.axaSpecific?.ForgotPasswordForm?.validationOTPTxt
                    setIsValid(false)
                    setUserOtpDetails({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', });
                });
        } else {
            setIsLoading(false);
            document.getElementById('validation_error').innerText = context.siteData?.axaSpecific?.ForgotPasswordForm?.validationOTPTxt ?? 'Invalid OTP.'
            setIsValid(false)
        }
    }

    function resendVerificationMail() {
        if (!isActive) {
            return;
        }
        setIsOverlayLoading(true);
        setUserOtpDetails({ otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', });
        setIsValid(true)
        document.getElementById('validation_error').innerText = '';
        api.post('/auth/user/resend-verification-code', userForgotDetails, { headers: { 'Accept-Language': language } }).then((res) => {
            if (res.status === 200) {
                setIsOverlayLoading(false);
            }
        }).catch((err) => {
            setIsOverlayLoading(false);
            setIsValid(false)
        });
        setIsActive(false);
        setTimeout(() => {
            setIsActive(true);
        }, 30000);
    }

    function handlePasswordSubmit(evt) {
        evt.preventDefault();
        setIsLoading(true);
        setIsValid(true);
        if (!isPasswordFormSubmitDisabled && accessToken !== null) {
            let newHeader = { ...header, "acessToken": accessToken, 'Accept-Language': language }
            api.post('/auth/user/reset-password', userPasswordDetails, { headers: newHeader }).then((res) => {
                if (res.status === 200) {
                    toggleForm(evt);
                }
            }).catch((err) => {
                setIsLoading(false);
                document.getElementById('validation_error').innerText = err?.response?.data?.message?.message ?? defaultConnectionErrorMsg
                setIsValid(false)
            });
        } else {
            document.getElementById('validation_error').innerText = context.siteData?.axaSpecific?.ForgotPasswordForm?.validationTxt ?? 'Form submission blocked because not all requirements are met.'
            setIsValid(false)
            setIsLoading(false);
        }
    }

    const togglePasswordVisibility = (inputFieldId) => {
        setPasswordVisibility((prevState) => ({
          ...prevState,
          [inputFieldId]: !prevState[inputFieldId] || false,
        }));
      };

    function SwitchForms(form) {
        switch (form) {
            case 'email':
                return (
                    <>
                        <form onSubmit={(e) => handleFormSubmit(e)} className='grid gap-3'>
                            {
                                formData.map((inputField, key) => {
                                    return <input tabIndex={inputField.id}
                                        key={inputField.id}
                                        value={userForgotDetails[inputField.name] ?? ''}
                                        onInvalid={e => e.target.setCustomValidity(inputField.validationText)}
                                        onInput={e => e.target.setCustomValidity('')}
                                        onChange={(e) => handlEmailInputs(e)}
                                        autoComplete="off"
                                        autoFocus={key === 0 ? true : false}
                                        type={inputField.inputType}
                                        name={inputField.name}
                                        id={inputField.id}
                                        placeholder={inputField.placeholder}
                                        required={inputField.required}
                                        className={`${styles.placeholderColor} ${styles.inputTextColor} h-14 w-full  ${invalidList.includes(inputField.name) ? `border-b-2 ${styles.inputErrorBorderColor} ${styles.invalidInputBgColor}` : `${styles.inputBorderColor} ${styles.inputBackground} ${styles.inputHoverBackground}`} ${styles.placeholderColor} ${styles.inputTextColor} font-semibold placeholder:font-semibold focus:outline-none px-4`}
                                    />
                                })
                            }
                            <button tabIndex={3} disabled={isLoading} className={`w-full  h-14 hover:${styles.loginSignUpBtnHoverColor} ${styles.loginSignUpBtnColor}  ${styles.loginSignUpBtnTextColor}`}>
                                {isLoading ? <LoaderEllipse /> : submitBtnTxt}
                            </button>
                        </form>
                        <div className={`grid place-items-center ${styles.infoTextColor} gap-1 text-center`}>
                            <p><button onClick={(e) => toggleForm(e)} className='underline'>{backToLoginTxt}</button></p>
                        </div>
                    </>
                )
            case 'otp':
                return (
                    <>
                        <form onSubmit={(e) => handleOtpSubmit(e)} className='grid gap-3'>
                            <div className="flex items-center">
                                {Array.from({ length: 6 }).map((_, key) => (
                                    <Fragment key={key}><input
                                        ref={(el) => (inputRefs.current[key] = el)}
                                        value={userOtpDetails[`otp${key + 1}`] ?? ''}
                                        onChange={(e) => handleOtpInputs(e, key)}
                                        autoComplete="off"
                                        autoFocus={key === 0}
                                        type="text"
                                        name={`otp${key + 1}`}
                                        id={`otp${key + 1}`}
                                        placeholder={otpFormData[0]?.placeholder || `*`}
                                        required
                                        maxLength="1"
                                        className={`h-14 w-full items-center justify-center text-center border-input-border bg-input-bg hover:bg-input-hover-bg text-extra-dark-gray invalid:border-b-2 font-semibold text-extra-dark-gray font-semibold placeholder:font-semibold focus:outline-none px-4 ${styles.placeholderColor} ${styles.inputTextColor}`}
                                    /><span>&nbsp;</span></Fragment>
                                ))}
                            </div>
                            <button tabIndex={3} disabled={isLoading} className={`w-full  h-14 hover:${styles.loginSignUpBtnHoverColor} ${styles.loginSignUpBtnColor}  ${styles.loginSignUpBtnTextColor}`}>
                                {isLoading ? <LoaderEllipse /> : submitOtpBtnTxt}
                            </button>
                        </form>
                        <div className={`grid place-items-center ${styles.infoTextColor} gap-1 text-center`}>
                            <p><button onClick={(e) => resendVerificationMail(e)} className={`text-indigo-700 underline ${isActive ? '' : 'opacity-50 cursor-not-allowed'}`}>{resendVerification}</button></p>
                        </div>
                        <div className={`grid place-items-center ${styles.infoTextColor} gap-1 text-center`}>
                            <p><button onClick={(e) => setWhichForm('email')} className='underline'>{backToLoginTxt}</button></p>
                        </div>
                    </>
                )
            case 'password':
                return (
                    <>
                        <h6 className="font-bold">Change Password Guidelines</h6>
                        <ul className="list">
                            {passwordFormRequirments.map((item) => (
                                <li key={item.key} className="flex"><img className="" src={item.isMet ? "/images/Icon-Checkmark.svg" : "/images/Icon-X.svg"} alt="logo" /> {item.title}</li>
                            ))}
                        </ul>
                        <form onSubmit={(e) => handlePasswordSubmit(e)} className='grid gap-3'>
                            {
                                passwordFormData.map((inputField, key) => {
                                    return <><div className="relative" key={inputField.id}>
                                        <input tabIndex={inputField.id}                                        
                                        value={userPasswordDetails[inputField.name] ?? ''}
                                        onInvalid={e => e.target.setCustomValidity(inputField.validationText)}
                                        onInput={e => e.target.setCustomValidity('')}
                                        onChange={(e) => handlePasswordInputs(e)}
                                        autoComplete="off"
                                        autoFocus={key === 0 ? true : false}
                                        type={passwordVisibility[inputField.id] ? 'text' : 'password'}
                                        name={inputField.name}
                                        id={inputField.id}
                                        placeholder={inputField.placeholder}
                                        required={inputField.required}
                                        className={`h-14 w-full  ${invalidList.includes(inputField.name) ? `border-b-2 ${styles.inputErrorBorderColor} ${styles.invalidInputBgColor}` : `${styles.inputBorderColor} ${styles.inputBackground} ${styles.inputHoverBackground}`} ${styles.placeholderColor} ${styles.inputTextColor} font-semibold placeholder:font-semibold focus:outline-none px-4`}
                                    />
                                        <button
                                        type="button"
                                            className="absolute inset-y-0 right-0 flex items-center px-4 text-gray-600"
                                            onClick={() => togglePasswordVisibility(inputField.id)}
                                        >
                                            {passwordVisibility[inputField.id] ? (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                                                    />
                                                </svg>
                                            ) : (
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                                                    />
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                    />
                                                </svg>
                                            )}
                                        </button></div>
                                    </>
                                })
                            }
                            <button tabIndex={3} disabled={isLoading} className={`w-full  h-14 hover:${styles.loginSignUpBtnHoverColor} ${styles.loginSignUpBtnColor}  ${styles.loginSignUpBtnTextColor}`}>
                                {isLoading ? <LoaderEllipse /> : resetPasswordBtnTxt}
                            </button>
                        </form>
                        <div className={`grid place-items-center ${styles.infoTextColor} gap-1 text-center`}>
                            <p><button onClick={(e) => setWhichForm('email')} className='underline'>{backToLoginTxt}</button></p>
                        </div>
                    </>
                )
            default:
                setWhichForm('email')
                break;
        }
    }

    return (
        <div className={` test-1 w-full mb-8 sm:p-3 sm:rounded-none rounded-md ${styles.formWrapperBackground}`}>
            <div className={`relative test-2 w-full rounded-md md:px-14 p-4 font-SansPro ${styles.formBackground} grid shadow-cardShadow-md`}>
                {isOverlayLoading ? <div className="absolute bg-black bg-opacity-60 z-10 h-full w-full flex items-center justify-center">
                    <div className="flex items-center">
                        <img src="/images/loader.png" alt="loader-ellipse" className="w-10 animate-spin" />
                    </div>
                </div> : null}
                <div className='grid place-items-center relative object-contain'>
                    <img src="/images/light/logo.webp" className='h-138px' alt="client logo" />
                </div>
                <div className='grid gap-2 place-items-center text-center mt-2 mb-8'>
                    <h2 className={`text-2xl font-bold ${styles.formTitleColor}`}>{formTitle}</h2>
                    <p className={styles.formTitleDescriptionColor}>{description}</p>
                </div>
                <div className='grid gap-y-4'>
                    {SwitchForms(whichForm)}
                    <p className={`mt-2 text-center text-lg font-bold ${styles.validationErrTextColor} ${isValid ? `hidden` : `block`}`} id="validation_error"></p>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword 