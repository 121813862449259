import React, { useContext, useEffect, 
    // useState
 } from 'react'
import DynamicTitle from '../../components/common/dynamic-title'
import { InnerHeader } from '../../components/common/inner-header'
import { DataContext } from '../../contexts/dataContext'
import TeamReview from '../../components/cyber-training/team-review'

const TeamActivityReview = () => {
    const context = useContext(DataContext);
    //site data

    let cyberSafetyTitle = context?.siteData?.cyberSafetyTraining?.cyberSafetyTitle ?? ''
    let defaultErrorMsg = context?.siteData?.common?.defaultErrorMsg ?? 'Something went wrong'
    let teamReviewTitle = context?.siteData?.common?.teamReviewTitle ?? ''
    let defaultLoadingText = context?.siteData?.common?.defaultLoadingText ?? 'Loading...'
    let teamReviewTableHeader = context?.siteData?.common?.teamReviewTableHeader ?? []
    let teamReviewModuleFilters = context?.siteData?.common?.teamReviewModuleFilters ?? []
    let teamReviewModuleInitialFilter = context?.siteData?.common?.teamReviewModuleInitialFilter ?? {
        module: "Employee_Digital_Safety",
        moduleLabel: "Employee Digital Safety"
    }

    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader
                    hasBackBtn={true}
                    hasHomeBtn={false}
                />
                <DynamicTitle
                    title={cyberSafetyTitle}
                    hasBorder={true}
                />
            </div>
            <div className='w-full'>
                <TeamReview
                    teamReviewTitle={teamReviewTitle}
                    defaultErrorMsg={defaultErrorMsg}
                    teamReviewTableHeader={teamReviewTableHeader}
                    teamReviewModuleFilters={teamReviewModuleFilters}
                    teamReviewModuleInitialFilter={teamReviewModuleInitialFilter}
                    defaultLoadingText={defaultLoadingText}
                />
            </div>
        </div>
    )
}

export default TeamActivityReview