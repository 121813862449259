import React, { useContext } from 'react'

import BoxxActivitiesCard from './boxx-activities-card';
import { DataContext } from '../../contexts/dataContext';

const BoxxaActivities = () => {
    const context = useContext(DataContext)
    let boxxActivities = context?.siteData?.homePage?.boxxActivities ?? {}
    return (
        <div className='w-full flex justify-center'>
            <div className='w-full flex justify-center md:w-11/12 lg:w-3/4'>    
                <div className='grid grid-cols-2 gap-x-7 gap-y-6 md:gap-x-20 md:gap-y-16'>
                    {
                        Object.entries(boxxActivities).map((activity)=>{
                            return(
                                <BoxxActivitiesCard
                                    key={activity[0]}
                                    titleSmall={activity[1].title_small}
                                    titleLarge={activity[1].title_large}
                                    description={activity[1].description}
                                    imgSmall={activity[1].image_small}
                                    imgLarge={activity[1].image_large}
                                    pageUrl={activity[1].page_url}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default BoxxaActivities