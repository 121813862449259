import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext'

const SwitchLanguage = () => {
  const context = useContext(DataContext);
  const { handlefilters, filters } = context;
  const languages = context?.siteData?.common?.languages ?? []
  const languageTextCol = context?.siteStyles?.header?.languageTextColor ?? 'lite-orange'

  let languageTextColor = `text-${languageTextCol}`
  return (
    <div className={` ${languages.length > 1 && `flex items-center justify-center space-x-4 text-sm font-bold ${languageTextColor}`} `}>
      {
        languages.length > 1 &&
        languages.map((language) => (
          <button key={language.id} onClick={() => handlefilters("lang", language.name)} disabled={filters.lang === language.name ?? true}
            className={`hover:opacity-100 hover:underline disabled:cursor-default disabled:opacity-100  ${filters.lang === language.name ? "underline" : "opacity-60"} `}>{language.name}</button>
        ))
      }
    </div>
  )
}

export default SwitchLanguage