import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext';

const DynamicBanner = (props) => {
  const { image, header } = props;
  const backgroundImage = `bg-${image}`;
  const context = useContext(DataContext);

  const dynamicBannerTextCol = context?.siteStyles?.cyberSafetyTraining?.dynamicBannerTextCol ?? 'white'

  let dynamicBannerTextColor = `text-${dynamicBannerTextCol}`

  return (
    <div className={`h-28 w-full ${backgroundImage} flex items-center justify-center bg-repeat absolute`}>
      <h1 className={`text-2xl font-normal leading-8 font-Publico ${dynamicBannerTextColor}`}>{header}</h1>
    </div>
  )
}

export default DynamicBanner