import React from 'react'

const CardSlider = (props) => {
  return (
    <div className='w-full grid gap-2 grid-flow-col scroll-auto overscroll-x-contain overflow-x-auto no-scrollbar'>
        {props.children}
    </div>
  )
}

export default CardSlider