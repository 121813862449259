import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext';

const Header = ({ mainHead, subHead }) => {

    const context = useContext(DataContext)
    //page style's
    const titleCol = context?.siteStyles?.homePage?.boxxActivities?.titleCol ?? 'dark-gray'

    let titleColor = `text-${titleCol}`

    return (
        <div className='w-full py-14'>
            <div className='space-y-5 max-w-5xl text-center mx-auto'>
                <h1 className={`font-semibold font-Publico ${titleColor} text-2xl`} dangerouslySetInnerHTML={{ __html: mainHead }} />
                <h2 className='font-SansPro '>{subHead}</h2>
            </div>
        </div>
    )
}

export default Header