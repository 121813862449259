import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { authUtils } from '../../helpers/authHelper';
import SideNav from '../navbar/sideNav';
import { DataContext } from '../../contexts/dataContext';
import SwitchLanguage from './switch-language';

const Header = () => {
    const [isSideNav, setIsSideNav] = useState(false)
    const context = useContext(DataContext)

    //page style's
    const background = context?.siteStyles?.header?.background ?? 'white'
    const borderCol = context?.siteStyles?.header?.borderCol ?? 'btn-blue'
    let bg = `bg-${background}`
    let borderColor = `border-b-${borderCol}`

    const toggleSideNav = () => {
        setIsSideNav(!isSideNav);
    }
    let location = useLocation();

    const isAuthenticated = authUtils.isAuthenticated();
    return (
        <div className={`w-full ${bg} border-b-2 ${borderColor} ${location.pathname === '/' ? `py-7` : `py-4`}`}>
            <div className={`w-full grid grid-cols-3 items-center`}>
                <div className='md:flex md:justify-start md:items-center'>
                    <div className='hidden px-5 md:flex md:justify-start md:items-center'>
                        {
                            location.pathname === '/'
                                ? <img className={`w-36 md:ml-8`} src={`/images/dark/solutionProvider.svg`} alt="logo" />
                                : <Link to={'/home'}>
                                    <img className={`w-36 md:ml-8`} src={`/images/dark/solutionProvider.svg`} alt="logo" />
                                </Link>
                        }
                    </div>
                </div>
                <div className='flex items-center justify-center'>
                    {
                        location.pathname === '/'
                            ? <img className={`h-12`} src="/images/light/AXA_Logo.svg" alt="logo" />
                            : <Link to={'/home'}>
                                <img className={`h-12`} src="/images/light/AXA_Logo.svg" alt="logo" /> 
                            </Link>
                    }
                </div>
                <div className={`md:flex md:justify-end md:items-center ${isAuthenticated ? `pr-0` : `pr-8`}`}>
                    <SwitchLanguage />
                    {isAuthenticated && <div className='hidden px-5 md:flex md:justify-end md:items-center'>
                        <button onClick={() => toggleSideNav()} className='min-w-max flex items-center justify-end'>
                            <img className='w-9' src="/images/user-icon-blue.png" alt="user-icon" />
                        </button>
                    </div>}
                </div>
                <SideNav
                    isSideNav={isSideNav}
                    toggleSideNav={toggleSideNav}
                />
            </div>
        </div>
    )
}

export default Header