import { useContext, useEffect } from 'react';

import 'vanilla-cookieconsent';
import PluginConfig from './CookieConsentConfig';
import { localStorageUtils } from '../../helpers/storageHelper';
import { DataContext } from '../../contexts/dataContext';

const CookieConsentComponent = () => {
  const context = useContext(DataContext);

  useEffect(() => {

    let languageConfig = context.siteData?.common?.cookieLanguageConfig ?? {
      "en": {
        "consent_modal": {
          "title": "En AXA también utilizamos cookies.",
          "description": "En esta web, AXA únicamente utiliza cookies técnicas que permiten al usuario, la navegación, la utilización de servicios básicos como gestión y operativa de la presente web, el control del tráfico y visitas a la misma y el acceso a áreas seguras y restringidas. La página web no puede funcionar adecuadamente sin estas cookies técnicas que se van a instalar. Más información en la. <a href='./cookie_policy' class='cc-link'> Política de Cookies. </button>",
          "primary_btn": {
            "text": "Accept",
            "role": "accept_all"
          },
          "secondary_btn": false
        },
        "settings_modal": {
          "title": "Cookie Settings",
          "save_settings_btn": "Save settings",
          "accept_all_btn": "Accept all",
          "reject_all_btn": false,
          "close_btn_label": "Close",
          "cookie_table_headers": [
            {
              "col1": "Name"
            },
            {
              "col2": "Domain"
            },
            {
              "col3": "Expiration"
            },
            {
              "col4": "Description"
            }
          ],
          "blocks": [
            {
              "title": "Cookie usage 📢",
              "description": "I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href='https://cybersafe-axa.boxxinsurance.com/cookie_policy' class='cc-link'>privacy policy</a>."
            },
          ]
        }
      },
      "es": {
        "consent_modal": {
          "title": "En AXA también utilizamos cookies.",
          "description": "En esta web, AXA únicamente utiliza cookies técnicas que permiten al usuario, la navegación, la utilización de servicios básicos como gestión y operativa de la presente web, el control del tráfico y visitas a la misma y el acceso a áreas seguras y restringidas. La página web no puede funcionar adecuadamente sin estas cookies técnicas que se van a instalar. Más información en la. <a href='./cookie_policy' class='cc-link'> Política de Cookies. </button>",
          "primary_btn": {
            "text": "Aceptar todo",
            "role": "aceptar todo"
          },
          "secondary_btn": false
        },
        "settings_modal": {
          "title": "Configuración de cookies",
          "save_settings_btn": "Guardar ajustes",
          "accept_all_btn": "Aceptar todo",
          "reject_all_btn": false,
          "close_btn_label": "Cerca",
          "cookie_table_headers": [
            {
              "col1": "Nombre"
            },
            {
              "col2": "Dominio"
            },
            {
              "col3": "Vencimiento"
            },
            {
              "col4": "Descripción"
            }
          ],
          "blocks": [
            {
              "title": "En AXA también utilizamos cookies.📢",
              "description": "En esta web, AXA únicamente utiliza cookies técnicas que permiten al usuario, la navegación, la utilización de servicios básicos como gestión y operativa de la presente web, el control del tráfico y visitas a la misma y el acceso a áreas seguras y restringidas. La página web no puede funcionar adecuadamente sin estas cookies técnicas que se van a instalar. Más información en la <a href='https://cybersafe-axa.boxxinsurance.com/cookie_policy' class='cc-link'> Política de Cookies</a>."
            }
          ]
        }
      }
    }
    let updatdPulgin = { ...PluginConfig, languages: languageConfig }

    /**
     * useEffect is executed twice (React 18+),
     * make sure the plugin is initialized and executed once
     */

    if (!document.getElementById('cc--main')) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run(updatdPulgin);
    }
    else {
      let siteLanguage = localStorageUtils.getLocalStoragItem('lang') ? localStorageUtils.getLocalStoragItem('lang').toLowerCase() : 'en'
      window.CookieConsentApi.updateLanguage(siteLanguage, true);
    }
    const overlay = document.getElementById('s-c-bn');
    overlay && overlay.addEventListener('click', () => {
      window.CookieConsentApi.accept([]);
      window.CookieConsentApi.hide();
    });
  },);

  return null;
};

export default CookieConsentComponent;
