import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../../contexts/dataContext'

function Footer() {

  const context = useContext(DataContext)

  //page data
  const privacypolicyLink = context?.siteData?.common?.urls?.privacypolicyLink ?? ''
  const cookiepolicyLink = context?.siteData?.common?.urls?.cookiepolicyLink ?? ''
  const termsAndConditionLink = context?.siteData?.common?.urls?.termsAndConditionLink ?? ''
  // const boxxinsuranceLink = context?.siteData?.common?.urls?.boxxinsuranceLink ?? ''
  const footerTitle = context?.siteData?.common?.footerTitle ?? ''
  const footerDescription = context?.siteData?.common?.footerDescription ?? ''
  const termsAndConditionText = context?.siteData?.common?.urlTexts?.termsAndConditionText ?? ''
  const privacypolicyText = context?.siteData?.common?.urlTexts?.privacypolicyText ?? ''
  const cookiepolicyText = context?.siteData?.common?.urlTexts?.cookiepolicyText ?? ''
  // const boxxinsuranceText = context?.siteData?.common?.urlTexts?.boxxinsuranceText ?? ''

  //page style's
  const background = context?.siteStyles?.footer?.background ?? 'footer-bg-blue'
  const title = context?.siteStyles?.footer?.title ?? 'light-white'
  const description = context?.siteStyles?.footer?.description ?? 'light-white'
  const links = context?.siteStyles?.footer?.links ?? 'light-white'

  let bg = `bg-${background}`
  let titleColor = `text-${title}`
  let descriptionColor = `text-${description}`
  let linkColor = `!text-${links}`

  return (
    <footer className={`${bg} pl-2 pr-4 sm:px-4 md:px-8 py-3 md:py-7 w-full`}>
      <div className='flex lg:items-end items-center justify-between'>
        <div className='h-full'>
          <p className={`text-footer-text font-semibold text-base md:text-xl lg:text-2xl xl:text-3xl sm:text-[17px] xs:leading-[17px] ${titleColor} font-Publico`}>{footerTitle}</p>
          <p className={`text-xxs md:text-base font-normal ${descriptionColor} font-SansPro`}>{footerDescription}</p>
        </div>
        <div className={`flex lg:flex-row flex-col h-full lg:space-x-6 text-center font-normal text-xxs md:text-base ${linkColor} font-SansPro`}>
          <p className='underline'><Link to={termsAndConditionLink} target={'_self'}>{termsAndConditionText}</Link></p>
          <p className='underline'><Link to={privacypolicyLink} target={'_self'}>{privacypolicyText}</Link></p>
          <p className='underline'><Link to={cookiepolicyLink} target={'_self'}>{cookiepolicyText}</Link></p>
          {/* <p className='underline'><Link to={boxxinsuranceLink} target={'_blank'}>{boxxinsuranceText}</Link></p> */}
        </div>
      </div>

    </footer>
  )
}

export default Footer