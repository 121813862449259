import React, { useContext, useEffect } from 'react'
import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { DataContext } from '../../contexts/dataContext';

const SensitiveData = () => {

  const context = useContext(DataContext);

  const data = context?.siteData?.cyberSafetyTraining?.moduleData?.sensitiveData ?? {};

  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <div className='w-full'>
      <DynamicInnerPageWrapper
        data={data}
        moduleName="PROTECTING_SENSITIVE_DATA"
      />
    </div>
  )
}

export default SensitiveData