import React, { useContext, useState } from 'react'
import { DataContext } from '../../../contexts/dataContext';
import axios from 'axios';
import { apiUrl, header } from '../../../helpers/constants';
import { localStorageUtils } from '../../../helpers/storageHelper';
import LoaderEllipse from '../../common/loader-ellipse';

const ELearning = (props) => {
    const { handleContentChange, description, fileContent, video, currentPage } = props;

    const context = useContext(DataContext);
    const [loadingStates, setLoadingStates] = useState({});

    const cyberSafetyELearningSectionBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyELearningSectionBtnText ?? ''
    const cyberSafetyELearningSectionTitle = context?.siteData?.cyberSafetyTraining?.cyberSafetyELearningSectionTitle ?? ''

    const textCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.eLearning?.textCol ?? 'simple-gray'
    const videoBgCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.eLearning?.videoBgCol ?? 'black'
    const buttonStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.eLearning?.buttonStyle ?? 'orange-border-btn'
    const pdfLinkTextStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.eLearning?.pdfLinkTextStyle ?? 'redTxt'

    const textColor = `text-${textCol}`
    const videoBgColor = `text-${videoBgCol}`
    const isMobileDevice = () => {
        const userAgent = navigator.userAgent;
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    };
    function fetchPdf(filename, key) {
        setLoadingStates((prevLoadingStates) => ({
            ...prevLoadingStates,
            [key]: true,
        }));

        axios.get(`${apiUrl}/pdf/file`, {
            params: { lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN", filename },
            headers: header,
            responseType: 'arraybuffer'
        }).then((res) => {
            if (res.status === 200) {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const dataUrl = window.URL.createObjectURL(blob);
                if (isMobileDevice()) {
                    window.open(dataUrl, '_self');
                } else {
                    window.open(dataUrl, '_blank');
                }            }
        }).catch((err) => console.log(err)).finally(() => {
            setLoadingStates((prevLoadingStates) => ({
                ...prevLoadingStates,
                [key]: false,
              }));
        })
    }

    return (
        <div className={`w-full space-y-5 pb-6 ${textColor}`}>
            <h1 className='text-base font-Publico font-semibold '>{cyberSafetyELearningSectionTitle}</h1>
            <p className='text-sm font-normal '>{description}</p>
            <div className={`w-full space-y-3`}>
                {fileContent.map((content, key) => (
                    <div key={key} >
                        <button onClick={() => fetchPdf(content.file, key)} className={`inline-block ${pdfLinkTextStyle}`} disabled={loadingStates[key]}>
                            <span className='flex items-center space-x-2 text-xs tracking-.4px'>
                                <h2 className='uppercase'>{content.title} {loadingStates[key] ? <LoaderEllipse /> : null}</h2>
                                <span className='w-2.5'>
                                    <img src="/images/downloadIcon.png" alt="arrow down" />
                                </span>
                            </span>
                        </button>
                    </div>
                ))}
            </div>
            <div className='w-full pt-4'>
                <iframe src={video} title={`${currentPage}-video`} width="100%" className={`aspect-video ${videoBgColor}`} allowFullScreen />
            </div>
            <div className='w-full pt-16 flex justify-center'>
                <button onClick={() => handleContentChange('assessment', 'forward')} className={buttonStyle}>{cyberSafetyELearningSectionBtnText}</button>
            </div>
        </div>
    )
}

export default ELearning