import React, { useContext } from 'react'
import { findScorePointerPosition } from '../../helpers/helper-functions';
import { DataContext } from '../../contexts/dataContext';

const ScorePointer = ({ totalMarkScored, isSmallChart, maxScore }) => {
    const context = useContext(DataContext);

    const resultSectionPointerStyle = context?.siteStyles?.digitalRiskAssessment?.DoughnutChart?.ScorePointer?.resultSectionPointerStyle ?? 'triangle-down-small'
    const scoreSectionPointerStyle = context?.siteStyles?.digitalRiskAssessment?.DoughnutChart?.ScorePointer?.scoreSectionPointerStyle ?? 'triangle-down'
    let pointer = findScorePointerPosition(totalMarkScored, maxScore) ?? '';
    return (
        <div className='absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full'>
            <div className={`w-full transform duration-300`} style={{rotate:pointer}}>
                <div className={`${isSmallChart ? resultSectionPointerStyle : scoreSectionPointerStyle} -rotate-90 -ml-4`} />
            </div>
        </div>
    )
}

export default ScorePointer