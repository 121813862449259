import React from 'react'
import Card from './card'

const CardWrapper = ({ cardsData }) => {
    return (
        <div className='w-full grid justify-center'>
            <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2 w-full max-w-4xl'>
                {
                    cardsData.map((card) => (
                        <Card key={card.id}
                            title={card.title}
                            description={card.description}
                            image={card.image}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default CardWrapper