import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext';

const AssessmentHead = (props) => {
    const { currentQuestion, totalQuestions, totalAnsweredQuestions } = props;
    let progressBarStaus = totalQuestions === 0 ? 0 : (100 / totalQuestions) * (currentQuestion + 1);
    const context = useContext(DataContext);

    const cyberSafetyAssessmentRangeTag = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentRangeTag ?? []

    const rangeBg = context?.siteStyles?.digitalRiskAssessment?.assessment?.header?.rangeBg ?? 'extra-medium-gray'
    const rangeFillBg = context?.siteStyles?.digitalRiskAssessment?.assessment?.header?.rangeFillBg ?? 'dark-red'
    const textCol = context?.siteStyles?.digitalRiskAssessment?.assessment?.header?.textCol ?? 'medium-gray'

    let rangeBgColor = `bg-${rangeBg}`
    let rangeFillBgColor = `bg-${rangeFillBg}`
    let textColor = `text-${textCol}`

    return (
        <div className='w-full space-y-2'>
            <div className={`w-full h-1.5 ${rangeBgColor} rounded-3xl`}>
                <div className={`h-full rounded-3xl ${rangeFillBgColor} transform duration-300`} style={{ width: `${progressBarStaus}%` }} />
            </div>
            <h2 className={`text-sm font-SansPro font-normal ${textColor}`}>{`${cyberSafetyAssessmentRangeTag[0] ?? ''} ${totalAnsweredQuestions}/${totalQuestions} ${cyberSafetyAssessmentRangeTag[1] ?? ''}`}</h2>
        </div>
    )
}
export default AssessmentHead