import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleModal from '../common/simple-modal';
import Card from "./card";
import { DataContext } from '../../contexts/dataContext';

const BreachResults = ({ data }) => {

    const context = useContext(DataContext);

    //page data
    const breacheResultPageHead = context?.siteData?.checkForBreaches?.breachesResultsPage?.breacheResultPageHead ?? ''
    const backToHomeLinkText = context?.siteData?.checkForBreaches?.breachesResultsPage?.backToHomeLinkText ?? ''
    const searchAgainLinkText = context?.siteData?.checkForBreaches?.breachesResultsPage?.searchAgainLinkText ?? ''
    const breacheResultPageDescription = context?.siteData?.checkForBreaches?.breachesResultsPage?.breacheResultPageDescription ?? []

    //page styles
    const breacheResultPageHeadTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.breacheResultPageHeadTextCol ?? 'simple-gray'
    const searchDataTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.searchDataTextCol ?? 'medium-purple'
    const breacheResultPageDescriptionTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.breacheResultPageDescriptionTextCol ?? 'simple-gray'
    const breacheResultCountTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.breacheResultCountTextCol ?? 'err-input-border'
    const breacheNoResultCountTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.breacheNoResultCountTextCol ?? 'success'
    const backToHomeLinkTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.backToHomeLinkTextCol ?? 'extra-dark-gray'
    const searchAgainLinkTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.searchAgainLinkTextCol ?? 'extra-dark-gray'
    const backToHomeLinkBgCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.backToHomeLinkBgCol ?? 'dark-yellow'
    const searchAgainLinkBgCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.searchAgainLinkBgCol ?? 'app-bg'
    const searchAgainLinkBorderCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.searchAgainLinkBorderCol ?? 'dark-yellow'

    let breacheResultPageHeadTextColor = `text-${breacheResultPageHeadTextCol}`
    let searchDataTextColor = `text-${searchDataTextCol}`
    let breacheResultPageDescriptionTextColor = `text-${breacheResultPageDescriptionTextCol}`
    let breacheResultCountTextColor = `text-${breacheResultCountTextCol}`
    let breacheNoResultCountTextColor = `text-${breacheNoResultCountTextCol}`
    let backToHomeLinkTextColor = `text-${backToHomeLinkTextCol}`
    let searchAgainLinkTextColor = `text-${searchAgainLinkTextCol}`
    let backToHomeLinkBgColor = `bg-${backToHomeLinkBgCol}`
    let searchAgainLinkBgColor = `bg-${searchAgainLinkBgCol}`
    let searchAgainLinkBorderColor = `border-${searchAgainLinkBorderCol}`

    const [modalContent, setModalContent] = useState(null);

    const handleLearnMore = (data) => {
        setModalContent(data);
    }

    useEffect(() => {
        if (modalContent) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }, [modalContent]);

    useEffect(() => {
        return () => {
            document.body.style.overflowY = "auto";
        }
    }, []);

    const handleClose = () => {
        setModalContent(null);
    }
    return <div className="flex justify-center">
        <div className='w-full max-w-4xl my-7'>
            <div className=''>
                <h1 className={`text-lg text-center font-Publico font-semibold ${breacheResultPageHeadTextColor} `}>{breacheResultPageHead} <span className={searchDataTextColor}>{data && data.search_data}</span></h1>
                {
                    data && data.search_result && data.search_result.length > 0 &&
                    <p className={`text-center ${breacheResultPageDescriptionTextColor} text-sm mt-3 `}>{breacheResultPageDescription[0] ?? ''} {data && data.type} {breacheResultPageDescription[1] ?? ''} <span className={`${breacheResultCountTextColor}`}>{data.search_result.length}</span> {breacheResultPageDescription[2] ?? ''}</p>
                }
            </div>

            {
                data && data.search_result && data.search_result.length === 0 &&
                <div className='flex flex-col items-center m-[0px]'>
                    <div className='p-[60px_50px_40px_50px]'>
                        <p className={`text-center text-base ${breacheResultPageDescriptionTextColor} leading-5 `}>{breacheResultPageDescription[0] ?? ''} {data && data.type} {breacheResultPageDescription[1] ?? ''} <span className={breacheNoResultCountTextColor}>0</span> {breacheResultPageDescription[2] ?? ''}</p>

                    </div>
                    <Link to="/home">
                        <button className={`min-w-[153px] py-2 text-sm font-semibold ${backToHomeLinkTextColor} leading-4 ${backToHomeLinkBgColor} rounded-lg `}>{backToHomeLinkText}</button>

                    </Link>
                    <Link to="/check_for_breaches">
                        <button className={`mt-7 min-w-[153px] py-2 text-sm font-semibold ${searchAgainLinkTextColor} leading-4 ${searchAgainLinkBgColor} border ${searchAgainLinkBorderColor} rounded-lg `}>{searchAgainLinkText}</button>

                    </Link>
                </div>
            }

            <div className='mt-10 space-y-3'>
                {data && data.search_result.map((breach, index) => {
                    return <Card key={`breach-${index}`} data={breach} handleLearnMore={handleLearnMore} />
                })}
            </div>

            {
                modalContent &&
                <SimpleModal handleClose={handleClose} />
            }

        </div>
    </div>
}

export default BreachResults;