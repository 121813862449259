import React, { useContext } from 'react'
import { DataContext } from '../../../contexts/dataContext';

const AssessmentHead = (props) => {
    const {currentQuestion, totalQuestions, totalAnsweredQuestions} = props;
    const progressBarStaus = totalQuestions === 0 ? 0 : (100/totalQuestions)*currentQuestion;

    const context = useContext(DataContext);

    //page data
    const cyberSafetyAssessmentHeadTitle = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentHeadTitle ?? ''
    const cyberSafetyAssessmentHeadTitleDescription = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentHeadTitleDescription ?? []
    const cyberSafetyAssessmentRangeDescription = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentRangeDescription ?? []

    //page styles
    const assessmentHeadTitleTextCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.assessmentHeadTitleTextCol ?? 'simple-gray'
    const assessmentHeadTitleDescriptionTextCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.assessmentHeadTitleDescriptionTextCol ?? 'simple-gray'
    const rangeBg = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.rangeBg ?? 'input-border'
    const rangeFillBg = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.rangeFillBg ?? 'dark-red'
    const textCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.textCol ?? 'medium-gray'

    const assessmentHeadTitleTextColor = `text-${assessmentHeadTitleTextCol}`
    const assessmentHeadTitleDescriptionTextColor = `text-${assessmentHeadTitleDescriptionTextCol}`
    const rangeBgColor = `bg-${rangeBg}`
    const rangeFillBgColor = `bg-${rangeFillBg}`
    const textColor = `text-${textCol}`

    const cyberSafetyAssessmentHeadTitleDescriptionText = `${cyberSafetyAssessmentHeadTitleDescription[0] ?? ''} ${totalQuestions} ${cyberSafetyAssessmentHeadTitleDescription[1] ?? ''}`
    const cyberSafetyAssessmentRangeDescriptionText = `${cyberSafetyAssessmentRangeDescription[0] ?? ''} ${totalAnsweredQuestions}/${totalQuestions} ${cyberSafetyAssessmentRangeDescription[1] ?? ''}`
    
    return (
        <div className='w-full space-y-5'>
            <h1 className={`text-base font-Publico font-semibold font-SansPro ${assessmentHeadTitleTextColor}`}>{cyberSafetyAssessmentHeadTitle}</h1>
            <div className='w-full space-y-10 font-SansPro'>
                {currentQuestion !== totalQuestions - 1 && <h2 className={`text-sm font-normal font-SansPro ${assessmentHeadTitleDescriptionTextColor}`}>{cyberSafetyAssessmentHeadTitleDescriptionText}</h2>}
                <div className='w-full space-y-2'>
                    <div className={`w-full h-1.5 ${rangeBgColor} rounded-3xl`}>
                        <div className={`h-full rounded-3xl ${rangeFillBgColor} transform duration-300`} style={{width:`${progressBarStaus}%`}} />
                    </div>
                    <h2 className={`text-sm font-normal ${textColor}`}>{cyberSafetyAssessmentRangeDescriptionText}</h2>
                </div>
            </div>
        </div>
    )
}

export default AssessmentHead