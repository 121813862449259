import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../../contexts/dataContext';

const BoxxActivitiesCard = ({
    titleSmall,
    titleLarge,
    description,
    imgSmall,
    imgLarge,
    pageUrl
}) => {
    let activityImgLg = `md:bg-${imgLarge}`;

    const context = useContext(DataContext)

    //page style's
    const titleCol = context?.siteStyles?.homePage?.boxxActivities?.titleCol ?? 'dark-gray'
    const titleMdCol = context?.siteStyles?.homePage?.boxxActivities?.titleMdCol ?? 'white'
    const background = context?.siteStyles?.homePage?.boxxActivities?.background ?? 'light-blue'
    const descriptionCol = context?.siteStyles?.homePage?.boxxActivities?.descriptionCol ?? 'dark-gray'
    const cardBorder = context?.siteStyles?.homePage?.boxxActivities?.cardBorder ?? 'dark-blue'
    const shadowCol = context?.siteStyles?.homePage?.boxxActivities?.shadowCol ?? 'white'

    let titleColor = `text-${titleCol}`
    let titleMdColor = `md:text-${titleMdCol}`
    let shadowColor = `md:bg-${shadowCol}`
    let backgroundColor = `bg-${background}`
    let descriptionColor = `text-${descriptionCol}`
    let cardBorderColor = `border-${cardBorder}`
    return (
        <div className={`rounded md:rounded-none border md:border-none ${shadowColor} md:shadow-cardShadow-md ${cardBorderColor} ${backgroundColor} md:bg-none sm:block flex flex-col items-center justify-center w-full`}>
            <Link to={pageUrl} className="md:h-auto h-full w-full">
                <div className={`sm:bg-none ${activityImgLg} bg-cover bg-no-repeat flex flex-col items-center justify-center md:h-auto h-full w-full  md:aspect-video relative`}>
                    <div className={`flex flex-col items-center md:justify-center justify-between space-y-2 py-5 px-4 font-Publico h-full`}>
                        <img src={`/images/home/smallScreens/${imgSmall}`} className="sm:block md:hidden object-contain" alt={'boxx-activity'} />
                        <div className={`w-full h-full flex items-center justify-center z-10 ${titleColor} ${titleMdColor} font-Publico font-bold text-center md:text-2xl xl:text-35px text-xl leading-8`}>
                            <h2 className='md:inline-block hidden sm:w-10/12 w-11/12 font-Publico'><span>{titleLarge}</span></h2>
                            <h2 className='inline-block md:hidden sm:w-10/12 w-11/12 font-Publico'><span>{titleSmall}</span></h2>
                        </div>
                    </div>
                    <div className='absolute bg-black bg-opacity-30 top-0 left-0 h-full w-full md:block hidden' />
                </div>
            </Link>
            <div className='hidden md:block p-5'>
                <p className={`text-center font-SansPro font-normal md:text-xs lg:text-xs  xl:text-base 2xl:text-lg ${descriptionColor} font-SansPro leading-6`}>{description}</p>
            </div>
        </div>
    )
}

export default BoxxActivitiesCard