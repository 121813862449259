import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext';

const ScoreWithRange = ({ totalMarkScored, handleContentChange, userScoreRange, isSmallChart }) => {
  const context = useContext(DataContext);

  const rangeTextCol = context?.siteStyles?.digitalRiskAssessment?.DoughnutChart?.ScoreWithRangeSection?.rangeTextCol ?? 'strong-gray'
  const lowScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.DoughnutChart?.ScoreWithRangeSection?.lowScoreTextCol ?? 'err-input-border'
  const mediumScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.DoughnutChart?.ScoreWithRangeSection?.mediumScoreTextCol ?? 'extra-dark-yellow'
  const highScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.DoughnutChart?.ScoreWithRangeSection?.highScoreTextCol ?? 'success'

  let rangeTextColor = `text-${rangeTextCol}`
  let lowScoreTextColor = `text-${lowScoreTextCol}`
  let mediumScoreTextColor = `text-${mediumScoreTextCol}`
  let highScoreTextColor = `text-${highScoreTextCol}`
  return (
    <div className={`absolute left-1/2 transform -translate-x-1/2 ${isSmallChart ? `-space-y-2 top-8` : `top-1/2 space-y-2 -translate-y-1/2`} font-semibold text-center font-Publico`}>
      <h1 className={`${isSmallChart ? `text-3xl` : `text-5xl`} ${(userScoreRange === 'POCO' || userScoreRange === 'POOR') ? lowScoreTextColor : (userScoreRange === 'GOOD' || userScoreRange === 'BUENO') ? mediumScoreTextColor : highScoreTextColor} `}>{totalMarkScored}</h1>

      <h2 className={`${isSmallChart ? `text-xl` : `text-2xl`} underline ${rangeTextColor}`}>{userScoreRange}</h2>

    </div>
  )
}

export default ScoreWithRange