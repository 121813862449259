import { cookieStorageUtils, localStorageUtils } from "./storageHelper";

export const authUtils = {
    handleLogout: () => {
        localStorageUtils.removeLocalStorageItem('user');
        localStorageUtils.removeLocalStorageItem('token');
        localStorageUtils.removeLocalStorageItem('refreshToken');
        localStorageUtils.removeLocalStorageItem('userId');
        cookieStorageUtils.deleteCookie('authToken');
        cookieStorageUtils.deleteCookie('refreshToken');
    },
    isAuthenticated: () => {
        // const isAuthenticated = cookieStorageUtils.getCookie('authToken');
        const isAuthenticated = localStorageUtils.getLocalStoragItem('token');
        return isAuthenticated;
    }
}