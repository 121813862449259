import React, { useContext, useState } from 'react'
import { DataContext } from '../../contexts/dataContext'
import { useNavigate } from "react-router-dom";
import api from '../../api/axiosConfig';
import { localStorageUtils } from '../../helpers/storageHelper';
import LoaderEllipse from '../common/loader-ellipse';
import { isNumber } from '../../helpers/validator';

const SignUp = ({ toggleForm }) => {
    const context = useContext(DataContext);

    //page data
    let formData = context.siteData?.axaSpecific?.axaSignUp?.formData ?? []
    let title = context.siteData?.axaSpecific?.axaSignUp?.title ?? ""
    let description = context.siteData?.axaSpecific?.axaSignUp?.description ?? ""
    let signUpBtnText = context.siteData?.axaSpecific?.axaCommon?.signUpBtnText ?? ""
    let accessCodeInfo = context.siteData?.axaSpecific?.axaCommon?.accessCodeInfo ?? ""
    let newUserLoginBtnText = context.siteData?.axaSpecific?.axaCommon?.newUserLoginBtnText ?? ""
    let signUpLeadingText = context.siteData?.axaSpecific?.axaSignUp?.signUpLeadingText ?? ""

    //site style
    let formWrapperBg = context.siteStyles?.axaSpecific?.formWrapperBg ?? 'cyan-blue'
    let formBg = context.siteStyles?.axaSpecific?.formBg ?? 'white'
    let inputBg = context.siteStyles?.axaSpecific?.inputBg ?? 'input-bg'
    let inputHoverBg = context.siteStyles?.axaSpecific?.inputHoverBg ?? 'input-hover-bg'
    let invalidInputBgCol = context.siteStyles?.axaSpecific?.invalidInputBgCol ?? 'extra-lite-red'
    let loginSignUpBtnCol = context.siteStyles?.axaSpecific?.loginSignUpBtnCol ?? 'btn-blue'
    let formTitleCol = context.siteStyles?.axaSpecific?.formTitleCol ?? 'extra-dark-gray'
    let formTitleDescriptionCol = context.siteStyles?.axaSpecific?.formTitleDescriptionCol ?? 'dark-gray'
    let loginSignUpBtnTextCol = context.siteStyles?.axaSpecific?.loginSignUpBtnTextCol ?? 'white'
    let infoTextCol = context.siteStyles?.axaSpecific?.infoTextCol ?? 'dark-gray'
    let placeholderCol = context.siteStyles?.axaSpecific?.placeholderCol ?? 'extra-dark-gray'
    let inputTextCol = context.siteStyles?.axaSpecific?.inputTextCol ?? 'extra-dark-gray'

    let formWrapperBackground = `bg-${formWrapperBg}`
    let formBackground = `bg-${formBg}`
    let formTitleColor = `text-${formTitleCol}`
    let formTitleDescriptionColor = `text-${formTitleDescriptionCol}`
    let inputBackground = `bg-${inputBg}`
    let inputHoverBackground = `hover:bg-${inputHoverBg}`
    let invalidInputBgColor = `bg-${invalidInputBgCol}`
    let loginSignUpBtnColor = `bg-${loginSignUpBtnCol}`
    let loginSignUpBtnTextColor = `text-${loginSignUpBtnTextCol}`
    let infoTextColor = `text-${infoTextCol}`
    let placeholderColor = `placeholder:text-${placeholderCol}`
    let inputTextColor = `text-${inputTextCol}`

    const inputErrorBorderCol = context?.siteStyles?.common?.inputs?.errTextCol ?? 'err-input-border'
    const inputBorderCol = context?.siteStyles?.common?.inputs?.selectBorderCol ?? 'input-border'
    const inputErrorBorderColor = `border-${inputErrorBorderCol}`
    const invalidInputBorderColor = `invalid:border-b-${inputErrorBorderCol}`
    const inputBorderColor = `border-${inputBorderCol}`
    const validationErrTextCol = context?.siteStyles?.common?.inputs?.validationErrTextCol ?? 'err-text'
    const validationErrTextColor = `text-${validationErrTextCol}`
    const defaultConnectionErrorMsg = context?.siteData?.common?.defaultConnectionErrorMsg ?? ''
    const signUpInputValidationErrorText = context?.siteData?.signUpPage?.signUpInputValidationErrorText ?? ''
    const [userCridentials, setUserCridentials] = useState({
        firstName: null,
        lastName: null,
        companyName: null,
        noOfEmployees: null,
        website: null,
        policyNumber: null,
        email: null,
        password: null
    })

    const [isValid, setIsValid] = useState(true)
    const [invalidList, setInvalidList] = useState([])
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const language = localStorageUtils.getLocalStoragItem('lang') ?? "EN"

    const handleInputs = (form) => {
        //Only default form validatons are performing
        let tempInvalidList = [...invalidList];
        let errFieldId = `err_${form.target.name}`
        if (form.target.name === 'noOfEmployees') {
            if (isNumber(form.target.value)) {
                document.getElementById('validation_error').innerText = ''
                clearValidationErr(errFieldId, form.target.name)
                setUserCridentials((prevState) => ({
                    ...prevState,
                    [form.target.name]: form.target.value
                }))
                if (invalidList.includes(form.target.name)) {
                    tempInvalidList.splice(tempInvalidList.indexOf(form.target.name), 1)
                    setInvalidList(tempInvalidList)
                }

            } else {
                document.getElementById('validation_error').innerText = signUpInputValidationErrorText
                if (!invalidList.includes(form.target.name)) {
                    tempInvalidList.push(form.target.name)
                    setInvalidList(tempInvalidList)
                }
            }
        } else {
            document.getElementById('validation_error').innerText = ''
            clearValidationErr(errFieldId, form.target.name)
            setUserCridentials((prevState) => ({
                ...prevState,
                [form.target.name]: form.target.value
            }))
            if (invalidList.includes(form.target.name)) {
                tempInvalidList.splice(tempInvalidList.indexOf(form.target.name), 1)
                setInvalidList(tempInvalidList)
            }
        }
    }

    const clearValidationErr = (errFieldId, fieldName) => {
        const errorElement = document.getElementById(errFieldId)
        if (errorElement)
            errorElement.innerText = '';

        const fieldElement = document.getElementById(fieldName)
        if (fieldElement)
            fieldElement.style.borderColor = "";
    }

    const isValidWebsite = (name) => {
        const value = userCridentials[name];
        let tempInvalidList = [...invalidList];
        let errFieldId = `err_${name}`
        let flag = true;
         // Regular expression to validate the website domain
         const domainPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
         const isValid = domainPattern.test(value);
         if(!isValid) {
             if (!tempInvalidList.includes(name)) {
                 tempInvalidList.push(name)
             }
             flag = false;
         } else {
             document.getElementById('validation_error').innerText = ''
             clearValidationErr(errFieldId, name)
             if (tempInvalidList.includes(name)) {
                 tempInvalidList.splice(tempInvalidList.indexOf(name), 1)
             }
         }
         setInvalidList(tempInvalidList)
         return flag;
    }

    const handleSignUp = (form) => {
        form.preventDefault();
        let userInfo = {};
        if(!isValidWebsite('website')) {
            return;
        }
        setIsLoading(true);
        api.post('/auth/user/register', userCridentials, { headers: { 'Accept-Language': language } }).then(res => {
            if (res.status === 200) {
                const { data } = res.data
                localStorageUtils.setLocalStorageItem('token', data.token.accessToken)
                localStorageUtils.setLocalStorageItem('refreshToken', data.token.refreshToken)
                localStorageUtils.setLocalStorageItem('userId', data.id)
                localStorageUtils.setLocalStorageItem('email', data.email)
                
                userInfo['email'] = data.email ?? ''
                userInfo['firstName'] = data.firstName ?? ''
                userInfo['lastName'] = data.lastName ?? ''
                userInfo['phoneNumber'] = data.phoneNumber ?? ''
                userInfo['policyNumber'] = data.policyNumber ?? ''
                userInfo['type'] = data.type ?? ''
                userInfo['positionTitle'] = data.positionTitle ?? ''
                userInfo['parentId'] = data.parentId ?? ''

                context.handleUserDetails(userInfo);

                localStorageUtils.setLocalStorageItem('user', userInfo)

                navigate('/home')
                document.getElementById('validation_error').innerText = ''
            }
        }).catch(err => {
            setIsLoading(false);
            let tempInvalidList = [...invalidList];
            // eslint-disable-next-line
            err?.response?.data?.message?.data && Object.entries(err?.response?.data?.message?.data).map((errField)=>{
                const errFieldId = `err_${errField[0]}`;
                const inputFieldId = errField[0];
                const errorMessage = errField[1];
                // Check if the error element exists before setting innerText and style
                const errorElement = document.getElementById(errFieldId);
                const inputElement = document.getElementById(inputFieldId);
                if (errorElement) {
                    errorElement.innerText = errorMessage;
                    if (inputElement) {
                        inputElement.style.borderColor = "#CA3434";
                    }
                }
                if (!invalidList.includes(inputFieldId)) {
                    tempInvalidList.push(inputFieldId)
                }
            })
            setInvalidList(tempInvalidList)
            document.getElementById('validation_error').innerText = err?.response?.data?.message?.message ?? defaultConnectionErrorMsg
            setIsValid(false)
        })

    }

    return (
        <div className={`w-full mb-8 sm:p-4 sm:rounded-none rounded-md ${formWrapperBackground}`}>
            <div className={`w-full rounded-md md:px-14 px-4 py-4 ${formBackground} grid shadow-cardShadow-md`}>
                <div className='grid place-items-center relative object-contain'>
                    <img src="/images/light/logo.webp" className='h-138px' alt="client logo" />
                </div>
                <div className='grid gap-2 place-items-center text-center mt-2 mb-8'>
                    <h2 className={`text-2xl font-bold ${formTitleColor}`}>{title}</h2>
                    <p className={formTitleDescriptionColor}>{description}</p>
                </div>
                <div className='grid gap-y-4'>
                    <form onSubmit={(e) => handleSignUp(e)} className='grid gap-3'>
                        {
                            formData.map((inputField, key) => {
                                return <div key={inputField.id}>
                                    <input tabIndex={inputField.id}
                                        key={inputField.id} 
                                        value={userCridentials[inputField.name] ?? ''} 
                                        onInvalid={e => e.target.setCustomValidity(inputField.validationText)}
                                        onInput={e => e.target.setCustomValidity('')}
                                        onChange={handleInputs}
                                        autoComplete="off"
                                        autoFocus= {key === 0 ? true : false}
                                        type={inputField.inputType} 
                                        name={inputField.name} 
                                        id={inputField.id} 
                                        placeholder={inputField.placeholder} 
                                        required={inputField.required} 
                                        className={`h-14 w-full  ${invalidList.includes(inputField.name) ? `border-b-2 ${inputErrorBorderColor} ${invalidInputBgColor}` : `${inputBorderColor} ${inputBackground} ${inputHoverBackground}`} ${placeholderColor} ${inputTextColor} ${ userCridentials[inputField.name] !== null && `invalid:border-b-2 ${invalidInputBorderColor}`} font-semibold placeholder:font-semibold focus:outline-none px-4`} 
                                    />
                                    <p key={`err_${inputField.id}`} className={`${validationErrTextColor} text-xs ${invalidList.includes(inputField.name) ? `block` : `hidden`}` } id={`err_${inputField.name}`}></p>
                                </div>
                                
                            })
                        }
                        <button tabIndex={9} disabled={isLoading} type="submit" className={`w-full h-14 ${loginSignUpBtnColor} ${loginSignUpBtnTextColor}`}>
                            {isLoading ? <LoaderEllipse /> : signUpBtnText}
                        </button>
                        <div className={`grid place-items-center ${infoTextColor} gap-1 text-center`}>
                            <p>{accessCodeInfo}</p>
                            <p>{signUpLeadingText} <button onClick={(e) => toggleForm(e)} className='underline'>{newUserLoginBtnText}</button></p>
                        </div>
                        <p className={`my-2 text-center text-lg font-bold ${validationErrTextColor} ${isValid ? `hidden` : `block`}`} id="validation_error"></p>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SignUp