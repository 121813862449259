import React, { useState } from 'react'
import LoginSignUpWrapper from '../components/login-signup/wrapper'
import Login from '../components/login-signup/login'
import SignUp from '../components/login-signup/signup';
import ForgotPassword from '../components/login-signup/ForgotPassword';
import { authUtils } from '../helpers/authHelper';
import { Navigate } from 'react-router-dom';

const LoginSignup = () => {

    const [isLogin, setIsLogin] = useState(true);
    const [isForgotPassword, setIsForgotPassword] = useState(false);

    const isAuthenticated = authUtils.isAuthenticated()

    if (isAuthenticated) {
        return <Navigate to="/home" />
    }

    const toggleForm = (e) => {
        e.preventDefault();
        setIsLogin(!isLogin)
        setIsForgotPassword(false)
    }

    const toggleForgotPasswordForm = (e) => {
        e.preventDefault();
        setIsLogin(false)
        setIsForgotPassword(true)
    }

    return (
        <LoginSignUpWrapper>
            {isLogin ? <Login toggleForm={toggleForm} toggleForgotPasswordForm={toggleForgotPasswordForm} /> : isForgotPassword ? (<ForgotPassword toggleForm={toggleForm} />) : (<SignUp toggleForm={toggleForm} />)}
        </LoginSignUpWrapper>
    )
}

export default LoginSignup