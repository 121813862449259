import React from 'react'
import { Navigate } from 'react-router-dom'
import { authUtils } from '../helpers/authHelper'

const PrivateRoute = ({ children }) => {
    const isAuthenticated = authUtils.isAuthenticated()
    // const isAuthenticated = true

    if (isAuthenticated) {
        return children
    }

    return <Navigate to="/" />
}

export default PrivateRoute