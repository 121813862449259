import { localStorageUtils } from "../helpers/storageHelper";
import api from "./axiosConfig"

export const refreshAccessToken = async () => {
    return api.post('/auth/user/refresh-token', {
        refreshToken: localStorageUtils.getLocalStoragItem('refreshToken')
    })
    .then(res => {
        if (res.status === 200) {
            const token = res.data.data.token.accessToken;
            localStorageUtils.setLocalStorageItem('token', token);
            return token;
        }
    })
    .catch(error => Promise.reject(error))
}