import React, { useContext } from 'react'
import FailedIcon from '../../common/failed-icon'
import SuccessIcon from '../../common/success-icon'
import { DataContext } from '../../../contexts/dataContext';

const ResultWithMsg = (props) => {
    const { isPass, resultMsg, description } = props;
    const context = useContext(DataContext);

    //site data
    const cyberSafetyAssessmentTryagainMsg = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentTryagainMsg ?? ''

    //site styles
    const resultMsgCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.resultMsgCol ?? 'extra-dark-gray'
    const resultDescriptionCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.resultDescriptionCol ?? 'dark-gray'

    const resultMsgColor = `text-${resultMsgCol}`
    const resultDescriptionColor = `text-${resultDescriptionCol}`

    return (
        <div className='w-full flex flex-col items-center'>
            <div className='w-full flex flex-col items-center space-y-6'>
                {isPass ?
                    <SuccessIcon />
                    :
                    <FailedIcon />
                }
                <div className={`text-center max-w-sm ${resultMsgColor} space-y-3`}>
                    <div className='font-semibold text-lg leading-7'>
                        <h1 className=' font-Publico'>{resultMsg}</h1>
                        {!isPass && <h1 className=''>{cyberSafetyAssessmentTryagainMsg}</h1>}
                    </div>
                    <p className={`text-sm font-SansPro ${resultDescriptionColor} font-normal`}>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default ResultWithMsg