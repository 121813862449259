import React, { useContext } from 'react'
import Generator from '../../helpers/generator';
import CustomFormValidatorMsg from '../common/cust-form-validator-msg';
import { DataContext } from '../../contexts/dataContext';

const QuestionCard = (props) => {
    const context = useContext(DataContext);

    //page data
    const formPopupValidationMsg = context?.siteData?.common?.formPopupValidationMsg ?? ''
    const noteprefix = context?.siteData?.digitalRiskAssessment?.draEmailNoteprefix ?? ''

    //page style
    const questionTextCol = context?.siteStyles?.digitalRiskAssessment?.assessment?.questionCard?.questionTextCol ?? 'simple-gray'
    const prefixTextCol = context?.siteStyles?.digitalRiskAssessment?.assessment?.questionCard?.prefixTextCol ?? 'medium-gray'

    let questionTextColor = `text-${questionTextCol}`
    let prefixTextColor = `text-${prefixTextCol}`

    const { handleAnswers, answers, currentQuestion, sectionId, currentAssessment, toggleValidatorMsg, questionType, subSection, sectionDetails } = props;

    return (
        <div className='w-full space-y-4'>
            <h1 className={`text-base font-SansPro font-semibold ${questionTextColor}`}>{currentAssessment?.question ?? ""}</h1>
            <div className={`w-full pb-2 relative font-SansPro font-normal`}>
                <div className='w-full space-y-8'>
                    {
                        currentAssessment && currentAssessment?.answerTypes &&
                        currentAssessment.answerTypes.map((inpt, key) => (
                            <Generator
                                key={`${sectionId}-${currentQuestion}-${inpt.id}`}
                                inputType={inpt.answerType ?? ''}
                                data={inpt.options ?? []}
                                questionId={currentQuestion}
                                sectionId={sectionId}
                                answerTypeId={inpt.id}
                                handleAnswers={handleAnswers}
                                answers={answers}
                                questionType={questionType}
                                subSection={subSection ?? key}
                                selectPlaceHolder={inpt.selectPlaceHolder ?? ''}
                            />

                        ))
                    }
                </div>

                {(typeof (sectionDetails[sectionId]) === 'object' ? !sectionDetails[sectionId][subSection] : !sectionDetails[sectionId]) &&
                    <CustomFormValidatorMsg
                        message={formPopupValidationMsg}
                        isToggle={toggleValidatorMsg}
                    />
                }

            </div>
            {currentAssessment?.note && (
                <div className={`flex ${prefixTextColor}`}>
                    <div className={`pt-2 pb-2 font-SansPro text-xs`}>{noteprefix}</div>
                    <div className={`w-full pt-2 pl-1 font-SansPro text-xs`}>{currentAssessment?.note}</div>
                </div>
            )}
        </div>

    )
}

export default QuestionCard