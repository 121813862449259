import React, { useState, useEffect } from 'react'
import { InnerHeader } from '../common/inner-header';
import DynamicBanner from './dynamic-banner';
import SectionChooser from './section-chooser';
import axios from 'axios';
import { localStorageUtils } from '../../helpers/storageHelper';
import { apiUrl, header } from '../../helpers/constants';

const DynamicInnerPageWrapper = (props) => {

    const { data, moduleName } = props;

    const [currentContent, setCurrentContent] = useState('introduction')
    const [assessments, setAssessments] = useState({})

    const [componentHistory, setComponentHistory] = useState([])
    const [isBackToPage, setIsBackToPage] = useState(true)

    const handleContentChange = (contentName, action) => {
        let tempComponentHistory = [...componentHistory]
        if (action === 'forward') {
            tempComponentHistory.push(currentContent)
            setCurrentContent(contentName)
        } else {
            //if ('back')
            let prevComp = tempComponentHistory.pop()
            tempComponentHistory.splice(componentHistory.indexOf(prevComp), 1)
            setCurrentContent(prevComp)
        }

        setComponentHistory(tempComponentHistory)
    }

    useEffect(() => {
        setIsBackToPage(componentHistory.length > 0 ? false : true)
    }, [componentHistory])

    useEffect(() => {
        //Api call for assessments
        axios.post(`${apiUrl}/question/fetch`, { module: moduleName, lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN" }, { headers: header }).then((res) => {
            if (res.status === 200) {
                const { data } = res.data;
                setAssessments(data)
            }
        })
            .catch((err) => console.log(err))
    }, [moduleName])

    return (
        <div className='w-full'>
            <InnerHeader
                hasBackBtn={true}
                hasHomeBtn={false}
                isBackToPage={isBackToPage}
                handleContentChange={handleContentChange}
            />
            <div className='w-full flex flex-col items-center'>
                <DynamicBanner
                    image={data?.dynamicBanner?.img ?? ''}
                    header={data?.dynamicBanner?.title ?? ''}
                />
                <div className='w-full py-5 max-w-4xl mt-28'>
                    {/* here comes dynamic components */}
                    <SectionChooser
                        section={currentContent}
                        data={data}
                        handleContentChange={handleContentChange}
                        questions={assessments}
                    />
                </div>
            </div>
        </div>
    )
}

export default DynamicInnerPageWrapper