import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { DataContext } from '../../../contexts/dataContext';

const ResultPageBtns = (props) => {
    // const { cyberSafetyAssessmentBackToTrainingBtnText, cyberSafetyAssessmentRestartBtnText } = cyberProtectData.cyberSafetyTraining
    const context = useContext(DataContext);
    
    //site data
    const cyberSafetyAssessmentBackToTrainingBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentBackToTrainingBtnText ?? ''
    const cyberSafetyAssessmentRestartBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentRestartBtnText ?? ''

    //site styles
    const cyberSafetyAssessmentBackToTrainingPassBtnStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentBackToTrainingPassBtnStyle ?? 'orange-bg-btn'
     const cyberSafetyAssessmentRestartBtnTextStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentRestartBtnTextStyle ?? 'orange-bg-btn'
     const backToCyberTrainingBtnStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentBackToCyberTrainingBtnStyle ?? 'orange-border-btn'  
 

    const { isPass, handleRestartAssessment } = props;
    return (
        <div className={`w-full`}>
            {isPass ?
                <div className='w-full flex justify-center font-SansPro'>
                    <Link to={'/cyber_training'}>
                        <div className={cyberSafetyAssessmentBackToTrainingPassBtnStyle}>{cyberSafetyAssessmentBackToTrainingBtnText}</div>
                    </Link>
                </div>
                :
                <div className='w-full flex flex-col items-center space-y-3'>
                    <button onClick={() => handleRestartAssessment()} className={cyberSafetyAssessmentRestartBtnTextStyle}>{cyberSafetyAssessmentRestartBtnText}</button>
                    <Link to={'/cyber_training'}>
                        <div className={`${backToCyberTrainingBtnStyle}`}>{cyberSafetyAssessmentBackToTrainingBtnText}</div>
                    </Link>
                </div>
            }
        </div>
    )
}

export default ResultPageBtns