import React, { useContext, useEffect, useState } from 'react'
import DynamicTitle from '../../components/common/dynamic-title'
import { InnerHeader } from '../../components/common/inner-header'
import SectionChooser from '../../components/digital-risk-assessment/section-chooser'
import { DataContext } from '../../contexts/dataContext'



const AssessmentPg = () => {

    const context = useContext(DataContext);

    const draPageTitle = context?.siteData?.digitalRiskAssessment?.draPageTitle ?? ''

    const [currentContent, setCurrentContent] = useState('assessment')
    const [componentHistory, setComponentHistory] = useState([])
    const [isBackToPage, setIsBackToPage] = useState(true)

    const handleContentChange = (contentName, action) => {
        let tempComponentHistory = [...componentHistory]
        if (action === 'forward') {
            tempComponentHistory.push(currentContent)
            setCurrentContent(contentName)
        } else {
            //if ('back')
            let prevComp = tempComponentHistory.pop()
            tempComponentHistory.splice(componentHistory.indexOf(prevComp), 1)
            setCurrentContent(prevComp)
        }

        setComponentHistory(tempComponentHistory)
    }

    useEffect(() => {
        setIsBackToPage(componentHistory.length > 0 ? false : true)
    }, [componentHistory])

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader
                    hasBackBtn={false}
                    hasHomeBtn={false}
                    isBackToPage={isBackToPage}
                    handleContentChange={handleContentChange}
                />
                <DynamicTitle
                    title={draPageTitle}
                    hasBorder={true}
                />
            </div>
            <div className='w-full flex flex-col items-center'>
                <div className='w-full max-w-4xl '>
                    <SectionChooser
                        section={currentContent}
                        handleContentChange={handleContentChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default AssessmentPg