import React, { createContext, Component } from "react";
import { authUtils } from "../helpers/authHelper";
import axios from "axios";
import { localStorageUtils } from "../helpers/storageHelper";
import { apiUrl, header, url } from "../helpers/constants";


export const DataContext = createContext();

class DataContextProvider extends Component {

    state = {
        siteData: {},
        siteStyles: {},
        isLoading: true,
        filters: {
            lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
        },
        userDetails: localStorageUtils.getLocalStoragItem('user') ?? {
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            policyNumber: '',
            type: '',
            positionTitle: '',
            parentId: ''
        },
        isLogged: authUtils.isAuthenticated() ?? false
    }
    componentDidMount() {

        if (this.state.isLogged) {

            //Loading data from localStorage if local storage have the data.
            if (localStorageUtils.isLocalStorageItemExpired('siteData') || localStorageUtils.isLocalStorageItemExpired('siteStyles')) {
                //Api call for both siteData and siteStyles
                axios.post(`${apiUrl}/client/contents`, { "domain": url, "lang": this.state.filters.lang, "content_type": "content" }, { headers: header })
                    .then((res) => {
                        if (res.status === 200) {
                            const { data } = res.data
                            this.setState((prevState) => ({
                                siteData: data?.content ?? {},
                                siteStyles: data?.style ?? {},
                                isLoading: false,
                                filters: {
                                    ...prevState,
                                    lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                                }
                            }), () => {
                                //TTL (Time to live)
                                let ttl = (2 * 3600) * 1000;

                                const now = new Date()

                                let siteDataItem = {
                                    values: this.state.siteData,
                                    expiry: now.getTime() + ttl,
                                }
                                let siteStylesItem = {
                                    values: this.state.siteStyles,
                                    expiry: now.getTime() + ttl,
                                }
                                localStorageUtils.setLocalStorageItem('siteData', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStyles', siteStylesItem)
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // If any error occour in the Api call to the server then the contents get's loaded from the local file.
                        axios.get(`/default-data-and-styles/defaultDataStyles.json`).then((res) => {
                            const { data } = res.data
                            this.setState((prevState) => ({
                                siteData: data?.content ?? {},
                                siteStyles: data?.style ?? {},
                                isLoading: false,
                                filters: {
                                    ...prevState,
                                    lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                                }
                            }), () => {
                                //TTL (Time to live)
                                let ttl = (2 * 3600) * 1000;

                                const now = new Date()

                                let siteDataItem = {
                                    values: this.state.siteData,
                                    expiry: now.getTime() + ttl,
                                }
                                let siteStylesItem = {
                                    values: this.state.siteStyles,
                                    expiry: now.getTime() + ttl,
                                }
                                localStorageUtils.setLocalStorageItem('siteData', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStyles', siteStylesItem)
                            })
                        })
                            .catch((error) => console.log(error))
                    })
            } else {
                //Fetching data from localStorage 
                const siteDataItem = localStorageUtils.getLocalStoragItem('siteData')
                const siteStylesItem = localStorageUtils.getLocalStoragItem('siteStyles')

                this.setState((prevState) => ({
                    siteData: siteDataItem.values,
                    siteStyles: siteStylesItem.values,
                    isLoading: false,
                    filters: {
                        ...prevState,
                        lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                    }
                }))
            }

        } else {

            if (localStorageUtils.isLocalStorageItemExpired('siteDataGeneral') || localStorageUtils.isLocalStorageItemExpired('siteStylesGeneral')) {

                //Api call for both siteData and siteStyles
                axios.post(`${apiUrl}/client/contents`, { "domain": url, "lang": this.state.filters.lang, "content_type": "general" }, { headers: header })
                    .then((res) => {
                        if (res.status === 200) {
                            const { data } = res.data

                            this.setState((prevState) => ({
                                siteData: data?.content ?? {},
                                siteStyles: data?.style ?? {},
                                isLoading: false,
                            }), () => {
                                //TTL (Time to live)
                                let ttl = (2 * 3600) * 1000;

                                const now = new Date()

                                let siteDataItem = {
                                    values: this.state.siteData,
                                    expiry: now.getTime() + ttl,
                                }
                                let siteStylesItem = {
                                    values: this.state.siteStyles,
                                    expiry: now.getTime() + ttl,
                                }
                                localStorageUtils.setLocalStorageItem('siteDataGeneral', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStylesGeneral', siteStylesItem)
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // If any error occour in the Api call to the server then the contents get's loaded from the local file.
                        axios.get(`/default-data-and-styles/defaultDataStyles.json`).then((res) => {
                            const { data } = res.data
                            this.setState((prevState) => ({
                                siteData: data?.content ?? {},
                                siteStyles: data?.style ?? {},
                                isLoading: false,
                            }), () => {
                                //TTL (Time to live)
                                let ttl = (2 * 3600) * 1000;

                                const now = new Date()

                                let siteDataItem = {
                                    values: this.state.siteData,
                                    expiry: now.getTime() + ttl,
                                }
                                let siteStylesItem = {
                                    values: this.state.siteStyles,
                                    expiry: now.getTime() + ttl,
                                }
                                localStorageUtils.setLocalStorageItem('siteDataGeneral', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStylesGeneral', siteStylesItem)
                            })
                        })
                            .catch((error) => console.log(error))
                    })
            } else {
                //Fetching data from localStorage 
                const siteDataItem = localStorageUtils.getLocalStoragItem('siteDataGeneral')
                const siteStylesItem = localStorageUtils.getLocalStoragItem('siteStylesGeneral')

                this.setState((prevState) => ({
                    siteData: siteDataItem.values,
                    siteStyles: siteStylesItem.values,
                    isLoading: false
                }))
            }


        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filters.lang !== this.state.filters.lang) {

            //Api call for both siteData and siteStyles according to filter changes
            axios.post(`${apiUrl}/client/contents`, { "domain": url, "lang": this.state.filters.lang, "content_type": "content" }, { headers: header })
                .then((res) => {
                    if (res.status === 200) {
                        const { data } = res.data
                        this.setState({
                            siteData: data?.content ?? {},
                            siteStyles: data?.style ?? {},
                            isLoading: false
                        }, () => {
                            //TTL (Time to live)
                            let ttl = (2 * 3600) * 1000;

                            const now = new Date()

                            let siteDataItem = {
                                values: this.state.siteData,
                                expiry: now.getTime() + ttl,
                            }
                            let siteStylesItem = {
                                values: this.state.siteStyles,
                                expiry: now.getTime() + ttl,
                            }
                            if (this.state.isLogged) {
                                localStorageUtils.setLocalStorageItem('siteData', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStyles', siteStylesItem)
                            } else {
                                localStorageUtils.setLocalStorageItem('siteDataGeneral', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStylesGeneral', siteStylesItem)
                            }

                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                    // If any error occour in the Api call to the server then the contents get's loaded from the local file.
                    axios.get(`/default-data-and-styles/defaultDataStyles.json`).then((res) => {
                        const { data } = res.data
                        this.setState({
                            siteData: data?.content ?? {},
                            siteStyles: data?.style ?? {},
                            isLoading: false
                        }, () => {
                            //TTL (Time to live)
                            let ttl = (2 * 3600) * 1000;

                            const now = new Date()

                            let siteDataItem = {
                                values: this.state.siteData,
                                expiry: now.getTime() + ttl,
                            }
                            let siteStylesItem = {
                                values: this.state.siteStyles,
                                expiry: now.getTime() + ttl,
                            }
                            if (this.state.isLogged) {
                                localStorageUtils.setLocalStorageItem('siteData', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStyles', siteStylesItem)
                            } else {
                                localStorageUtils.setLocalStorageItem('siteDataGeneral', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStylesGeneral', siteStylesItem)
                            }
                        })
                    })
                        .catch((error) => console.log(error))
                })
        }

        if (prevState.isLogged !== this.state.isLogged && this.state.isLogged){
            if (localStorageUtils.isLocalStorageItemExpired('siteData') || localStorageUtils.isLocalStorageItemExpired('siteStyles')) {
                //Api call for both siteData and siteStyles
                axios.post(`${apiUrl}/client/contents`, { "domain": url, "lang": this.state.filters.lang, "content_type": "content" }, { headers: header })
                    .then((res) => {
                        if (res.status === 200) {
                            const { data } = res.data
                            this.setState((prevState) => ({
                                siteData: data?.content ?? {},
                                siteStyles: data?.style ?? {},
                                isLoading: false,
                                filters: {
                                    ...prevState,
                                    lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                                }
                            }), () => {
                                //TTL (Time to live)
                                let ttl = (2 * 3600) * 1000;
    
                                const now = new Date()
    
                                let siteDataItem = {
                                    values: this.state.siteData,
                                    expiry: now.getTime() + ttl,
                                }
                                let siteStylesItem = {
                                    values: this.state.siteStyles,
                                    expiry: now.getTime() + ttl,
                                }
                                localStorageUtils.setLocalStorageItem('siteData', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStyles', siteStylesItem)
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        // If any error occour in the Api call to the server then the contents get's loaded from the local file.
                        axios.get(`/default-data-and-styles/defaultDataStyles.json`).then((res) => {
                            const { data } = res.data
                            this.setState((prevState) => ({
                                siteData: data?.content ?? {},
                                siteStyles: data?.style ?? {},
                                isLoading: false,
                                filters: {
                                    ...prevState,
                                    lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                                }
                            }), () => {
                                //TTL (Time to live)
                                let ttl = (2 * 3600) * 1000;
    
                                const now = new Date()
    
                                let siteDataItem = {
                                    values: this.state.siteData,
                                    expiry: now.getTime() + ttl,
                                }
                                let siteStylesItem = {
                                    values: this.state.siteStyles,
                                    expiry: now.getTime() + ttl,
                                }
                                localStorageUtils.setLocalStorageItem('siteData', siteDataItem)
                                localStorageUtils.setLocalStorageItem('siteStyles', siteStylesItem)
                            })
                        })
                            .catch((error) => console.log(error))
                    })
            } else {
                //Fetching data from localStorage 
                const siteDataItem = localStorageUtils.getLocalStoragItem('siteData')
                const siteStylesItem = localStorageUtils.getLocalStoragItem('siteStyles')

                this.setState((prevState) => ({
                    siteData: siteDataItem.values,
                    siteStyles: siteStylesItem.values,
                    isLoading: false,
                    filters: {
                        ...prevState,
                        lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                    }
                }))
            }
        }
    }

    handlefilters = (filterName, filterValue) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [filterName]: filterValue
            },
            isLoading: true
        }), () => {
            localStorageUtils.setLocalStorageItem('lang', this.state.filters.lang)
        })
    }

    handleUserDetails=(userInfo)=>{
        this.setState({
            userDetails:userInfo,
            isLogged:true
        })
    }

    render() {
        return (
            <DataContext.Provider value={{
                ...this.state,
                handlefilters: this.handlefilters,
                handleUserDetails: this.handleUserDetails
            }}>
                {this.props.children}
            </DataContext.Provider>
        )
    }
}
export default DataContextProvider