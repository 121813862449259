import React, { useContext, useEffect, useState } from 'react'

import Assessment from './assessment';
import AssessmentResult from './assessment-result';
import AssessmentScore from './assessment-score';
import CyberSecurityReport from './cyber-security-report';
import { DataContext } from '../../contexts/dataContext';
import { localStorageUtils } from '../../helpers/storageHelper';
import { apiUrl, header } from '../../helpers/constants';
import axios from 'axios';
import AssessmentResultWrapper from './assessment-result-wrapper';

const SectionChooser = ({ section, handleContentChange }) => {
    const context = useContext(DataContext);
    const moduleName = "DIGITAL_RISK_ASSESSMENT"

    const [draAssessment, setDraAssessment] = useState({})

    //page data
    // const draAssessment = context?.siteData?.digitalRiskAssessment?.draAssessment ?? {}
    const draGrandTotal = context?.siteData?.digitalRiskAssessment?.draGrandTotal ?? 0
    const draScoreStaringRange = context?.siteData?.digitalRiskAssessment?.draScoreStaringRange ?? 0
    const draScoreChartOptions = context?.siteData?.digitalRiskAssessment?.draScoreChartOptions ?? {}
    const draResultChartOptions = context?.siteData?.digitalRiskAssessment?.draResultChartOptions ?? {}
    const draChartData = context?.siteData?.digitalRiskAssessment?.draChartData ?? []
    const draScoreChartWidth = context?.siteData?.digitalRiskAssessment?.draScoreChartWidth ?? 0
    const draResultChartWidth = context?.siteData?.digitalRiskAssessment?.draResultChartWidth ?? 0
    const draInvalidSectionMsg = context?.siteData?.digitalRiskAssessment?.draInvalidSectionMsg ?? ''
    const draScoreRangeLowText = context?.siteData?.digitalRiskAssessment?.draScoreRangeLowText ?? ''
    const poorRangeScore = context?.siteData?.digitalRiskAssessment?.poorRangeScore ?? 67
    const goodRangeScore = context?.siteData?.digitalRiskAssessment?.goodRangeScore ?? 116
    const draScoreRangeMediumText = context?.siteData?.digitalRiskAssessment?.draScoreRangeMediumText ?? ''
    const draScoreRangeHighText = context?.siteData?.digitalRiskAssessment?.draScoreRangeHighText ?? ''

    //page style
    const draInvalidSectionMsgCol = context?.siteStyles?.digitalRiskAssessment?.draInvalidSectionMsgCol ?? 'extra-dark-gray'

    let draInvalidSectionMsgColor = `text-${draInvalidSectionMsgCol}`


    const [currentSection, setCurrentSection] = useState(0);
    const [toggleValidatorMsg, setToggleValidatorMsg] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [totalAnsweredQuestions, setTotalAnsweredQuestions] = useState(0);
    const [sectionDetails, setSectionDetails] = useState({})
    const [totalMarkScored, setTotalMarkScored] = useState(0);
    const [userScoreRange, setUserScoreRange] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [mailsendingstatus, setMailSendingStatus] = useState(0)
    const [apiValidationMessage, setApiValidationMessage] = useState("")
    // eslint-disable-next-line
    const [userResults, setUserResults] = useState([])
    const [assessmentMailId, setAssessmentMailId] = useState('');
    const [isInitialQuestionCall, setIsInitialQuestionCall] = useState(true);
    const [isResultApiFailed, setIsResultApiFailed] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const totalQuestions = draAssessment.questions ? Object.values(draAssessment.questions).length : 0;

    let userId = localStorageUtils.getLocalStoragItem('userId')
    let loginEmailId = localStorageUtils.getLocalStoragItem('email')


    /**
     * 
     * @param answer is the id of the choosen answer.
     * @param section Array position of the section.
     * @param questionType It indicate whether is there is multiple questions in the same section or not, values will be 'SINGLE' or 'MULTIPLE'
     * @param subSection Array position of the question.
     * @param answerType it indicates whether there is multiple answers for a single question or only a single answer,values will be 'single' or 'multi'.
     */
    const handleAnswers = ({ section, questionType, subSection, answerType, answer }) => {
        let tempAnswers = [...answers];
        let tempSectionDetails = { ...sectionDetails };
        if (questionType === 'SINGLE') {
            if (answerType === 'single') {
                tempAnswers[section].answers[subSection].answer = answer
                tempSectionDetails[section] = answer !== '' ? true : false
            } else {
                let ansArray = [...tempAnswers[section].answers[subSection].answer]
                ansArray.includes(answer) ?
                    ansArray.splice(ansArray.indexOf(answer), 1)
                    :
                    ansArray.push(answer)

                tempSectionDetails[section] = ansArray.length > 0 ? true : false
                tempAnswers[section].answers[subSection].answer = ansArray
            }
        } else {
            if (answerType === 'single') {
                tempAnswers[section].question[subSection].answer = answer
                tempSectionDetails[section][subSection] = answer !== '' ? true : false
            } else {
                let ansArray = [...tempAnswers[section].question[subSection].answer]

                ansArray.includes(answer) ?
                    ansArray.splice(ansArray.indexOf(answer), 1)
                    :
                    ansArray.push(answer)

                tempSectionDetails[section][subSection] = ansArray.length > 0 ? true : false
                tempAnswers[section].question[subSection].answer = ansArray
            }
        }

        let tempTotalAnsweredQuestions = 0;
        // eslint-disable-next-line
        Object.values(tempSectionDetails).map((section) => {
            if (typeof (section) === 'object') {
                let isAllSubQuestAnswered = Object.values(section).every(subsec => subsec === true)
                tempTotalAnsweredQuestions = isAllSubQuestAnswered ? tempTotalAnsweredQuestions + 1 : tempTotalAnsweredQuestions
            } else {
                tempTotalAnsweredQuestions = section === true ? tempTotalAnsweredQuestions + 1 : tempTotalAnsweredQuestions
            }
        })
        setAnswers(tempAnswers)
        setSectionDetails(tempSectionDetails)
        setTotalAnsweredQuestions(tempTotalAnsweredQuestions)
    }


    /**
     * 
     * @param operaton indicates next or preview
     */
    const handleQuestions = (operaton) => {
        if (operaton === 'previous') {
            setCurrentSection((prevState) => prevState - 1)
        } else {
            if (typeof (sectionDetails[currentSection]) === 'object') {
                let isAllSubQuestAnswered = Object.values(sectionDetails[currentSection]).every(subsec => subsec === true)
                if (isAllSubQuestAnswered) {
                    currentSection === Object.values(sectionDetails).length - 1 ? handleResult() : setCurrentSection((prevState) => prevState + 1)
                } else {
                    setToggleValidatorMsg(true)
                    let toggleTimer = setTimeout(() => {
                        setToggleValidatorMsg(false)
                        clearTimeout(toggleTimer)
                    }, 2000);
                }
            } else {
                if (sectionDetails[currentSection]) {
                    currentSection === Object.values(sectionDetails).length - 1 ? handleResult() : setCurrentSection((prevState) => prevState + 1)
                } else {
                    setToggleValidatorMsg(true)
                    let toggleTimer = setTimeout(() => {
                        setToggleValidatorMsg(false)
                        clearTimeout(toggleTimer)
                    }, 2000);
                }
            }
        }
    }

    const handleResult = () => {
        /**
         * Here we check whether the user needs result to mail or not.
         * If the "isResultToMail" is true then redirect to mail window other wise to result window.
         * 
         */
        if (draAssessment.isResultToMail) {
            handleContentChange('resultToMail', 'forward')
        } else {
            handleUserCredentials();
        }
    }

    const handleAssessmentMailId = (mailid) => {
        setAssessmentMailId(mailid)
        if (isInvalidEmail && isValidEmail(mailid)) {
            setIsInvalidEmail(false);
        }
    }
    const handleHideApiPopup = () => {
        setApiValidationMessage("");
        setIsResultApiFailed(false);
    };
    const handleIsInvalidEmail = (flag) => {
        setIsInvalidEmail(flag)
    }


    const isValidEmail = (email) => {
        // Regular expression to validate the email
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }
    const handleSendEmailToLoginedUser = () => {
        setMailSendingStatus(undefined);
        sendmail(true);
    }
    const handleUserCredentials = (e) => {

        e?.preventDefault();
        if (draAssessment.isResultToMail) {
            if (assessmentMailId !== '' && !isValidEmail(assessmentMailId)) {
                setIsLoading(false);
                setIsInvalidEmail(true);
                return;
            } else {
                setIsInvalidEmail(false);
            }
        }

        setIsLoading(true)
        setIsResultApiFailed(false);
        sendmail(false);
    }

    const sendmail = (isLoginMail) => {
        header["Content-type"] = "application/json"
        axios.post(`${apiUrl}/auth/user/answer`, {
            userId: userId,
            emailId: isLoginMail ? loginEmailId : assessmentMailId,
            questionId: draAssessment.id,
            answers: answers,
        }, { headers: header }).then((res) => {
            if (res.status === 200) {
                const { data } = res.data
                let score = data.score
                console.log(res.data)
                setUserResults(data)
                setIsLoading(false)
                setTotalMarkScored(score > 0 ? score : '0')
                setMailSendingStatus(assessmentMailId !== '' ? res.data?.error : undefined)
                setIsResultApiFailed(false)
                setUserScoreRange(score <= poorRangeScore ? draScoreRangeLowText : (score <= goodRangeScore && score > poorRangeScore) ? draScoreRangeMediumText : score > goodRangeScore && draScoreRangeHighText)
                handleContentChange('assessment score', 'forward')
            }
        })
            .catch(err => {
                console.log(err)
                setIsResultApiFailed(true)
                setApiValidationMessage(err?.response?.data?.message)
                setIsLoading(false)
            })
    }
    useEffect(() => {
        if (isInitialQuestionCall) {
            //Api call for assessments
            axios.post(`${apiUrl}/question/fetch`, { module: moduleName, lang: localStorageUtils.getLocalStoragItem('lang') ?? "EN" }, { headers: header }).then((res) => {
                if (res.status === 200) {
                    const { data } = res.data;
                    // console.log(data)
                    setDraAssessment(data)

                    //setting the answer formate
                    let tempAnswers = []
                    //sectionDetails for handling the answered questions
                    let tempSectionDetails = {}
                    // eslint-disable-next-line
                    data?.questions && data.questions.map((section, key) => {
                        tempSectionDetails[key] = section.type === "MULTIPLE" ? {} : false
                        let pushValue = section.type === "MULTIPLE" ? { "id": section.id, "question": [] } : { "id": section.id, "answers": [] }
                        tempAnswers.push(pushValue)
                        if (typeof (section.question) === 'object') {
                            // eslint-disable-next-line
                            section.question.map((question, questKey) => {
                                tempSectionDetails[key][questKey] = false
                                // eslint-disable-next-line
                                question.answerTypes.map((ansType) => {
                                    tempAnswers[key].question.push({
                                        "id": question.id,
                                        "answer": ansType.answerType === 'checkbox' ? [] : ''
                                    })
                                })
                            })
                        } else {
                            // eslint-disable-next-line
                            section.answerTypes.map((ansType) => {
                                tempAnswers[key].answers.push({
                                    "id": ansType.id,
                                    "answer": ansType.answerType === 'checkbox' ? [] : ''
                                })
                            })
                        }
                    })
                    setAnswers(tempAnswers)
                    setSectionDetails(tempSectionDetails)
                    setIsInitialQuestionCall(false)
                }
            })
                .catch((err) => console.log(err))
        }
    }, [isInitialQuestionCall])

    switch (section) {
        case 'assessment':
            return (
                <Assessment
                    assessments={draAssessment?.questions ?? []}
                    currentSection={currentSection}
                    totalAnsweredQuestions={totalAnsweredQuestions}
                    totalQuestions={totalQuestions}
                    answers={answers}
                    sectionDetails={sectionDetails}
                    handleAnswers={handleAnswers}
                    handleQuestions={handleQuestions}
                    toggleValidatorMsg={toggleValidatorMsg}
                />
            )
        case 'resultToMail':
            return (
                <AssessmentResultWrapper
                    totalAnsweredQuestions={totalAnsweredQuestions}
                    totalQuestions={totalQuestions}
                    assessmentMailId={assessmentMailId}
                    handleAssessmentMailId={handleAssessmentMailId}
                    handleContentChange={handleContentChange}
                    handleResultCall={handleUserCredentials}
                    isLoading={isLoading}
                    apiValidationMessage={apiValidationMessage}
                    isResultApiFailed={isResultApiFailed}
                    handleHideApiPopup={handleHideApiPopup}
                    isInvalidEmail={isInvalidEmail}
                    handleIsInvalidEmail={handleIsInvalidEmail}
                />
            )
        case 'assessment score':
            return (
                <AssessmentScore
                    chartData={draChartData}
                    chartOptions={draScoreChartOptions}
                    totalMarkScored={totalMarkScored}
                    userScoreRange={userScoreRange}
                    handleContentChange={handleContentChange}
                    grandTotal={draGrandTotal}
                    scoreStaringRange={draScoreStaringRange}
                    scoreChartWidth={draScoreChartWidth}
                    mailsendingstatus={mailsendingstatus}
                    handleSendEmailToLoginedUser={handleSendEmailToLoginedUser}
                />
            )
        case 'assessment result':
            return (
                <AssessmentResult
                    chartData={draChartData}
                    chartOptions={draResultChartOptions}
                    totalMarkScored={totalMarkScored}
                    poorRangeScore={poorRangeScore}
                    goodRangeScore={goodRangeScore}
                    userScoreRange={userScoreRange}
                    handleContentChange={handleContentChange}
                    grandTotal={draGrandTotal}
                    scoreStaringRange={draScoreStaringRange}
                    resultChartWidth={draResultChartWidth}
                />
            )
        case 'cyber security report':
            return (
                <CyberSecurityReport
                    handleContentChange={handleContentChange}
                />
            )

        default: return <h1 className={`font-semibold font-Publico ${draInvalidSectionMsgColor}`}>{draInvalidSectionMsg}</h1>
    }
}

export default SectionChooser