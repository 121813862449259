import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import SwitchLanguage from '../common/switch-language'
import { DataContext } from '../../contexts/dataContext'

const LoginSignUpWrapper = (props) => {
    const context = useContext(DataContext);

    //site data
    let axaLogoTitle = context.siteData?.axaSpecific?.axaCommon?.axaLogoTitle ?? ""
    let copyRightText = context.siteData?.axaSpecific?.axaCommon?.copyRightText ?? ""
    let urls = context.siteData?.axaSpecific?.axaCommon?.urls ?? []
    let title = context.siteData?.axaSpecific?.axaCommon?.title ?? []

    //site style
    let pageBg = context.siteStyles?.axaSpecific?.pageBg ?? 'deep-blue'
    let titleCol = context.siteStyles?.axaSpecific?.titleCol ?? 'white'
    let linkCol = context.siteStyles?.axaSpecific?.linkCol ?? 'white'

    let pageBackground = `bg-${pageBg}`
    let titleColor = `text-${titleCol}`
    let linkColor = `text-${linkCol}`


    return (
        <div className={`fixed top-0 left-0 ${pageBackground} w-full font-SansPro min-h-screen h-full overflow-y-auto md:pt-28 pt-14 pb-6 px-4`}>
            <div className='max-w-xl mx-auto relative'>
                <div className='w-full'>
                    <div className={`text-center md:mb-14 mb-10 md:text-7xl font-Publico text-5xl ${titleColor} leading-none`}>
                        {
                            title.map(item => <p key={item}>{item}</p>)
                        }
                        <div className='sm:hidden block mt-10'>
                            <SwitchLanguage />
                        </div>
                    </div>
                    {props.children}
                    <div className={`grid gap-y-12 place-items-center text-center ${linkColor}`}>
                        <div className=''>
                            <h2>{axaLogoTitle}</h2>
                            <div className='h-20 w-48 mt-3'>
                                <img src="/images/boxx.png" className='h-full w-full object-contain' alt="solution provider" />
                            </div>
                        </div>
                        <div className=''>
                            {
                                urls.map(url => (
                                    <p key={url.id}>{url.leadingText} <span className='underline'><Link to={url.url} target={'_self'}>{url.linkText}</Link></span></p>
                                ))
                            }
                        </div>
                        <p className=''>{copyRightText}</p>
                    </div>
                </div>
                <div className='sm:block hidden absolute top-0 lg:-right-40 right-0'>
                    <SwitchLanguage />
                </div>
            </div>
        </div>
    )
}

export default LoginSignUpWrapper