import React, { useContext } from 'react'
import Assessment from './inner-page-components/assessment'
import ELearning from './inner-page-components/e-learning'
import Introduction from './inner-page-components/introduction'
import { DataContext } from '../../contexts/dataContext'

const SectionChooser = ({ section, data, handleContentChange, questions }) => {
    const context = useContext(DataContext);

    //site data
    const cyberSafetySectionHandleErrMsg = context?.siteData?.cyberSafetyTraining?.cyberSafetySectionHandleErrMsgCol ?? ''

    //site style
    const cyberSafetySectionHandleErrMsgCol = context?.siteData?.cyberSafetyTraining?.cyberSafetySections?.cyberSafetySectionHandleErrMsgCol ?? 'extra-dark-gray'

    const cyberSafetySectionHandleErrMsgColor = `text-${cyberSafetySectionHandleErrMsgCol}`

    switch (section) {
        case 'introduction':
            return (
                <Introduction
                    handleContentChange={handleContentChange}
                    description={data?.introduction?.description ?? ''}
                    moduleDescription={data?.introduction?.moduleDescription ?? ''}
                    modules={data?.introduction?.modules ?? []}
                    guideLineContent={data?.introduction?.guideLineContent ?? ''}
                />
            )
        case 'e-learnig':
            return (
                <ELearning
                    handleContentChange={handleContentChange}
                    description={data?.eLearning?.description ?? ''}
                    fileContent={data?.eLearning?.fileContent ?? []}
                    video={data?.eLearning?.video ?? ''}
                    currentPage={data?.pathName ?? ''}
                />
            )
        case 'assessment':
            return (
                <Assessment
                    questions={questions ?? {}}
                    passLimit={questions?.passLimit ?? 0}
                />
            )

        default: return <h1 className={`font-semibold font-Publico ${cyberSafetySectionHandleErrMsgColor}`}>{cyberSafetySectionHandleErrMsg}</h1>
    }
}

export default SectionChooser