import moment from 'moment';
import { useContext } from 'react';
import { DataContext } from '../../contexts/dataContext';

const Card = ({ data, handleLearnMore }) => {

    const context = useContext(DataContext);

    //page data
    const breacheResultCardBtnText = context?.siteData?.checkForBreaches?.breacheResultCardBtnText ?? ''
    const breacheResultCardCompremisedDataLabel = context?.siteData?.checkForBreaches?.breacheResultCardCompremisedDataLabel ?? ''
    const breacheResultCardDateLabel = context?.siteData?.checkForBreaches?.breacheResultCardDateLabel ?? ''

    //page style
    const borderCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.informationCard?.borderCol ?? 'dark-blue'
    const cardBgCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.informationCard?.cardBgCol ?? 'light-blue'
    const breachNameTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.informationCard?.breachNameTextCol ?? 'extra-dark-gray'
    const labelCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.informationCard?.labelCol ?? 'medium-gray'
    const dataCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.informationCard?.dataCol ?? 'extra-dark-gray'
    const breacheResultCardBtnTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.informationCard?.breacheResultCardBtnTextCol ?? 'simple-gray'

    const borderColor = `border-${borderCol}`
    const cardBgColor = `bg-${cardBgCol}`
    const breachNameTextColor = `text-${breachNameTextCol}`
    const labelColor = `text-${labelCol}`
    const dataColor = `text-${dataCol}`
    const breacheResultCardBtnTextColor = `text-${breacheResultCardBtnTextCol}`

    const formatedDate = moment(data.AddedDate).format('MMM DD, YYYY');
    return (
        <div className={`w-full rounded-xl px-5 py-2.5 space-y-5 border-.5 ${borderColor} ${cardBgColor} `}>
            <div className='space-y-1'>
                <h1 className={`text-lg ${breachNameTextColor} font-semibold font-Publico`}>{data.Name}</h1>
                <div className='space-y-1'>
                    <p className={`mt-0 text-xs m-0 ${labelColor} font-SansPro`}>{breacheResultCardDateLabel}</p>
                    <p className={`mt-0 text-xs m-0 ${dataColor} font-normal  font-SansPro`}>{formatedDate}</p>
                </div>
                <div className='space-y-1'>
                    <p className={`mt-0 text-xs m-0 ${labelColor} font-normal font-SansPro`}>{breacheResultCardCompremisedDataLabel}</p>
                    <p className={`mt-0 text-xs m-0 ${dataColor}  font-SansPro`}>{data.DataClasses.join(", ")}</p>
                </div>
            </div>
            <div className='w-full space-y-2'>
                <p className='text-xs font-normal font-SansPro'>{ }</p>
                <div>
                    <div className='flex items-center space-x-3 cursor-pointer' onClick={() => handleLearnMore(data)}>
                        <p className={`font-normal text-xs font-SansPro ${breacheResultCardBtnTextColor} uppercase `}>{breacheResultCardBtnText}</p>
                        <img src={`/images/arrow-right-circle-small.png`} alt={'arrow circle'} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Card;