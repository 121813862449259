import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../contexts/dataContext'

const PageNotFound = () => {
  const context = useContext(DataContext);

  const pageNotFoundHomeBtnText = context?.siteData?.pageNotFound?.pageNotFoundHomeBtnText ?? 'Back To Home'
  const pageNotFoundMsg = context?.siteData?.pageNotFound?.pageNotFoundMsg ?? ''
  const pageNotFoundTitle = context?.siteData?.pageNotFound?.pageNotFoundTitle ?? ''
  
  const btnStyle = context?.siteStyles?.pageNotFound?.btnStyle ?? 'yellow-border-btn'

  return (
    <div className='w-full flex items-center justify-center md:min-h-dynamicContentHeight min-h-dynamicContentHeightSm'>
      <div className='max-w-4xl w-11/12 flex flex-col items-center justify-center space-y-8'>
        <div className='w-full flex flex-col items-center justify-center font-Publico'>
          <h1 className='font-bold text-7xl md:text-8xl tracking-wide bg-gradient-to-br from-black via-medium-purple to-black bg-clip-text text-transparent'>{pageNotFoundTitle}</h1>
          <p className='font-semibold sm:text-xl text-base'>{pageNotFoundMsg}</p>
        </div>
        <div className='w-full flex items-center justify-center'>
          <Link to={'/home'}>
            <button className={btnStyle}>
              {pageNotFoundHomeBtnText}
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound