import React, { useContext } from 'react'
import QuestionCard from './question-card'
import { DataContext } from '../../contexts/dataContext';

const AssessmentWrapper = ({
    sectionId,
    handleAnswers,
    answers,
    sectionDetails,
    toggleValidatorMsg,
    questions,
    questTitle,
    questionType
}) => {

    const context = useContext(DataContext);

    //page styles
    const questionTitleTextCol = context?.siteStyles?.digitalRiskAssessment?.assessment?.questionTitleTextCol ?? 'extra-dark-gray'

    let questionTitleTextColor = `text-${questionTitleTextCol}`

    return (
        <div className='w-full space-y-3'>
            <h1 className={`text-base font-semibold ${questionTitleTextColor}`}>{questTitle}</h1>
            <div className='w-full space-y-16'>
                {
                    questions.map((assessment, key) => (
                        <QuestionCard key={`${sectionId}-${assessment.id}`}
                            handleAnswers={handleAnswers}
                            answers={answers}
                            sectionDetails={sectionDetails}
                            currentQuestion={assessment.id}
                            sectionId={sectionId}
                            currentAssessment={assessment}
                            toggleValidatorMsg={toggleValidatorMsg}
                            questionType={questionType}
                            subSection={key }
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default AssessmentWrapper