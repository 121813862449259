import React, { useContext, useEffect } from 'react'
import HeadingText from '../../components/common/heading-text'
import { InnerHeader } from '../../components/common/inner-header'
import Footer from '../../components/insurance-coverage/footer'
import MarkdownWrapper from '../../components/common/markdown-wrapper'
import { DataContext } from '../../contexts/dataContext'


const InsuranceCoverage = () => {

    const context = useContext(DataContext);
    console.log("context")
    console.log(context)
    const insuranceCoverageTitle = context?.siteData?.insuranceCoverage?.insuranceCoverageTitle ?? ""
    const markdownData = context?.siteData?.insuranceCoverage?.markdownData ?? ""

    let stringify = JSON.stringify(markdownData)
    let markdown = JSON.parse(stringify);

    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader hasBackBtn={true} hasHomeBtn={true} />
            </div>
            <HeadingText title={insuranceCoverageTitle} />
            <div className='w-full py-6 prose prose-li:marker:text-inherit min-w-full font-SansPro'>
                <MarkdownWrapper
                    dataset={markdown}
                />
            </div>
            <Footer />
        </div>
    )
}

export default InsuranceCoverage