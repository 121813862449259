import React from 'react'
import GradiantCard from '../common/gradiant-card'

const SubscriptionDetails = () => {
  return (
    <GradiantCard>
        <div className='w-full bg-transparent px-11 pt-3 text-blue-900'>
          <p className='text-sm font-medium'>You paid House Rent 152 days to</p>
        </div>
    </GradiantCard>
  )
}

export default SubscriptionDetails