import React from 'react'

const Footer = ({footerContent}) => {
  return (
    <div className='text-xxs text-slate-400 py-16 text-center grid justify-center'>
      <div className='w-full max-w-4xl'>
        <p>{footerContent}</p>
      </div>
    </div>
  )
}

export default Footer