import React, { useContext } from 'react'
import { DataContext } from '../../../contexts/dataContext';

const Introduction = (props) => {
    const { handleContentChange, description, moduleDescription, modules, guideLineContent } = props;

    const context = useContext(DataContext);

    //page data
    const cyberSafetyIntroSectionTitle = context?.siteData?.cyberSafetyTraining?.cyberSafetyIntroSectionTitle ?? ''
    const cyberSafetyIntroSectionBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyIntroSectionBtnText ?? ''

    //page styles
    const titleTextCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.introductionSection?.titleTextCol ?? 'simple-gray'
    const buttonStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.introductionSection?.buttonStyle ?? 'orange-bg-btn'

    const titleTextColor = `text-${titleTextCol}`

    return (
        <div className={`w-full space-y-6 ${titleTextColor}`}>
            <h1 className='text-base font-semibold font-Publico'>{cyberSafetyIntroSectionTitle}</h1>
            <div className='w-full space-y-3 text-sm font-normal font-SansPro'>
                <p>{description}​</p>
                <div className='w-full space-y-3'>
                    <p>{moduleDescription}</p>
                    <ul className='list-disc list-inside pl-3'>
                        {modules.map((module,key)=>(
                            <li key={key}>{module}</li>

                        ))}
                    </ul>
                </div>
                <p>{guideLineContent}</p>
            </div>
            <div className='w-full pt-10 flex justify-center'>
                <button onClick={()=>handleContentChange('e-learnig','forward')} className={ buttonStyle }>{cyberSafetyIntroSectionBtnText}</button>
            </div>
        </div>
    )
}

export default Introduction