import React, { useEffect, useState } from 'react';
import { InnerHeader } from '../components/common/inner-header';
import MarkdownWrapper from '../components/common/markdown-wrapper';
import { localStorageUtils } from '../helpers/storageHelper';
import LoaderEllipse from '../components/common/loader-ellipse';

const PrivacyPolicy = () => {
    const [markdown, setMarkdown] = useState('');

    useEffect(() => {
        const fetchMarkdown = async () => {
            try {
                const lang = localStorageUtils.getLocalStoragItem('lang') ?? "EN"
                let privacyPolicyPath = 'markdown/privacy-policy.md';
                switch (lang) {
                    case 'EN':
                        privacyPolicyPath = 'markdown/privacy-policy.md';
                        break;
                    case 'ES':
                        privacyPolicyPath = 'markdown/privacy-policy-es.md';
                        break;
                    default:
                        privacyPolicyPath = 'markdown/privacy-policy.md';
                        break;
                }
                const response = await fetch(privacyPolicyPath);
                const data = await response.text();
                setMarkdown(data);
            } catch (error) {
                console.error('Error fetching MD file:', error);
            }
        };

        fetchMarkdown();
    }, []);

    return (
        <div className='w-full'>
            <InnerHeader hasBackBtn={false} hasHomeBtn={false} />
            <div className='w-full md:text-xl.5 text-xl.5 leading-7 text-extra-dark-gray font-SansPro text-left'>
                <div className='max-w-[825px] mx-auto py-6 prose prose-li:marker:text-inherit prose-base  font-SansPro'>
                    {markdown ? (
                        <MarkdownWrapper dataset={markdown} />
                    ) : (
                        <LoaderEllipse />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
