import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext'

const CustomFormValidatorMsg = ({ message, isToggle }) => {
    const context = useContext(DataContext);

    const borderCol = context?.siteStyles?.common?.formValidationMsg?.borderCol ?? 'input-border'
    const iconBg = context?.siteStyles?.common?.formValidationMsg?.iconBg ?? 'btn-orange'
    const iconCol = context?.siteStyles?.common?.formValidationMsg?.iconCol ?? 'white'
    const msgCol = context?.siteStyles?.common?.formValidationMsg?.msgCol ?? 'medium-gray'
    const pointerCol = context?.siteStyles?.common?.formValidationMsg?.pointerCol ?? 'input-border'

    const borderColor = `border-${borderCol}`
    const iconBgColor = `bg-${iconBg}`
    const iconColor = `text-${iconCol}`
    const msgColor = `text-${msgCol}`
    const pointerColor = `border-b-${pointerCol}`

    return (
        <div className={`absolute top-full -left-3 transform duration-700 cursor-default ${isToggle ? `opacity-100` : `opacity-0`}`}>
            <div className={`flex items-center relative space-x-2 border ${borderColor} rounded-md px-2 py-1`}>
                <div className={`flex items-center justify-center h-4 w-4 rounded-sm ${iconBgColor}`}>
                    <p className={`text-sm ${iconColor}`}>!</p>
                </div>
                <p className={`text-xs font-normal ${msgColor}`}>{message}</p>
                <div className={`border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 ${pointerColor} absolute bottom-full left-0 0`}>
                    
                </div>
            </div>
        </div>
    )
}

export default CustomFormValidatorMsg