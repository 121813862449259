import React from 'react'

const ContentSection = ({ sec1Content, sec2Content }) => {
  return (
    <div className='w-full grid justify-center'>
      <div className='w-full max-w-4xl space-y-6 py-12 px-4'>
        <p className=''>{sec1Content}</p>
        <p className='' dangerouslySetInnerHTML={{ __html: sec2Content }} />
      </div>
    </div>
  )
}

export default ContentSection