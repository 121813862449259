import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router'
import BreachResults from '../../components/check-for-breaches/breach-results'
import { InnerHeader } from '../../components/common/inner-header';
import HeadingText from '../../components/common/heading-text';
import { DataContext } from '../../contexts/dataContext';

function CheckBreachesResults() {
  const context = useContext(DataContext);

  //page data
  const breachesTitle = context?.checkForBreaches?.breachesTitle ?? ''

  const location = useLocation();
  if (!location.state) {
    return <Navigate to={'/check_for_breaches'} />
  }
  return (
    <>
      <div className='w-full'>
        <div className='w-full'>
          <InnerHeader hasBackBtn={true} />
        </div>
        <HeadingText title={breachesTitle} />
        <BreachResults data={location.state.breaches && location.state.breaches.length > 0 ? location.state.breaches[0] : {}} />
      </div>
    </>
  )
}

export default CheckBreachesResults