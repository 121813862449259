import React from 'react'

const Header = () => {
  return (
    <div className='w-full flex items-center justify-between'>
        <div className='flex space-x-2 items-center text-black pr-2 pl-1 py-1 rounded bg-lite-gray'>
            <div className='rounded bg-lite-yellow flex items-center justify-center h-6 w-6'>
                <img src="/images/chevron-up.png" alt="uparrow"/>
            </div>
            <p className=''>CREDIT</p>
        </div>
        <div className='pr-2 pl-1 py-1 rounded-full flex items-center space-x-2 bg-lite-gray'>
            <div className='bg-lite-yellow rounded-full w-6 h-6'/>
            <p className='text-sm'>My Rewards</p>
        </div>
    </div>
  )
}

export default Header