import React, { useContext } from 'react'
import DoughnutChart from '../charts/doughnut-chart'
import { DataContext } from '../../contexts/dataContext'

const AssessmentResult = ({ chartData, chartOptions, resultChartWidth, totalMarkScored, userScoreRange, handleContentChange, grandTotal, scoreStaringRange, poorRangeScore, goodRangeScore }) => {

  const context = useContext(DataContext);

  //site data
  const draResultSectionTitle = context?.siteData?.digitalRiskAssessment?.draResultSection?.draResultSectionTitle ?? ''
  const draResultSectionTitleDesription = context?.siteData?.digitalRiskAssessment?.draResultSection?.draResultSectionTitleDesription ?? ''
  const draResultClasification = context?.siteData?.digitalRiskAssessment?.draResultSection?.draResultClasification ?? {}
  const draResultSectionLinkText = context?.siteData?.digitalRiskAssessment?.draResultSection?.draResultSectionLinkText ?? ''

  //site styles
  const titleDescriptionCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.titleDescriptionCol ?? 'simple-gray'
  const descriptionCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.descriptionCol ?? 'simple-gray'
  const linkTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.linkTextCol ?? 'simple-gray'
  const lowScoreBgCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.lowScoreBgCol ?? 'err-input-border'
  const mediumScoreBgCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.mediumScoreBgCol ?? 'warning'
  const highScoreBgCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.highScoreBgCol ?? 'success'
  const lowScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.lowScoreTextCol ?? 'err-input-border'
  const mediumScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.mediumScoreTextCol ?? 'extra-dark-yellow'
  const highScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.highScoreTextCol ?? 'success'

  let titleDescriptionColor = `text-${titleDescriptionCol}`
  let descriptionColor = `text-${descriptionCol}`
  let linkTextColor = `text-${linkTextCol}`

  let lowScoreBgColor = `bg-${lowScoreBgCol}`
  let mediumScoreBgColor = `bg-${mediumScoreBgCol}`
  let highScoreBgColor = `bg-${highScoreBgCol}`
  let lowScoreTextColor = `text-${lowScoreTextCol}`
  let mediumScoreTextColor = `text-${mediumScoreTextCol}`
  let highScoreTextColor = `text-${highScoreTextCol}`

  return (
    <div className='w-full space-y-7 py-7'>
      <div className='flex items-end space-x-5'>
        <div className={`space-y-5 ${titleDescriptionColor}`}>
          <h1 className='text-base font-Publico font-semibold '>{draResultSectionTitle}</h1>
          <p className='text-sm font-normal  font-SansPro'>{draResultSectionTitleDesription}</p>
        </div>
        <div className='w-32 h-32 mx-auto rounded-full'>
          <DoughnutChart
            data={chartData}
            options={chartOptions}
            isSliced={true}
            hasIndicator={true}
            range={[scoreStaringRange, grandTotal]}
            totalMarkScored={totalMarkScored}
            userScoreRange={userScoreRange}
            isSmallChart={true}
            chartWidth={resultChartWidth}
          />
        </div>
      </div>
      <div className='w-full space-y-5'>
        {
          Object.entries(draResultClasification).map((result) => (
            <div className='w-full space-y-2.5' key={result[1].type}>
              <div className='flex items-center space-x-2.5'>
                <p className={`h-3.5 w-3.5 rounded-full ${result[1].type === 'poor' ? lowScoreBgColor : result[1].type === 'good' ? mediumScoreBgColor : highScoreBgColor} min-w-max`}></p>
                <h1 className={` font-Publico ${result[1].type === 'poor' ? lowScoreTextColor : result[1].type === 'good' ? mediumScoreTextColor : highScoreTextColor} text-lg font-normal pt-1`}>{`${result[1].title} (${result[1].type === 'poor' ? 
                `0-${poorRangeScore}` : result[1].type === 'good' ? `${poorRangeScore+1}-${goodRangeScore}` : `${goodRangeScore+1}-${grandTotal}`})`}</h1>
              </div>
              <p className={`text-sm font-SansPro  font-normal ${descriptionColor}`}>{result[1].description}</p>
            </div>
          ))
        }
      </div>
      <button onClick={() => handleContentChange('cyber security report', 'forward')} className='flex items-center space-x-2'>
        <p className={`text-sm font-semibold ${linkTextColor}`}>{draResultSectionLinkText}</p>
        <img src="/images/arrow-right-circle-red.png" alt="arrow-right" className='h-5 w-5 object-contain' />
      </button>
    </div>
  )
}

export default AssessmentResult