import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import api from '../../api/axiosConfig';
import { InnerHeader } from '../../components/common/inner-header';
import LoaderEllipse from '../../components/common/loader-ellipse';
import HeadingText from '../../components/common/heading-text';
import { DataContext } from '../../contexts/dataContext';

const CheckForBreaches = () => {

    const context = useContext(DataContext);

    //page data
    let defaultConnectionErrorMsg = context?.siteData?.common?.defaultConnectionErrorMsg ?? ''
    let breachesIntroduction = context?.siteData?.checkForBreaches?.breachesIntroduction ?? {}
    let breachesSearchBtnText = context?.siteData?.checkForBreaches?.breachesSearchBtnText ?? ''
    let breachesSearchInputCustValidationMsg = context?.siteData?.checkForBreaches?.breachesSearchInputCustValidationMsg ?? ''
    let breachesSearchInputPlaceHolder = context?.siteData?.checkForBreaches?.breachesSearchInputPlaceHolder ?? ''
    let breachesTitle = context?.siteData?.checkForBreaches?.breachesTitle ?? ''

    //page style's
    const introTextCol = context?.siteStyles?.checkForBreaches?.introTextCol ?? 'simple-gray'
    const inputBorderCol = context?.siteStyles?.checkForBreaches?.inputBorderCol ?? 'input-border'
    const invalidInputBorderCol = context?.siteStyles?.checkForBreaches?.invalidInputBorderCol ?? 'err-input-border'
    const inputBgCol = context?.siteStyles?.checkForBreaches?.inputBgCol ?? 'app-bg'
    const inputTextCol = context?.siteStyles?.checkForBreaches?.inputTextCol ?? 'simple-gray'
    const inputPlaceholderCol = context?.siteStyles?.checkForBreaches?.inputPlaceholderCol ?? 'medium-gray'
    const invalidMsgTextCol = context?.siteStyles?.checkForBreaches?.invalidMsgTextCol ?? 'err-text'
    const searchBtnTextCol = context?.siteStyles?.checkForBreaches?.searchBtnTextCol ?? 'extra-dark-gray'
    const searchBtnSearchTimeBgCol = context?.siteStyles?.checkForBreaches?.searchBtnSearchTimeBgCol ?? 'range-fill-bg'
    const searchBtnBgCol = context?.siteStyles?.checkForBreaches?.searchBtnBgCol ?? 'dark-yellow'

    let introTextColor = `text-${introTextCol}`
    let inputBorderColor = `border-${inputBorderCol}`
    let invalidInputBorderColor = `border-${invalidInputBorderCol}`
    let inputBgColor = `bg-${inputBgCol}`
    let inputTextColor = `text-${inputTextCol}`
    let inputPlaceholderColor = `placeholder:text-${inputPlaceholderCol}`
    let invalidMsgTextColor = `text-${invalidMsgTextCol}`
    let searchBtnTextColor = `text-${searchBtnTextCol}`
    let searchBtnSearchTimeBgColor = `bg-${searchBtnSearchTimeBgCol}`
    let searchBtnBgColor = `bg-${searchBtnBgCol}`

    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [hasError, setHasError] = useState(false);

    const handleChange = e => {
        setSearchText(e.target.value);
        setHasError(false);
    }

    const searchBreaches = (e) => {
        setIsSearching(true);
        e.preventDefault();

        api.post('/user/breaches', {
            requestData: searchText
        })
            .then(res => {
                if (res.status === 200) {
                    navigate(
                        '/check_for_breaches/results',
                        { state: { breaches: res.data.data } }
                    )

                }
            }).catch(err => {
                setHasError(true)
                setIsSearching(false);
                if (err && err.response && err.response.data.message) {
                    document.getElementById('error_response').innerText = err.response.data.message
                } else {
                    document.getElementById('error_response').innerText = defaultConnectionErrorMsg
                }
            })
    }

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    return (
        <div className='w-full'>
            <div className='w-full'>
                <InnerHeader hasBackBtn={true} hasHomeBtn={true} />
            </div>
            <div className='flex justify-center w-full'>
                <div className='w-full max-w-4xl space-y-10 pb-8'>
                    <form onSubmit={searchBreaches}>
                        <HeadingText title={breachesTitle} />
                        <div className={`w-full mt-7 text-sm ${introTextColor} font-normal font-SansPro leading-5 space-y-6`}>
                            {
                                Object.entries(breachesIntroduction).map((intro) => <p key={intro[0]}>{intro[1]}</p>)
                            }
                        </div>
                        <div className='w-full mt-8'>
                            <div className={`flex items-center space-x-2 border ${hasError ? invalidInputBorderColor : inputBorderColor} rounded-lg`}>
                                <div className='pl-3'>
                                    <img className='mt-1' src='/images/breaches_search.png' alt='search_breaches' />
                                </div>
                                <input type="text" name="breachSearch" value={searchText}
                                    onChange={handleChange}
                                    placeholder={breachesSearchInputPlaceHolder}
                                    autoComplete='off'
                                    onInvalid={e => e.target.setCustomValidity(breachesSearchInputCustValidationMsg)}
                                    onInput={e => e.target.setCustomValidity('')}
                                    required
                                    className={`w-full relative p-2 ${inputBgColor} ${inputTextColor} outline-none bg-transparent ${inputPlaceholderColor} placeholder:text-sm placeholder:font-SansPro placeholder:font-normal`} />
                            </div>
                            <div className='flex mt-1'>
                                <p className={`text-xs ${invalidMsgTextColor} ${hasError ? `block` : `hidden`}`} id="error_response"></p>
                            </div>

                        </div>
                        <div className='w-full flex justify-center mt-6'>
                            <button disabled={isSearching} type='submit' className={`flex justify-between items-center align-center text-sm font-semibold ${searchBtnTextColor} font-SansPro rounded-lg h-9 w-102px ${isSearching ? `${searchBtnSearchTimeBgColor} py-1 px-9` : `${searchBtnBgColor} py-2 px-7`}`}>
                                {isSearching ? <LoaderEllipse /> : breachesSearchBtnText}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}

export default CheckForBreaches