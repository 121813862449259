import React, { useContext } from 'react'
import CustomFormValidatorMsg from '../../common/cust-form-validator-msg';
import { DataContext } from '../../../contexts/dataContext';

const QuestionCard = (props) => {
    const context = useContext(DataContext);

    //site data
    const formPopupValidationMsg = context?.siteData?.common?.formPopupValidationMsg ?? ''

    //site styles
    const questionCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.questionCol ?? 'simple-gray'
    const answerCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.answerCol ?? 'dark-gray'
    const radioBtnStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.radioBtnStyle ?? 'custom-radio-btn-orange'
    const checkboxBtnStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.checkboxBtnStyle ?? 'custom-checkbox-orange'

    const questionColor = `text-${questionCol}`
    const answerColor = `text-${answerCol}`

    const { handleAnswers, answers, currentQuestion, currentAssessment, toggleValidatorMsg, answerType } = props;
    return (
        <div className='w-full space-y-4'>
            <h1 className={`text-base font-SansPro font-semibold ${questionColor}`}>{currentAssessment?.question ?? ""}</h1>
            <div className='space-y-3 relative font-SansPro'>
                {(currentAssessment?.options).map((option) => (
                    <div className='flex items-center space-x-3' key={option.id}>
                        {
                            answerType === 'checkbox' ?
                                <input type="checkbox" name={currentAssessment.id} id={option.id} onChange={() => handleAnswers(currentAssessment.id, option.id)} checked={answers?.[currentQuestion]?.answer.includes(option.id) ? true : false} className={checkboxBtnStyle} />
                                :
                                <input type="radio" name={currentAssessment.id} id={option.id} onChange={() => handleAnswers(currentAssessment.id, option.id)} checked={answers?.[currentQuestion]?.answer === option.id ? true : false} className={radioBtnStyle} />
                        }
                        <label htmlFor={option.id} className={`text-sm font-normal ${answerColor} cursor-pointer`}>{option.option}</label>
                    </div>
                ))}
                <CustomFormValidatorMsg
                    message={formPopupValidationMsg}
                    isToggle={toggleValidatorMsg}
                />
            </div>
        </div>
    )
}

export default QuestionCard