import React, { useContext, useEffect } from 'react'
import DynamicTitle from '../../components/common/dynamic-title'
import { InnerHeader } from '../../components/common/inner-header'
import ContentLlist from '../../components/cyber-training/content-list'
import { DataContext } from '../../contexts/dataContext'
import { Link } from 'react-router-dom'

const CyberTraining = () => {
  const context = useContext(DataContext);

  //site data
  let cyberSafetyDescriptionMain = context?.siteData?.cyberSafetyTraining?.cyberSafetyDescriptionMain ?? ''
  let cyberSafetyDescription = context?.siteData?.cyberSafetyTraining?.cyberSafetyDescription ?? ''
  let cyberSafetyTitle = context?.siteData?.cyberSafetyTraining?.cyberSafetyTitle ?? ''
  let teamReviewBtnText = context?.siteData?.common?.teamReviewBtnText ?? 'REVIEW TEAM ACTIVITY'

  //site style
  const cyberSafetyDescriptionCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetyDescriptionCol ?? 'simple-gray'
  const cyberSafetyDescriptionMainCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetyDescriptionMainCol ?? 'black'
  const reviewBtnStyle = context?.siteStyles?.cyberSafetyTraining?.reviewBtnStyle ?? 'orange-bg-btn'

  let cyberSafetyDescriptionColor = `text-${cyberSafetyDescriptionCol}`
  let cyberSafetyDescriptionMainColor = `text-${cyberSafetyDescriptionMainCol}`

  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <div className='w-full'>
      <div className='w-full'>
        <InnerHeader
          hasBackBtn={true}
          hasHomeBtn={true}
        />
        <DynamicTitle
          title={cyberSafetyTitle}
          hasBorder={true}
        />
      </div>
      <div className='w-full flex justify-center'>
        <div className='w-full max-w-4xl'>
          <div className='w-full space-y-10 py-8'>
            <div className={`px-2`}>
              <div className='w-full'>
                {
                  cyberSafetyDescriptionMain &&
                  <p className={`text-xl font-semibold pb-3 ${cyberSafetyDescriptionMainColor} font-SansPro sm:text-center`}>{cyberSafetyDescriptionMain} </p>
                }
                <p className={`text-sm font-normal ${cyberSafetyDescriptionColor} font-SansPro sm:text-center`}>{cyberSafetyDescription} </p>
              </div>
              <div className='pt-5'>
                <ContentLlist />
              </div>
              {
                context?.userDetails?.type === 'lead' &&
                <div className='w-full flex justify-center pt-2'>
                  <Link to={'/cyber_training/team-activity-review'} className={`${reviewBtnStyle} mt-3`}>
                    {teamReviewBtnText}
                  </Link>
                </div>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default CyberTraining