import React from 'react'
import { Link } from 'react-router-dom'

const DownloadSectionCard = ({ title, description, borderColor, appStoreLink, playStoreLink }) => {
    let borderCol = `border-${borderColor ?? `btn-orange`}`
    return (
        <div className={`w-full space-y-6 font-SansPro sm:px-12 px-6 py-8 lg:border-l-12 ${borderCol} shadow-cardShadow-md`}>
            <div className='w-full space-y-6'>
                <h1 className='text-5xl font-Publico font-extrabold'>{title}</h1>
                <p>{description}</p>
            </div>
            <div className='w-full space-y-8 py-4 flex flex-col items-center'>
                <div className='w-52 h-16 rounded-md'>
                    <Link to={appStoreLink}>
                        <img src="/images/staticPage/appStore.png" className='h-full' alt="appstore" />
                    </Link>
                </div>
                <div className='w-52 h-16 rounded-md'>
                    <Link to={playStoreLink}>
                        <img src="/images/staticPage/playStore.png" className='h-full' alt="playstore" />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default DownloadSectionCard