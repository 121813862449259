import React, { useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { authUtils } from '../../helpers/authHelper';
import { localStorageUtils } from '../../helpers/storageHelper';
import { DataContext } from '../../contexts/dataContext';

const SideNav = (props) => {
    const { isSideNav, toggleSideNav } = props;
    const navigate = useNavigate()
    const user = localStorageUtils.getLocalStoragItem('user');
    const logout = () => {
        toggleSideNav();
        authUtils.handleLogout();
        navigate('/')
    }

    const context = useContext(DataContext)

    //page data 
    let logoutBtnText = context?.siteData?.common?.logoutBtnText ?? ''
    let closeBtnText = context?.siteData?.common?.closeBtnText ?? ''

    //page style's
    const background = context?.siteStyles?.common?.sideNavbar?.background ?? 'shaded-white'
    const closeBtnCol = context?.siteStyles?.common?.sideNavbar?.closeBtnCol ?? 'header-bg-blue'
    const userNameCol = context?.siteStyles?.common?.sideNavbar?.userNameCol ?? 'header-bg-blue'
    const phoneNumberCol = context?.siteStyles?.common?.sideNavbar?.phoneNumberCol ?? 'header-bg-blue'
    const logoutCol = context?.siteStyles?.common?.sideNavbar?.logoutCol ?? 'header-bg-blue'


    let backgroundColor = `bg-${background}`
    let closeBtnColor = `text-${closeBtnCol}`
    let userNameColor = `text-${userNameCol}`
    let phoneNumberColor = `text-${phoneNumberCol}`
    let logoutColor = `text-${logoutCol}`
    
    return (
        <div className={`w-72 z-50 h-screen transform duration-500 font-SansPro shadow-cardShadow-md ${isSideNav ? `translate-x-0` : `translate-x-full`} pl-10 pr-5 space-y-10 fixed top-0 right-0 ${backgroundColor}`}>
            <div className='w-full flex items-center justify-end py-5'>
                <button onClick={() => toggleSideNav()} className='flex items-center space-x-1'>
                    <div className='flex items-center min-w-max'>
                        <img className='h-5 w-5' src="/images/x-circle-new.png" alt="close-btn" />
                    </div>
                    <p className={`text-sm font-normal ${closeBtnColor} `}>{closeBtnText}</p>
                </button>
            </div>
            <div className='w-full space-y-8'>
                <div className='w-full flex items-start'>
                    <div className='min-w-max'>
                        <img className='w-6 h-6 object-contain' src="/images/user-profile-icon.png" alt="profile img" />
                    </div>
                    <div className={`w-full ${ user && (user.firstName !== "" && user.phoneNumber !== "" ) ? `space-y-2 ` : `` } pl-3`}>
                        <h1 className={`text-sm font-normal ${userNameColor}`}>{user?.firstName ?? 'Profile details'}</h1>
                        <div className='w-full'>
                            <p className={`${phoneNumberColor} font-normal text-sm`}>{user?.phoneNumber ?? '(000)- 000-0000'}</p>
                        </div>
                    </div>
                </div>
                <button className='w-full flex items-center max-w-min' onClick={logout}>
                    <div className='w-8 h-8 flex items-center'>
                        <img src="/images/log-out.png" alt="logout" />
                    </div>
                    <div className='w-full pl-2.5'>
                        <h1 className={`text-sm font-normal ${logoutColor} min-w-max`}>{logoutBtnText}</h1>
                    </div>
                </button>
            </div>
        </div>
    )
}

export default SideNav