import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext';

const CyberSecurityReport = ({ handleContentChange }) => {

  const context = useContext(DataContext);

  //page data
  const cyberReport = context?.siteData?.digitalRiskAssessment?.howWeDetermineYourSecurityScorecardSection?.cyberReport ?? {}
  const sectionTitle = context?.siteData?.digitalRiskAssessment?.howWeDetermineYourSecurityScorecardSection?.sectionTitle ?? ""
  const sectionSubTitle = context?.siteData?.digitalRiskAssessment?.howWeDetermineYourSecurityScorecardSection?.sectionSubTitle ?? ""
  
  //page styles
  const sectionTitleCol = context?.siteStyles?.digitalRiskAssessment?.howWeDetermineYourSecurityScorecardSection?.sectionTitleCol ?? 'simple-gray'
  const contentTitleCol = context?.siteStyles?.digitalRiskAssessment?.howWeDetermineYourSecurityScorecardSection?.contentTitleCol ?? 'extra-dark-gray'
   const contentSubTitleCol = context?.siteStyles?.digitalRiskAssessment?.howWeDetermineYourSecurityScorecardSection?.contentSubTitleCol ?? 'medium-gray'

  let sectionTitleColor = `text-${sectionTitleCol}`
  let contentTitleColor = `text-${contentTitleCol}` 
  let contentSubTitleColor = `text-${contentSubTitleCol}`

  return (
    <div className='w-full py-7 space-y-10'>
      <div className='w-full'>
        <h1 className={`text-base font-Publico font-semibold ${sectionTitleColor} `}>{sectionTitle}</h1>
        <p className={`text-base font-SansPro my-4  ${contentSubTitleColor}`}>{sectionSubTitle}</p>
      </div>
      <div className='w-full space-y-6'>
        {Object.entries(cyberReport).map((content) => (
          <div className='w-full space-y-4' key={content[0]}>
            <div className='inline-flex items-center space-x-3'>
              <img src="/images/alert-octagon-red.png" alt="esclamation-icon" />
              <h1 className={`text-base font-Publico font-normal ${contentTitleColor}`}>{content[1].title}</h1>
            </div>
            <p className={`text-sm font-SansPro font-normal ${contentSubTitleColor}`}>{content[1].description}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CyberSecurityReport