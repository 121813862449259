import React, { useContext, useEffect } from 'react'
import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { DataContext } from '../../contexts/dataContext';

const OnlineRisks = () => {
  const context = useContext(DataContext);

  const data = context?.siteData?.cyberSafetyTraining?.moduleData?.onlineRisksData ?? {};
  
  useEffect(() => {
    window.scroll(0, 0);
  }, [])
  return (
    <div className='w-full'>
      <DynamicInnerPageWrapper
        data={data}
        moduleName="MANAGING_ONLINE_RISKS"
      />
    </div>
  )
}

export default OnlineRisks