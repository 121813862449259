import axios from 'axios';
import { localStorageUtils } from '../helpers/storageHelper';
import { refreshAccessToken } from './auth';
import { apiUrl, contentToken } from '../helpers/constants';

const api = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
})


api.interceptors.request.use(
    async (config) => {
        const accessToken = contentToken;
        if (accessToken) {
            config.headers.Authorization = accessToken;
        }
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
)

let isRefreshing = false;
let refreshSubscribers = [];

api.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                try {
                    const newAccessToken = await new Promise((resolve) => {
                        refreshSubscribers.push((accessToken) => {
                            resolve(accessToken);
                        })
                    });
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return api(originalRequest);
                } catch(error) {
                    console.log(error)
                    return Promise.reject(error);
                }
            } else {
                originalRequest._retry = true;
                isRefreshing = true;
                try {
                    const newAccessToken = localStorageUtils.getLocalStoragItem('token') && await refreshAccessToken();
                    isRefreshing = false;
                    onAccessTokenRefreshed(newAccessToken);
                    originalRequest.headers.Authorization = 'Bearer ' + newAccessToken;
                    return api(originalRequest);
                } catch(error) {
                    isRefreshing = false;
                    onAccessTokenRefresheshFailed(error);
                    return Promise.reject(error);
                }
            }
        }
        return Promise.reject(error);
    }
);


const onAccessTokenRefreshed = (accessToken) => {
    refreshSubscribers.forEach((callback) => callback(accessToken));
    refreshSubscribers = [];
}

const onAccessTokenRefresheshFailed = (error) => {
    refreshSubscribers.forEach((callback) => Promise.reject(error));
    refreshSubscribers = [];
}

export default api;