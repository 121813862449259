import React from 'react'

const Card = ({ title, description, image }) => {
    return (
        <div className='w-full px-6 pb-14 pt-4 shadow-cardShadow-md text-center rounded-md flex flex-col items-center'>
            <div className='w-52'>
                <img src={`/images/staticPage/${image}`} alt="card-icon"  className='w-full object-contain '/>
            </div>
            <h1 className='text-xl font-bold '>{title}</h1>
            <p className='pt-4'>{description}</p>
        </div>
    )
}

export default Card