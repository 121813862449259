import React, { useContext, useEffect, useState } from 'react'
import BoxxaActivities from '../components/boxx-page/boxx-activities';
import { InnerHeader } from '../components/common/inner-header';
import SideNav from '../components/navbar/sideNav';
import HeadingText from '../components/common/heading-text';

import { DataContext } from '../contexts/dataContext';

const Home = () => {
  const context = useContext(DataContext)

  //page data
  let homePageTitle = context?.siteData?.homePage?.homePageTitle ?? ''
  let homePageTitleDescription = context?.siteData?.homePage?.homePageTitleDescription ?? ''

  //page style's
  const homePageTitleCol = context?.siteStyles?.homePage?.homePageTitleCol ?? 'extra-dark-gray'
  const homePageTitleDescriptionMdCol = context?.siteStyles?.homePage?.homePageTitleDescriptionMdCol ?? 'simple-gray'
  const homePageDescriptionContainerBorder = context?.siteStyles?.homePage?.homePageDescriptionContainerBorder ?? 'extra-lite-gray'


  let homePageTitleColor = `text-${homePageTitleCol}`
  let homePageTitleDescriptionMdColor = `text-${homePageTitleDescriptionMdCol}`
  let homePageDescriptionContainerBorderColor = `border-${homePageDescriptionContainerBorder}`

  const [isSideNav, setIsSideNav] = useState(false)

  const toggleSideNav = () => {
    setIsSideNav(!isSideNav);
  }

  useEffect(() => {
    document.body.style.overflow = isSideNav ? "hidden" : "auto";
  }, [isSideNav])

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  return (
    <div className='w-full relative pb-10 px-0 lg:px-7'>
      <div className='block md:hidden w-full'>
        <InnerHeader hasBackBtn={false} />
      </div>
      <div className='block md:hidden'>
        <HeadingText title={homePageTitle} hasBorder={false} />
      </div>
      <div className='w-full md:flex hidden flex-col items-center md:space-y-10 md:py-14 md:mb-0'>
        <div className={`${homePageTitleColor} md:text-4xl text-2xl font-semibold font-Publico sm:text-center text-left`}>
          <h1 className='font-Publico'>{homePageTitle}</h1>
        </div>
        <div className={`md:text-xl.5 text-xl.5 leading-7 ${homePageTitleColor} font-SansPro sm:text-center text-left  `}>
          <p>
            {homePageTitleDescription}
          </p>
        </div>
      </div>
      <div className={`w-full md:hidden flex flex-col items-center font-Sans text-center space-y-5 pb-5 mb-10 border-b ${homePageDescriptionContainerBorderColor} text-sm ${homePageTitleDescriptionMdColor} font-normal `}>
        <p>{homePageTitleDescription}</p>
      </div>
      <BoxxaActivities />
      <SideNav
        isSideNav={isSideNav}
        toggleSideNav={toggleSideNav}
      />
    </div>
  )
}

export default Home