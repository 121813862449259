import React, { useContext } from 'react'
import { DataContext } from '../contexts/dataContext';

const Generator = ({ inputType, data, handleAnswers, answers, sectionId, questionId, answerTypeId, questionType, subSection, selectPlaceHolder }) => {

    const context = useContext(DataContext);

    const invalidInputErrText = context?.siteData?.common?.invalidInputErrText ?? ''

    const checkboxStyle = context?.siteStyles?.common?.inputs?.checkboxStyle ?? 'custom-checkbox-orange'
    const checkboxLabelCol = context?.siteStyles?.common?.inputs?.checkboxLabelCol ?? 'dark-gray'
    const radioBtnStyle = context?.siteStyles?.common?.inputs?.radioBtnStyle ?? 'custom-radio-btn-black'
    const radioBtnLabelCol = context?.siteStyles?.common?.inputs?.radioBtnLabelCol ?? 'dark-gray'
    const selectBorderCol = context?.siteStyles?.common?.inputs?.selectBorderCol ?? 'input-border'
    const selectTextCol = context?.siteStyles?.common?.inputs?.selectTextCol ?? 'dark-gray'
    const errTextCol = context?.siteStyles?.common?.inputs?.errTextCol ?? 'err-input-border'

    let checkboxLabelColor = `text-${checkboxLabelCol}`
    let radioBtnLabelColor = `text-${radioBtnLabelCol}`
    let selectBorderColor = `border-${selectBorderCol}`
    let selectTextColor = `text-${selectTextCol}`
    let errTextColor = `text-${errTextCol}`

    let uniqueId;
    switch (inputType) {
        case 'checkbox':
            return (
                <div className='w-full space-y-3'>
                    {data.map((option) => {
                        return (
                            <div className='w-full flex items-center space-x-2' key={option.id}>
                                <input
                                    type="checkbox"
                                    checked={questionType === 'MULTIPLE' ? answers[sectionId].question[subSection].answer.includes(option.id) ? true : false : answers[sectionId].answers[subSection].answer.includes(option.id) ? true : false}
                                    onChange={() => handleAnswers({
                                        "section": sectionId,
                                        "questionType": questionType,
                                        "subSection": subSection,
                                        "answerType": 'multi',
                                        "answer": option.id
                                    })}
                                    name={questionId}
                                    id={option.id}
                                    className={`${checkboxStyle} peer`}
                                />
                                <label htmlFor={option.id} className={`peer-disabled:cursor-default cursor-pointer text-sm font-normal font-SansPro ${checkboxLabelColor}`}>{option.name}</label>
                            </div>
                        )
                    })}
                </div>
            )
        case 'radio':
            return (
                <div className='w-full space-y-3'>
                    {data.map((option) => {
                        return (
                            <div className='w-full flex items-center space-x-2' key={`${subSection}-${option.id}`}>
                                <input
                                    type="radio"
                                    checked={questionType === 'SINGLE' ? answers[sectionId].answers[subSection].answer === option.id ? true : false : answers[sectionId].question[subSection].answer === option.id ? true : false}
                                    onChange={() => handleAnswers({
                                        "section": sectionId,
                                        "questionType": questionType,
                                        "subSection": subSection,
                                        "answerType": 'single',
                                        "answer": option.id
                                    })}
                                    name={`${subSection}`}
                                    id={`${subSection}-${option.id}`}
                                    className={`${radioBtnStyle} peer`}
                                />
                                <label htmlFor={`${subSection}-${option.id}`} className={`peer-disabled:cursor-default cursor-pointer text-sm font-normal font-SansPro ${radioBtnLabelColor}`}>{option.name}</label>
                            </div>
                        )
                    })}
                </div>
            )
        case 'select':
            return (
                <div className='w-full max-w-sm'>
                    <select
                        value={questionType === 'MULTIPLE' ? answers[sectionId].question[subSection].answer ?? '' : answers[sectionId].answers[subSection].answer ?? '' }
                        onChange={(e) => handleAnswers({
                            "section": sectionId,
                            "questionType": questionType,
                            "subSection": subSection,
                            "answerType": 'single',
                            "answer": e.target.value
                        })}
                        className={`w-full border ${selectBorderColor} rounded-lg p-2 cursor-pointer text-sm font-normal font-SansPro ${selectTextColor} outline-none`
                        }>
                        <option value="">------ {selectPlaceHolder || 'select'} ------</option>
                        {data.map((option) => {
                            uniqueId = `${sectionId}-${questionId}-${answerTypeId}-${option.id}`;
                            return (
                                <option value={option.id} key={uniqueId}>{option.name}</option>
                            )
                        })}
                    </select>
                </div>
            )
        case 'table':
            return (
                <table className='w-full table-auto text-sm'>
                    <thead className='w-full border-b-2 border-black'>
                        <tr className=''>
                            <td className='py-3 px-6'>#</td>
                            {
                                data.tableHeaders &&
                                (data.tableHeaders).map((header) => (
                                    <td className='px-6' key={header.id}><p className='min-w-max'>{header.label}</p></td>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody className='w-full'>
                        {
                            data.tableData && data.tableHeaders &&
                            (data.tableData).map((user, key) => (
                                <tr className='even:bg-gray-100' key={user.id}>
                                    <td className='py-3 px-6'>{key + 1}</td>
                                    {
                                        (data.tableHeaders).map((header) => (
                                            <td className='px-6' key={`${user.id}-${header.id}`}><p className='min-w-max'>{user[header.name]}</p></td>
                                        ))
                                    }
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )

        default: return <h1 className={`${errTextColor}`}>{invalidInputErrText}</h1>
    }
}

export default Generator