import React from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const MarkdownWrapper = ({ dataset }) => {

    const openExternalLinksInNewTab = ({ href, children }) => {
        if (href && !href.startsWith('#')) {
            return (
                <a href={href} target="_blank" rel="noopener noreferrer">
                    {children}
                </a>
            );
        }
        return <a href={href}>{children}</a>;

    };
    return (
        <Markdown remarkPlugins={[remarkGfm]} components={{ a: openExternalLinksInNewTab }}>{dataset}</Markdown>
    )
}

export default MarkdownWrapper