import React from 'react'

const LoaderWrapper = ({children}) => {
  return (
    <div className='w-full h-screen bg-black bg-opacity-30 flex items-center justify-center fixed top-0 left-0'>
        {children}
    </div>
  )
}

export default LoaderWrapper