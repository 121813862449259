import React, { useContext } from 'react'
import { Outlet } from "react-router-dom";
import Footer from '../common/footer';
import Header from '../common/header';
import { DataContext } from '../../contexts/dataContext';
import LoaderWrapper from '../loaders/loader-wrapper';
import CircleLoader from '../loaders/circle-loader';
import CookieConsentComponent from '../cookieConsent/CookieConsentComponent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

const Layout = () => {
  const context = useContext(DataContext)
  const appBackground = context?.siteStyles?.common?.appBackground ?? 'app-bg'
  let bg = `bg-${appBackground}`

  const isLoading = context.isLoading

  if (isLoading) {
    return (
      <LoaderWrapper>
        <CircleLoader />
      </LoaderWrapper>
    )
  } else {
    return (
      <div className={`flex flex-col w-full ${bg} min-h-screen relative overflow-y-auto`}>
        <Header />
        <div className={`w-full px-6 flex-grow`}>
          <Outlet />
        </div>
        <Footer />
        <CookieConsentComponent/>
      </div>
    )
  }

}

export default Layout