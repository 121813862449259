import React, { useContext } from 'react'
import AssessmentHead from './assessment-head'
import AssessmentWrapper from './assessment-wrapper'
import { DataContext } from '../../contexts/dataContext'
import QuestionCard from './question-card'

const Assessment = (props) => {
  const context = useContext(DataContext);

  //page data
  const draNextBtnText = context?.siteData?.digitalRiskAssessment?.draNextBtnText ?? ''
  const draPreviousBtnText = context?.siteData?.digitalRiskAssessment?.draPreviousBtnText ?? ''

  //page style
  const draPreviousBtnStyle = context?.siteStyles?.digitalRiskAssessment?.assessment?.draPreviousBtnStyle ?? 'orange-border-btn'
  const draNextBtnStyle = context?.siteStyles?.digitalRiskAssessment?.assessment?.draNextBtnStyle ?? 'orange-bg-btn'
  const questionTitleTextCol = context?.siteStyles?.digitalRiskAssessment?.assessment?.questionTitleTextCol ?? 'extra-dark-gray'

  let questionTitleTextColor = `text-${questionTitleTextCol}`

  const {
    currentSection,
    totalAnsweredQuestions,
    totalQuestions,
    assessments,
    answers,
    sectionDetails,
    handleAnswers,
    handleQuestions,
    toggleValidatorMsg,
  } = props
  return (
    <div className='w-full space-y-9'>
      <div className='w-full pt-7'>
        <AssessmentHead
          currentQuestion={currentSection}
          totalAnsweredQuestions={totalAnsweredQuestions}
          totalQuestions={totalQuestions}
        />
      </div>
      <div className='w-full py-8 space-y-10'>
        {
          assessments?.[currentSection]?.question &&
            typeof (assessments[currentSection].question) === 'object' ?
            <AssessmentWrapper
              sectionId={currentSection}
              questions={assessments[currentSection].question}
              questTitle={assessments[currentSection]?.title ?? ''}
              handleAnswers={handleAnswers}
              answers={answers}
              sectionDetails={sectionDetails}
              toggleValidatorMsg={toggleValidatorMsg}
              questionType={assessments[currentSection]?.type ?? ''}
            />
            :
            <div className='w-full space-y-3'>
              <h1 className={`text-base font-Publico font-semibold ${questionTitleTextColor}`}>{assessments?.[currentSection]?.title ?? ''}</h1>
              <div className='w-full space-y-16'>
                {
                  <QuestionCard key={`${currentSection}`}
                    handleAnswers={handleAnswers}
                    answers={answers}
                    sectionDetails={sectionDetails}
                    currentQuestion={currentSection}
                    sectionId={currentSection}
                    currentAssessment={assessments[currentSection]}
                    toggleValidatorMsg={toggleValidatorMsg}
                    questionType={assessments[currentSection]?.type ?? ''}
                  />
                }
              </div>
            </div>
        }
        <div className={`w-full pt-8 flex justify-end ${currentSection !== 0 && `space-x-4`} `}>
          {currentSection !== 0 && <button onClick={() => handleQuestions('previous')} className={draPreviousBtnStyle}>{draPreviousBtnText}</button>}
          <button disabled={totalQuestions === 0 ? true : false} onClick={() => handleQuestions('next')} className={draNextBtnStyle}>{draNextBtnText}</button>
        </div>
      </div>
    </div>
  )
}

export default Assessment