import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext'

function SimpleModal({ handleClose }) {
  const context = useContext(DataContext);

  //page data
  const breachContents = context?.siteData?.checkForBreaches?.breachesResultsPage?.breachContents ?? {}
  const breachPopupCloseBtnText = context?.siteData?.checkForBreaches?.breachesResultsPage?.breachPopupCloseBtnText ?? ''
  const breachPopupTitle = context?.siteData?.checkForBreaches?.breachesResultsPage?.breachPopupTitle ?? ''

  //page styles
  const transparentBgCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.popupModel?.transparentBgCol ?? 'header-bg-blue'
  const popupBgCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.popupModel?.popupBgCol ?? 'app-bg'
  const headerTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.popupModel?.headerTextCol ?? 'extra-dark-gray'
  const subHeadTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.popupModel?.subHeadTextCol ?? 'extra-dark-gray'
  const descriptionTextCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.popupModel?.descriptionTextCol ?? 'medium-gray'
  const backBtnCol = context?.siteStyles?.checkForBreaches?.breachesResultsPage?.popupModel?.backBtnCol ?? 'header-bg-blue'

  let transparentBgColor = `bg-${transparentBgCol}`
  let popupBgColor = `bg-${popupBgCol}`
  let headerTextColor = `text-${headerTextCol}`
  let subHeadTextColor = `text-${subHeadTextCol}`
  let descriptionTextColor = `text-${descriptionTextCol}`
  let backBtnColor = `text-${backBtnCol}`

  return (
    <div id="top-right-modal" data-modal-placement="top-right" tabIndex="-1" className={`fixed top-0 left-0 w-full h-screen ${transparentBgColor} bg-opacity-60 px-8 py-28 overflow-y-auto flex justify-center md:items-center`}>
      <div className={`w-full max-w-4xl overflow-y-auto h-min ${popupBgColor} py-7 px-5 space-y-8 shadow-[0px_25px_50px_-12px_rgba(39,39,54,0.25)]`}>
        <h1 className={`text-xl.5 font-semibold ${headerTextColor} leading-6 font-Publico`}>{breachPopupTitle}</h1>
        {Object.entries(breachContents).map((breach) => (
          <div className='w-full space-y-2' key={breach[0]}>
            <h1 className={`text-lg font-Publico font-semibold leading-5 ${subHeadTextColor}`}>{breach[1].title}</h1>
            <p className={`text-sm font-SansPro font-normal leading-4 ${descriptionTextColor}`} dangerouslySetInnerHTML={{ __html: breach[1].description }} />
          </div>
        ))}
        <div className=''>
          <button className={`${backBtnColor} text-sm font-SansPro font-medium`} onClick={handleClose}>
            <div className='flex items-center space-x-2'>
              <img src="/images/chevron-left.png" alt="uparrow" />
              <span className=''>{breachPopupCloseBtnText}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default SimpleModal