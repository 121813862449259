import React, { useContext, useEffect, useMemo, useState } from 'react'
import AssessmentFinal from './assessment-final';
import AssessmentHead from './assessment-head';
import AssessmentResult from './assessment-result';
import QuestionCard from './question-card';
import { DataContext } from '../../../contexts/dataContext';
import axios from 'axios';
import { apiUrl, header } from '../../../helpers/constants';
import { localStorageUtils } from '../../../helpers/storageHelper';
import LoaderEllipse from '../../common/loader-ellipse'

const Assessment = (props) => {


    const context = useContext(DataContext);

    //page data
    const cyberSafetyAssessmentNextBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentNextBtnText ?? ''
    const cyberSafetyAssessmentPrevBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentPrevBtnText ?? ''
    const cyberSafetyAssessmentSubmitBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentSubmitBtnText ?? ''
    const passMsgText = context?.siteData?.cyberSafetyTraining?.passMsg ?? ''
    const failedMsgText = context?.siteData?.cyberSafetyTraining?.failedMsg ?? ''
    const noQuestionsmsg = context?.siteData?.cyberSafetyTraining?.noQuestionsmsg ?? ''
    const assessmentResultDescription = context?.siteData?.cyberSafetyTraining?.assessmentResultDescription ?? []
    const cyberSafetyAssessmentMailMandatory = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentMailMandatory ?? false
    let showEmail = cyberSafetyAssessmentMailMandatory;
    //page styles
    const prevBtnTextStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.prevBtnTextStyle ?? 'orange-border-btn'
    const nextBtnTextStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.nextBtnTextStyle ?? 'orange-bg-btn'
    const submitBtnStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.submitBtnStyle ?? 'orange-bg-btn'

    const { questions, passLimit } = props;

    let allQuestions = useMemo(() => questions?.questions ?? [], [questions])
    let answerType = useMemo(() => questions?.answerType ?? 'radio', [questions])
    let isResultToMail = useMemo(() => questions?.isResultToMail ?? false, [questions])

    const [isResult, setIsResult] = useState(false);
    const [isResultVerified, setIsResultVerified] = useState(false);
    const [isResultApiFailed, setIsResultApiFailed] = useState(false);
    const [isQusetionsActive, setIsQuestionsActive] = useState(true);
    const [isPass, setIsPass] = useState(false);
    const [toggleValidatorMsg, setToggleValidatorMsg] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [currentQuestionId, setCurrentQuestionId] = useState(allQuestions?.[currentQuestion]?.id ?? null);
    const [totalCorrectAnswers, setTotalCorrectAnswers] = useState(0);
    const [totalAnsweredQuestions, setTotalAnsweredQuestions] = useState(0);
    const [assessmentMailId, setAssessmentMailId] = useState('');
    const [assessmentResultMailPreview, setAssessmentResultMailPreview] = useState(null);
    const [mailsendingstatus, setMailSendingStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [payloadData, setPayloadData] = useState({
        userId: localStorageUtils.getLocalStoragItem('userId'),
        emailId: "",
        questionId: null,
        answers: []
    })

    const [answers, setAnswers] = useState([]);

    const passMsg = passMsgText;
    const failedMsg = `${passLimit} ${failedMsgText}`;

    const totalQuestions = allQuestions.length;

    const handleAnswers = (questId, ansId) => {
        let tempAnswers = [...answers]
        let ansItem = {
            id: "",
            answer: ""
        }
        let foundIndex = tempAnswers.findIndex(ans => ans.id === questId);

        /**
         * handling the answers accoring to the answer type
         * if answerType is "checkbox" then the type of the answer inside the answers state will be an array.
         * if answerType is "radio button" then the type of the answer inside the answers state will be an number ( it consists only one value ).
         */

        if (answerType === "checkbox") {
            if (foundIndex >= 0) {
                let cpyAnswers = tempAnswers[foundIndex].answer
                if (cpyAnswers.length > 0) {
                    if (cpyAnswers.includes(ansId)) {
                        cpyAnswers.splice(cpyAnswers.indexOf(ansId), 1)
                        ansItem = {
                            id: questId,
                            answer: cpyAnswers
                        }

                    } else {
                        cpyAnswers.push(ansId)
                        ansItem = {
                            id: questId,
                            answer: cpyAnswers
                        }
                    }
                } else {
                    ansItem = {
                        id: questId,
                        answer: [ansId]
                    }
                }

            } else {
                ansItem = {
                    id: questId,
                    answer: [ansId]
                }
            }
        } else {
            ansItem = {
                id: questId,
                answer: ansId
            }
        }

        /**
         * foundIndex === -1 then the question is not answered
         * If question is already answered then replace the current answer with new one otherwise push the new answer
         */
        if (foundIndex >= 0) {
            tempAnswers[foundIndex] = ansItem;
        } else {
            tempAnswers.push(ansItem)
        }
        let tempTotAnsweredQuest = 0;

        // eslint-disable-next-line
        tempAnswers.map((ans) => {
            if (typeof (ans.answer) === 'object') {
                tempTotAnsweredQuest += ans.answer.length > 0 ? 1 : 0
            } else {
                tempTotAnsweredQuest += ans.answer !== '' ? 1 : 0
            }
        })

        setTotalAnsweredQuestions(tempTotAnsweredQuest)
        setAnswers(tempAnswers)
    }

    /**
     * 
     * @param operaton indicates next or preview
     */
    const handleQuestions = (operaton) => {
        let isQuestAnswered
        if (answerType === "checkbox") {
            let currentQuestIndex = currentQuestionId === null ? -1 : answers.length === 0 ? -1 : answers.findIndex(ans => ans.id === currentQuestionId)
            isQuestAnswered = currentQuestIndex === -1 ? false : answers[currentQuestIndex].answer.length === 0 ? false : true
        } else {
            isQuestAnswered = currentQuestionId === null ? false : answers.length === 0 ? false : answers.some(ans => ans.id === currentQuestionId)
        }

        if (isQuestAnswered || operaton === 'previous') {
            if (operaton === 'previous' && currentQuestion !== 0) {
                setCurrentQuestion((prevState) => prevState - 1)
            } else if (operaton === 'next') {
                if (currentQuestion !== (allQuestions.length - 1)) {
                    setCurrentQuestion((prevState) => prevState + 1)
                } else {
                    setIsQuestionsActive(false)
                }
            }
        } else {
            setToggleValidatorMsg(true)
            let toggleTimer = setTimeout(() => {
                setToggleValidatorMsg(false)
                clearTimeout(toggleTimer)
            }, 2000);
        }
    }

    const handleResult = (event, questId) => {
        event.preventDefault();
        if (isResultToMail) {
            if (!isValidEmail(assessmentMailId)) {
                setIsLoading(false);
                setIsInvalidEmail(true);
                return;
            } else {
                setIsInvalidEmail(false);
            }
        }
        setIsResult(true)

        setPayloadData(prevState => ({
            ...prevState,
            emailId: assessmentMailId,
            questionId: questId,
            answers: answers
        }))
    }

    const handleAssessmentMailId = (mailId) => {
        setAssessmentMailId(mailId)
        if (isInvalidEmail && isValidEmail(mailId)) {
            setIsInvalidEmail(false);
        }
    }

    const handleRestartAssessment = () => {
        setIsResult(false);
        setIsQuestionsActive(true);
        setAnswers([]);
        setTotalCorrectAnswers(0);
        setTotalAnsweredQuestions(0);
        setIsPass(false);
        setIsResultVerified(false);
        setIsResultApiFailed(false);
        setCurrentQuestion(0);
    }
    useEffect(() => {
        setCurrentQuestionId(allQuestions?.[currentQuestion]?.id ?? null)
    }, [currentQuestion, allQuestions])
    const isValidEmail = (email) => {
        // Regular expression to validate the email
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    }
    useEffect(() => {
        if (isResult) {
            setMailSendingStatus(undefined);
            setIsLoading(true);
            //Api call for the results
            header["Content-type"] = "application/json"
            axios.post(`${apiUrl}/auth/user/answer`, payloadData, { headers: header }).then((res) => {
                if (res.status === 200) {
                    console.log(res)
                    const { data } = res.data
                    console.log(res.data)
                    setIsLoading(false);
                    setIsResultVerified(true)
                    setIsResultApiFailed(false)
                    setIsPass(data.score >= data.passLimit ? true : false)
                    setTotalCorrectAnswers(data.score)
                    setMailSendingStatus(res.data?.error)
                    setAssessmentResultMailPreview(data.html)
                }
            })
                .catch(err => {
                    console.log(err)
                    setIsResultApiFailed(true)
                    setIsLoading(false)
                })
        }
    }, [isResult, payloadData])

    const assessmentResultDescriptionText = `${assessmentResultDescription[0] ?? ''} ${totalCorrectAnswers}/${totalQuestions} ${assessmentResultDescription[1] ?? ''}`
    let isQuestionAnswered = currentQuestionId === null ? false : answers.length === 0 ? false : answers.some(ans => ans.id === currentQuestionId)

    return (
        <div className='w-full'>
            {!isResult ?
                <div className='w-full'>
                    <AssessmentHead
                        currentQuestion={currentQuestion + 1}
                        totalAnsweredQuestions={totalAnsweredQuestions}
                        totalQuestions={totalQuestions}
                    />
                    {(allQuestions).length > 0 ?

                        isQusetionsActive ?
                            <div className='w-full py-8 space-y-5'>
                                <QuestionCard
                                    handleAnswers={handleAnswers}
                                    answers={answers}
                                    currentQuestion={currentQuestion}
                                    currentAssessment={allQuestions[currentQuestion]}
                                    toggleValidatorMsg={toggleValidatorMsg}
                                    answerType={answerType}
                                />
                                <div className={`w-full pt-8 flex justify-end ${currentQuestion !== 0 && `space-x-4`} `}>
                                    {currentQuestion !== 0 && <button onClick={() => handleQuestions('previous')} className={prevBtnTextStyle}>{cyberSafetyAssessmentPrevBtnText}</button>}
                                    <button onClick={() => handleQuestions('next')} className={nextBtnTextStyle}>{cyberSafetyAssessmentNextBtnText}</button>
                                </div>
                            </div> :
                            <form onSubmit={(e) => handleResult(e, questions.id)} className='w-full'>
                                <div className={`w-full ${isResultToMail ? `pt-12 pb-24` : `pt-28 pb-32`}`}>
                                    <AssessmentFinal
                                        isResultToMail={isResultToMail}
                                        assessmentMailId={assessmentMailId}
                                        handleAssessmentMailId={handleAssessmentMailId}
                                        isInvalidEmail={isInvalidEmail}
                                        showEmail={showEmail}
                                    />
                                </div>
                                <div className='w-full flex justify-end space-x-4'>
                                    <button onClick={() => setIsQuestionsActive(true)} disabled={currentQuestion === 0 ? true : false} className={prevBtnTextStyle}>{cyberSafetyAssessmentPrevBtnText}</button>
                                    <button type='submit' disabled={isQuestionAnswered ? false : true} className={`${submitBtnStyle} ${isLoading && `w-44`}`}>{isLoading ? <LoaderEllipse /> : cyberSafetyAssessmentSubmitBtnText}</button>
                                </div>
                            </form>
                        :
                        <div className={`w-full flex items-center justify-center h-44 text-simple-gray text-sm`}>
                            <h1>{noQuestionsmsg}</h1>
                        </div>}
                </div>
                :
                <AssessmentResult
                    isResultToMail={isResultToMail}
                    assessmentResultMailPreview={assessmentResultMailPreview}
                    isResultVerified={isResultVerified}
                    isResultApiFailed={isResultApiFailed}
                    isPass={isPass}
                    resultMsg={isPass ? passMsg : failedMsg}
                    description={assessmentResultDescriptionText}
                    handleRestartAssessment={handleRestartAssessment}
                    mailsendingstatus={mailsendingstatus}
                />
            }
        </div>
    )
}

export default Assessment