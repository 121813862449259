import React, { useContext, useState, useEffect } from 'react'
import AssessmentHead from './assessment-head'
import AssessmentFinal from '../cyber-training/inner-page-components/assessment-final'
import { DataContext } from '../../contexts/dataContext'
import LoaderEllipse from '../common/loader-ellipse'

const AssessmentResultWrapper = ({ totalAnsweredQuestions, totalQuestions, handleResultCall, assessmentMailId, handleAssessmentMailId, handleContentChange, isLoading, apiValidationMessage, isResultApiFailed, handleHideApiPopup, isInvalidEmail }) => {

    const [showPopup, setShowPopup] = useState(isResultApiFailed);

    const handleHidePopup = () => {
        setShowPopup(false);
        handleHideApiPopup();
    };

    useEffect(() => {
        document.body.style.overflow = showPopup ? "hidden" : "auto";
    }, [showPopup])

    const context = useContext(DataContext)
    //page data
    const cyberSafetyAssessmentPrevBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentPrevBtnText ?? ''
    const cyberSafetyAssessmentSubmitBtnText = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentSubmitBtnText ?? ''
    // const DigitalRiskAssessmentMailMandatory = context?.siteData?.cyberSafetyTraining?.DigitalRiskAssessmentMailMandatory ?? true
    let showEmail = false; 
    //page styles
    const prevBtnTextStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.prevBtnTextStyle ?? 'orange-border-btn'
    const submitBtnStyle = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.submitBtnStyle ?? 'orange-bg-btn'

    const apiFailedBgOverlay = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailFailedBgOverlay ?? 'simple-gray'
    const apiFailedBgAlert = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailFailedBgAlert ?? 'white'
    const apiFailedBtnStyle = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailFailedBtnStyle ?? 'orange-border-btn'

    let apiResultFailedBgOverlay = `bg-${apiFailedBgOverlay}`
    let apiResultFailedBgAlert = `bg-${apiFailedBgAlert}`

    return (
        <div className='w-full space-y-9'>
            <div className='w-full pt-7'>
                <AssessmentHead
                    totalAnsweredQuestions={totalAnsweredQuestions}
                    totalQuestions={totalQuestions}
                />
            </div>
            <form onSubmit={(e) => handleResultCall(e)} className='w-full'>
                <div className={`w-full pt-12 pb-24`}>
                    <AssessmentFinal
                        assessmentMailId={assessmentMailId}
                        handleAssessmentMailId={handleAssessmentMailId}
                        isInvalidEmail={isInvalidEmail}
                        showEmail={showEmail}
                    />
                </div>
                <div className='w-full flex justify-end space-x-4 pb-8'>
                    <button onClick={() => { handleContentChange('assessment', 'back') }} className={prevBtnTextStyle}>{cyberSafetyAssessmentPrevBtnText}</button>
                    <button type='submit' className={`${submitBtnStyle} flex items-center justify-center ${isLoading && `w-44`}`}>{isLoading ? <LoaderEllipse /> : cyberSafetyAssessmentSubmitBtnText}</button>
                </div>
            </form>
            {isResultApiFailed && (
                <div className={`fixed inset-0 flex items-center justify-center z-50 ${apiResultFailedBgOverlay} bg-opacity-50`}>
                    <div className={`${apiResultFailedBgAlert} rounded-lg shadow-lg p-4 w-300 m-5`}>

                        <p>{apiValidationMessage}</p>
                        <div className="mt-4 flex justify-center">
                            <button className={apiFailedBtnStyle}
                                onClick={handleHidePopup} >Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AssessmentResultWrapper