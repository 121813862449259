import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext'

const DynamicTitle = ({ title, hasBorder }) => {
  const context = useContext(DataContext);

  const borderCol = context?.siteStyles?.common?.dynamicTitle?.borderCol ?? 'extra-lite-gray'
  const textCol = context?.siteStyles?.common?.dynamicTitle?.textCol ?? 'extra-dark-gray'

  let borderColor = `border-${borderCol}`
  let textColor = `text-${textCol}`

  return (
    <div className={`w-full flex justify-center text-center pb-3.5 ${hasBorder && `border-b ${borderColor}`}`}>
      <h1 className={`font-bold font-Publico text-xl max-w-350px font-Publico ${textColor}`}>{title}</h1>
    </div>
  )
}

export default DynamicTitle