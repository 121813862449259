import { Link } from "react-router-dom"
import Header from "../components/profilePage/header"
import MenuList from "../components/profilePage/menu-list"
import SubscriptionDetails from "../components/profilePage/subscription-details"

const UserProfile = () => {
  const userName = 'Manudeep'

  return (
    <div className='w-full text-white space-y-10 pt-5'>
      <Header />
      <div className="px-2.5 text-3xl font-semibold text-slate-700">
        <h1>{`Hi ${userName}!`}</h1>
      </div>
      <div className="w-full space-y-7">
        <div className="w-full space-y-4">
          <SubscriptionDetails />
          <MenuList />
        </div>
        <div className="w-full space-y-5">
          <div className="w-full flex items-center justify-between bg-strong-gray rounded-lg px-4 py-2.5">
            <div className="w-24 h-9 relative flex items-center justify-center">
              <img src="/images/light/clientLogo.png" alt="logo" />
            </div>
            <p className="text-sm font-semibold">My Cyber Coverage</p>
            <Link to={'/home'}>
              <div className="rounded-lg bg-white p-2.5 font-semibold text-sm text-purple-col text-slate-700">
                Learn more
              </div>
            </Link>
          </div>
          <div className="w-full flex items-center justify-between bg-strong-gray rounded-lg px-4 py-2.5">
            <div className="w-24 h-9 relative flex items-center justify-center">
              <img src="/images/light/clientLogo.png" alt="logo" />
            </div>
            <p className="text-sm font-semibold">My Cyber Coverage</p>
            <Link to={'/home'}>
              <div className="rounded-lg bg-white p-2.5 font-semibold text-sm text-purple-col text-slate-700">
                Learn more
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile