import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { DataContext } from '../../contexts/dataContext'
import api from '../../api/axiosConfig';
import { localStorageUtils } from '../../helpers/storageHelper';
import LoaderEllipse from '../common/loader-ellipse';
import ReCAPTCHA from "react-google-recaptcha"
import { recaptchaSiteKey } from '../../helpers/constants';

const Login = ({ toggleForm, toggleForgotPasswordForm }) => {
    const context = useContext(DataContext);

    //page data
    let formData = context.siteData?.axaSpecific?.axaLogin?.formData ?? []
    let title = context.siteData?.axaSpecific?.axaLogin?.title ?? ""
    let description = context.siteData?.axaSpecific?.axaLogin?.description ?? ""
    let loginBtnText = context.siteData?.axaSpecific?.axaCommon?.loginBtnText ?? ""
    let accessCodeInfo = context.siteData?.axaSpecific?.axaCommon?.accessCodeInfo ?? ""
    let existingUserSignUpBtnText = context.siteData?.axaSpecific?.axaCommon?.existingUserSignUpBtnText ?? ""
    let forgotUserBtnText = context.siteData?.axaSpecific?.axaCommon?.forgotUserBtnText ?? ""
    let signUpLeadingText = context.siteData?.axaSpecific?.axaLogin?.signUpLeadingText ?? ""

    //site style
    let formWrapperBg = context.siteStyles?.axaSpecific?.formWrapperBg ?? 'cyan-blue'
    let formBg = context.siteStyles?.axaSpecific?.formBg ?? 'white'
    let inputBg = context.siteStyles?.axaSpecific?.inputBg ?? 'input-bg'
    let inputHoverBg = context.siteStyles?.axaSpecific?.inputHoverBg ?? 'input-hover-bg'
    let invalidInputBgCol = context.siteStyles?.axaSpecific?.invalidInputBgCol ?? 'extra-lite-red'
    let loginSignUpBtnCol = context.siteStyles?.axaSpecific?.loginSignUpBtnCol ?? 'btn-blue'
    let loginSignUpBtnHoverCol = context.siteStyles?.axaSpecific?.loginSignUpBtnHoverCol ?? 'dark-blue'
    let formTitleCol = context.siteStyles?.axaSpecific?.formTitleCol ?? 'extra-dark-gray'
    let formTitleDescriptionCol = context.siteStyles?.axaSpecific?.formTitleDescriptionCol ?? 'dark-gray'
    let loginSignUpBtnTextCol = context.siteStyles?.axaSpecific?.loginSignUpBtnTextCol ?? 'white'
    let infoTextCol = context.siteStyles?.axaSpecific?.infoTextCol ?? 'dark-gray'
    let placeholderCol = context.siteStyles?.axaSpecific?.placeholderCol ?? 'extra-dark-gray'
    let inputTextCol = context.siteStyles?.axaSpecific?.inputTextCol ?? 'extra-dark-gray'

    let formWrapperBackground = `bg-${formWrapperBg}`
    let formBackground = `bg-${formBg}`
    let formTitleColor = `text-${formTitleCol}`
    let formTitleDescriptionColor = `text-${formTitleDescriptionCol}`
    let inputBackground = `bg-${inputBg}`
    let inputHoverBackground = `hover:bg-${inputHoverBg}`
    let invalidInputBgColor = `invalid:bg-${invalidInputBgCol}`
    let loginSignUpBtnColor = `bg-${loginSignUpBtnCol}`
    let loginSignUpBtnHoverColor = `bg-${loginSignUpBtnHoverCol}`
    let loginSignUpBtnTextColor = `text-${loginSignUpBtnTextCol}`
    let infoTextColor = `text-${infoTextCol}`
    let placeholderColor = `placeholder:text-${placeholderCol}`
    let inputTextColor = `text-${inputTextCol}`

    const inputErrorBorderCol = context?.siteStyles?.common?.inputs?.errTextCol ?? 'err-input-border'
    const inputBorderCol = context?.siteStyles?.common?.inputs?.selectBorderCol ?? 'input-border'
    const inputErrorBorderColor = `border-${inputErrorBorderCol}`
    const invalidInputBorderColor = `invalid:border-b-${inputErrorBorderCol}`
    const inputBorderColor = `border-${inputBorderCol}`
    const validationErrTextCol = context?.siteStyles?.common?.inputs?.validationErrTextCol ?? 'err-text'
    const defaultConnectionErrorMsg = context?.siteData?.common?.defaultConnectionErrorMsg ?? ''
    const validationErrTextColor = `text-${validationErrTextCol}`

    const [userCridentials, setUserCridentials] = useState({
        email: null,
        password: null
    })
    const [isValid, setIsValid] = useState(true)
    const [invalidList, setInvalidList] = useState([])
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const language = localStorageUtils.getLocalStoragItem('lang') ?? "EN"
    const recaptchaRef = React.createRef();
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [isRecaptchaValid, setIsRecaptchaValid] = useState(true);



    const handleInputs = (form) => {
        //Only default form validatons are performing
        let tempInvalidList = [...invalidList];
        document.getElementById('validation_error').innerText = ''
        setUserCridentials((prevState) => ({
            ...prevState,
            [form.target.name]: form.target.value
        }))
        setIsValid(true)
        if (invalidList.includes(form.target.name)) {
            tempInvalidList.splice(tempInvalidList.indexOf(form.target.name), 1)
            setInvalidList(tempInvalidList)
        }
    }

    const handleLogin = (form) => {
        form.preventDefault();
        let userInfo = {};
        setIsLoading(true);
        let token = '';
        if (showRecaptcha) {
            token = recaptchaRef.current.getValue();
            if (!token) {
                setIsLoading(false);
                setIsRecaptchaValid(false);
                return
            }
        }
        setIsRecaptchaValid(true);

        api.post('/auth/user/login', { ...userCridentials, token }, { headers: { 'Accept-Language': language } }).then(res => {
            if (res.status === 200) {
                const { data } = res.data
                localStorageUtils.setLocalStorageItem('token', data.token.accessToken)
                localStorageUtils.setLocalStorageItem('refreshToken', data.token.refreshToken)
                localStorageUtils.setLocalStorageItem('userId', data.id)
                localStorageUtils.setLocalStorageItem('email', data.email)
                
                userInfo['email'] = data.email ?? ''
                userInfo['firstName'] = data.firstName ?? ''
                userInfo['lastName'] = data.lastName ?? ''
                userInfo['phoneNumber'] = data.phoneNumber ?? ''
                userInfo['policyNumber'] = data.policyNumber ?? ''
                userInfo['type'] = data.type ?? ''
                userInfo['positionTitle'] = data.positionTitle ?? ''
                userInfo['parentId'] = data.parentId ?? ''

                context.handleUserDetails(userInfo);

                localStorageUtils.setLocalStorageItem('user', userInfo)

                navigate('/home')
                document.getElementById('validation_error').innerText = ''
                setShowRecaptcha(false)
            }
        }).catch(err => {
            setIsLoading(false);
            document.getElementById('validation_error').innerText = err?.response?.data?.message?.message ?? defaultConnectionErrorMsg
            setShowRecaptcha(true)
            setIsValid(false)
        })
        if (showRecaptcha) {
            recaptchaRef.current?.reset();
        }
    }

    return (
        <div className={`w-full mb-8 sm:p-3 sm:rounded-none rounded-md ${formWrapperBackground}`}>
            <div className={`w-full rounded-md md:px-14 p-4 font-SansPro ${formBackground} grid shadow-cardShadow-md`}>
                <div className='grid place-items-center relative object-contain'>
                    <img src="/images/light/logo.webp" className='h-138px' alt="client logo" />
                </div>
                <div className='grid gap-2 place-items-center text-center mt-2 mb-8'>
                    <h2 className={`text-2xl font-bold ${formTitleColor}`}>{title}</h2>
                    <p className={formTitleDescriptionColor}>{description}</p>
                </div>
                <div className='grid gap-y-4'>
                    <form onSubmit={(e) => handleLogin(e)} className='grid gap-3'>
                        {
                            formData.map((inputField,key) => {
                                return <input tabIndex={inputField.id} 
                                    key={inputField.id}
                                    value={userCridentials[inputField.name] ?? ''} 
                                    onInvalid={e => e.target.setCustomValidity(inputField.validationText)}
                                    onInput={e => e.target.setCustomValidity('')}
                                    onChange={(e) => handleInputs(e)}
                                    autoComplete="off"
                                    autoFocus= {key === 0 ? true : false}
                                    type={inputField.inputType} 
                                    name={inputField.name} 
                                    id={inputField.id} 
                                    placeholder={inputField.placeholder} 
                                    required={inputField.required} 
                                    className={`h-14 w-full  ${invalidList.includes(inputField.name) ? `border-b-2 ${inputErrorBorderColor} ${invalidInputBgColor}` : `${inputBorderColor} ${inputBackground} ${inputHoverBackground}`} ${placeholderColor} ${inputTextColor} ${ userCridentials[inputField.name] !== null && `invalid:border-b-2 ${invalidInputBorderColor}`} font-semibold placeholder:font-semibold focus:outline-none px-4`} 
                                />
                            })
                        }
                        {showRecaptcha ? <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} className={isRecaptchaValid ? "" : "invalid-recaptcha"} hl={language} /> : null}
                        <button tabIndex={3} disabled={isLoading} className={`w-full  h-14 hover:${loginSignUpBtnHoverColor} ${loginSignUpBtnColor}  ${loginSignUpBtnTextColor}`}>
                            {isLoading ? <LoaderEllipse /> : loginBtnText}
                        </button>
                    </form>
                    <div className={`grid place-items-center ${infoTextColor} gap-1 text-center`}>
                        <p>{accessCodeInfo}</p>
                        <p>{signUpLeadingText} <button onClick={(e) => toggleForm(e)} className='underline'>{existingUserSignUpBtnText}</button></p>
                        <p><button onClick={(e) => toggleForgotPasswordForm(e)} className='underline'>{forgotUserBtnText}</button></p>
                    </div>
                    <p className={`mt-2 text-center text-lg font-bold ${validationErrTextColor} ${isValid ? `hidden` : `block`}`} id="validation_error"></p>
                </div>
            </div>
        </div>
    )
}

export default Login