import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DataContext } from '../../contexts/dataContext'

const Introduction = () => {

    const context = useContext(DataContext);

    //page data
    const draPageIntoBtnText = context?.siteData?.digitalRiskAssessment?.draPageIntoBtnText ?? ''
    const draPageIntoDescription = context?.siteData?.digitalRiskAssessment?.draPageIntoDescription ?? ''
    const draPageIntoTitle = context?.siteData?.digitalRiskAssessment?.draPageIntoTitle ?? ''

    //page style
    const textCol = context?.siteStyles?.digitalRiskAssessment?.introduction?.textCol ?? 'simple-gray'
    const getStartedBtnStyle = context?.siteStyles?.digitalRiskAssessment?.introduction?.getStartedBtnStyle ?? 'orange-bg-btn'

    let textColor = `text-${textCol}`

    return (
        <div className='w-full py-7 '>
            <div className={`space-y-7 font-SansPro ${textColor}`}>
                <h1 className='font-semibold text-base leading-6'>{draPageIntoTitle}</h1>
                <p className='text-sm font-normal'>{draPageIntoDescription}</p>
            </div>
            <div className='w-full pt-32'>
                <div className='w-full flex justify-center'>
                    <Link to={'/digital_risk_assessment/assessment'}>
                        <button className={getStartedBtnStyle}>
                            {draPageIntoBtnText}
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Introduction