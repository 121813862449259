import React, { useContext, useEffect } from 'react'

import DynamicInnerPageWrapper from '../../components/cyber-training/dynamic-inner-page-wrapper'
import { DataContext } from '../../contexts/dataContext';


const DigitalSafety = () => {

    const context = useContext(DataContext);

    const data = context?.siteData?.cyberSafetyTraining?.moduleData?.digitalSafetyData ?? {};

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    return (
        <div className='w-full'>
            <DynamicInnerPageWrapper
                data={data}
                moduleName="EMPLOYEE_DIGITAL_SAFETY"
            />
        </div>
    )
}

export default DigitalSafety