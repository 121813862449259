import React, { useContext } from 'react'
import GridWrapper from '../common/grid-wrapper'
import Card from './card'
import { DataContext } from '../../contexts/dataContext'


const ContentLlist = () => {
  const context = useContext(DataContext);

  let cyberSafetyDataList = context?.siteData?.cyberSafetyTraining?.cyberSafetyDataList ?? {}
  
  return (
    <GridWrapper>
        {Object.entries(cyberSafetyDataList).map((card)=>(
            <Card
                key={card[0]}
                header={card[1].header}
                image={card[1].image}
                description={card[1].description}
                link={card[1].path}
            />
        ))}
    </GridWrapper>
  )
}

export default ContentLlist