import React, { useEffect, useState, useContext } from 'react'
import api from '../../api/axiosConfig'
import { header } from '../../helpers/constants'
import { localStorageUtils } from '../../helpers/storageHelper'
import { DataContext } from '../../contexts/dataContext';

import DataTable from 'react-data-table-component';
import moment from 'moment';


const TeamReview = ({ teamReviewTitle, teamReviewTableHeader, teamReviewModuleFilters, teamReviewModuleInitialFilter, defaultErrorMsg, defaultLoadingText }) => {
    const context = useContext(DataContext);

    const [filters, setFilters] = useState(teamReviewModuleInitialFilter)
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    const [tableData, setTableData] = useState([])
    //page style's
    const homePageTitleCol = context?.siteStyles?.homePage?.homePageTitleCol ?? 'extra-dark-gray'
    const noDataComponentText = context?.siteData?.common?.noDataComponentText ?? 'There are no records to display'

    let homePageTitleColor = `text-${homePageTitleCol}`
    // eslint-disable-next-line
    const [tableColumns, setTableColumns] = useState([
        {
            "name": teamReviewTableHeader[0].name,
            "selector": row => row.firstName,
            "sortable": true
        },
        {
            "name": teamReviewTableHeader[1].name,
            "selector": row => row.lastName,
            "sortable": true
        },
        {
            "name": teamReviewTableHeader[2].name,
            "selector": row => row.email,
            "sortable": true
        },
        {
            "name": teamReviewTableHeader[3].name,
            "selector": row => Object.values(row.scoreDetails).length > 0 ? `${row.scoreDetails.score} / ${row.scoreDetails.total}` : '',
            "sortable": true
        },
        {
            "name": teamReviewTableHeader[4].name,
            "selector": row => Object.values(row.scoreDetails).length > 0 ? moment(row.scoreDetails.examDate).format('MMM DD, YYYY') : '',
            "sortable": true
        },
        {
            "name": teamReviewTableHeader[5].name,
            "selector": row => row.scoreDetails.status,
            "sortable": true,
            "conditionalCellStyles": [
                {
                    when: row => row.scoreDetails.score >= row.scoreDetails.passLimit,
                    classNames: ['passed']
                },
                {
                    when: row => row.scoreDetails.score < row.scoreDetails.passLimit,
                    classNames: ['failed']
                }
            ]
        }
    ])

    const moduleFilters = teamReviewModuleFilters

    const handleModuleFilter = (e) => {
        let modlueIndex = moduleFilters.findIndex(module => module.name === e.target.value)
        setFilters(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
            moduleLabel: moduleFilters[modlueIndex].label
        }))
        setIsLoading(true)
    }

    useEffect(() => {
        let newHeader = { ...header, "X-ACCESS-TOKEN": localStorageUtils.getLocalStoragItem('token') ?? '' }
        api.post(`/auth/user/team/results`, { module: filters.module ?? '', lang: localStorageUtils.getLocalStoragItem('lang') ?? 'EN' }, { headers: newHeader }).then((res) => {
            if (res.status === 200) {
                const { data } = res.data
                setTableData(data)
                setIsError(false)
                setIsLoading(false)
            } else {
                setIsError(true)
                setIsLoading(false)
            }
        })
            .catch((err) => {
                console.log(err)
                setIsError(true)
                setIsLoading(false)
            })
    }, [filters])
    return (
        <div className='w-full space-y-6 py-8'>
            <div className='w-full'>
                <div className={`flex items-center ${homePageTitleColor} sm:justify-between justify-start`}>
                    <h1 className={`font-semibold text-xl ${homePageTitleColor}`}>{teamReviewTitle}</h1>
                    {moduleFilters.length > 0 &&
                        <select onChange={(e) => handleModuleFilter(e)} value={filters.module} name="module" id="module" className='sm:block hidden border border-input-border px-4 py-1.5 rounded-md focus:outline-none text-sm'>
                            {
                                moduleFilters.map((module) => (
                                    <option value={module.name} key={module.id}>{module.label}</option>
                                ))
                            }
                        </select>
                    }
                </div>
                <h1 className='text-lg'>{filters.moduleLabel}</h1>
                <div className='w-full py-3 sm:hidden flex justify-end'>
                    {moduleFilters.length > 0 &&
                        <select onChange={(e) => handleModuleFilter(e)} value={filters.module} name="module" id="module" className='border border-input-border px-4 py-1.5 rounded-md focus:outline-none text-sm'>
                            {
                                moduleFilters.map((module) => (
                                    <option value={module.name} key={module.id}>{module.label}</option>
                                ))
                            }
                        </select>
                    }
                </div>
            </div>
            <div className='w-full'>
                {
                    isLoading ?
                        <div className='w-full flex items-center justify-center h-48 font-semibold'>
                            <h1 className='text-medium-gray animate-pulse'>{defaultLoadingText}</h1>
                        </div>
                        :
                        teamReviewTableHeader.length > 0 ?
                            <DataTable
                                columns={tableColumns}
                                data={tableData}
                                noDataComponent={noDataComponentText}
                            />
                            :
                            <div className='w-full flex items-center justify-center h-48 font-semibold'>
                                {
                                    isError ?
                                        <h1 className='text-err-text'>{defaultErrorMsg}</h1>
                                        :
                                        <h1 className='text-medium-gray animate-pulse'>{defaultLoadingText}</h1>
                                }
                            </div>
                }
            </div>
        </div>
    )
}

export default TeamReview