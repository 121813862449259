import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SideNav from '../navbar/sideNav'
import { DataContext } from '../../contexts/dataContext'

export const InnerHeader = ({ hasBackBtn, hasHomeBtn, isBackToPage, handleContentChange, hasBorder }) => {
    const navigate = useNavigate()
    const [isSideNav, setIsSideNav] = useState(false)

    const context = useContext(DataContext)

    const toggleSideNav = () => {
        setIsSideNav(!isSideNav);
    }

    const navigateBack = () => {
        if (!isBackToPage && isBackToPage !== undefined) {
            handleContentChange('', 'back')
        } else {
            if (hasHomeBtn) {
                navigate('/home')
            } else {
                navigate(-1)
            }
        }
    }
    const navigateToBack = context?.siteData?.common?.navigateToBack ?? ''
    const navigateToHome = context?.siteData?.common?.navigateToHome ?? ''

    //page style's
    const homeBackBtnCol = context?.siteStyles?.common?.innerHeader?.homeBackBtnCol ?? 'medium-purple'
    const borderCol = context?.siteStyles?.common?.innerHeader?.borderCol ?? 'extra-lite-gray'

    let homeBackBtnColor = `text-${homeBackBtnCol}`
    let borderColor = `border-${borderCol}`

    return (
        <div className={`w-full py-5 ${hasBorder ? 'border-b' : 'border-none'} ${borderColor}`}>
            <div className={`w-full grid grid-cols-3`}>
                <button onClick={navigateBack} className={`flex items-center  space-x-3 ${homeBackBtnColor} ${hasBackBtn ? `visible` : `invisible`}`}>
                    <img src="/images/chevron-up-purple.png" alt="uparrow" />
                    <p className={`text-sm font-medium hover:underline`}>{hasHomeBtn ? navigateToHome : navigateToBack}</p>
                </button>
                <div className='flex items-center justify-center'>
                    <div className='w-75px h-10 flex items-center justify-center md:hidden'>
                        <Link to={'/home'}>
                            <img className={``} src="/images/dark/solutionProvider.svg" alt="logo" />
                        </Link>
                    </div>
                </div>
                <div className='flex items-center justify-end'>
                    <button onClick={() => toggleSideNav()} className='min-w-max visible md:invisible'>
                        <img src="/images/dark/user-icon.png" alt="user-icon" />
                    </button>
                </div>
                <SideNav
                    isSideNav={isSideNav}
                    toggleSideNav={toggleSideNav}
                />
            </div>
        </div>
    )
}
