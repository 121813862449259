import React, { useContext } from 'react'
import { DataContext } from '../../contexts/dataContext'

function HeadingText({title, hasBorder=true}) {
    const context = useContext(DataContext)
    
    //page style's
    const homePageTitleCol = context?.siteStyles?.homePage?.homePageTitleCol ?? 'extra-dark-gray'
    const homePageTitleContainerBorder = context?.siteStyles?.homePage?.homePageTitleContainerBorder ?? 'lite-gray'

    let homePageTitleColor = `text-${homePageTitleCol}`
    let homePageTitleContainerBorderColor = `border-${homePageTitleContainerBorder}`
    return (
        <div className={`pb-3.5 ${hasBorder ? 'border-b' : 'border-none'} ${homePageTitleContainerBorderColor}`}>
            <h1 className={`font-semibold text-xl ${homePageTitleColor} text-center leading-5 font-Publico`}>{title}</h1>
        </div>
    )
}

export default HeadingText