import React, { useContext } from 'react'
import { DataContext } from '../../../contexts/dataContext'

const AssessmentFinal = ({ assessmentMailId, handleAssessmentMailId, isInvalidEmail,showEmail }) => {
  const context = useContext(DataContext);

  //site data
  const cyberSafetyAssessmentMailInputTitle = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentMailInputTitle ?? ''
  const cyberSafetyAssessmentMailInputPlaceholder = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentMailInputPlaceholder ?? ''
  const cyberSafetyAssessmentCompletedMsg = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentCompletedMsg ?? ''
  const cyberSafetyAssessmentCompletedSubMsg = context?.siteData?.cyberSafetyTraining?.cyberSafetyAssessmentCompletedSubMsg ?? ''

  //site styles
  const cyberSafetyAssessmentMailInputTitleCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentMailInputTitleCol ?? 'dark-gray'
  const cyberSafetyAssessmentMailInputBorderCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentMailInputBorderCol ?? 'input-border'
  const cyberSafetyAssessmentMailInputPlaceholderCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentMailInputPlaceholderCol ?? 'medium-gray'
  const cyberSafetyAssessmentCompletedMsgCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentCompletedMsgCol ?? 'extra-dark-gray'
  const cyberSafetyAssessmentCompletedSubMsgCol = context?.siteStyles?.cyberSafetyTraining?.cyberSafetySections?.assessment?.cyberSafetyAssessmentCompletedSubMsgCol ?? 'dark-gray'

  const invalidInputErrText = context?.siteData?.common?.invalidInputEmail ?? ''
  const inputErrorBorderCol = context?.siteStyles?.common?.inputs?.errTextCol ?? 'err-input-border'

  const cyberSafetyAssessmentMailInputTitleColor = `text-${cyberSafetyAssessmentMailInputTitleCol}`
  const cyberSafetyAssessmentMailInputBorderColor = `border-${cyberSafetyAssessmentMailInputBorderCol}`
  const cyberSafetyAssessmentMailInputPlaceholderColor = `placeholder:text-${cyberSafetyAssessmentMailInputPlaceholderCol}`
  const cyberSafetyAssessmentCompletedMsgColor = `text-${cyberSafetyAssessmentCompletedMsgCol}`
  const cyberSafetyAssessmentCompletedSubMsgColor = `text-${cyberSafetyAssessmentCompletedSubMsgCol}`
  
  const inputErrorBorderColor = `border-${inputErrorBorderCol}`

  return (
    <div className='w-full flex flex-col items-center text-center space-y-2'>
      <h1 className={`text-lg font-semibold font-Publico ${cyberSafetyAssessmentCompletedMsgColor}`}>{cyberSafetyAssessmentCompletedMsg}</h1>
      <div className={`text-sm ${cyberSafetyAssessmentCompletedSubMsgColor} font-SansPro font-normal w-52`}>
        <p>{cyberSafetyAssessmentCompletedSubMsg}</p>
        <p>{showEmail}</p>
      </div>
      {
        showEmail &&
        <div className={`w-full space-y-1 text-start text-sm ${cyberSafetyAssessmentMailInputTitleColor}`}>
          <label htmlFor="emailId" className=''>{cyberSafetyAssessmentMailInputTitle}</label>
          <input
            type="email"
            name="emailId"
            id="emailId"
            autoFocus
            required={showEmail ? true : false}
            onChange={(e) => handleAssessmentMailId(e.target.value)}
            value={assessmentMailId ?? ''}
            placeholder={cyberSafetyAssessmentMailInputPlaceholder}
            className={`w-11/12 py-2 px-4 rounded-md border ${cyberSafetyAssessmentMailInputPlaceholderColor} placeholder:text-sm focus:outline-none ${isInvalidEmail ? inputErrorBorderColor : cyberSafetyAssessmentMailInputBorderColor} `} />
          {showEmail && (isInvalidEmail && <p className='text-err-text text-xs' id='err_email'>{invalidInputErrText}</p>)}
        </div>
      }
    </div>
  )
}

export default AssessmentFinal