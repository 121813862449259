import React, { useContext, useState, useEffect } from 'react'
import DoughnutChart from '../charts/doughnut-chart'
import { DataContext } from '../../contexts/dataContext';
import CyberSecurityReport from './cyber-security-report';


const AssessmentScore = ({ chartData, chartOptions, scoreChartWidth, totalMarkScored, userScoreRange, handleContentChange, grandTotal, scoreStaringRange, mailsendingstatus, handleSendEmailToLoginedUser }) => {

  const context = useContext(DataContext);

  //page data
  const draScoreSectionTitle = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draScoreSectionTitle ?? ''
  const draScoreSectionTitleDesription = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draScoreSectionTitleDesription ?? []
  const draScoreSectionDescription = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draScoreSectionDescription ?? ''
  const draScoreSectionSubTitle = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draScoreSectionSubTitle ?? ''
  const draEmailMessageFail = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draEmailMessageFail ?? ''
  const draEmailMessageSuccess = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draEmailMessageSuccess ?? ''
  const draEmailBtnText = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draEmailBtnText ?? ''
  const draResultClasification = context?.siteData?.digitalRiskAssessment?.draResultSection?.draResultClasification ?? {}
  const lowScoreBgCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.lowScoreBgCol ?? 'err-input-border'
  const mediumScoreBgCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.mediumScoreBgCol ?? 'warning'
  const poorRangeScore = context?.siteData?.digitalRiskAssessment?.poorRangeScore ?? 67
  const goodRangeScore = context?.siteData?.digitalRiskAssessment?.goodRangeScore ?? 116
  const titleScore = context?.siteData?.digitalRiskAssessment?.draScoreSection?.titleScore ?? ''
  const draScoreSectionEmailReport = context?.siteData?.digitalRiskAssessment?.draScoreSection?.draScoreSectionEmailReport ?? ''

  //page styles
  const lightDescriptionCol = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.lightDescriptionCol ?? 'medium-gray'
  const descriptionCol = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.descriptionCol ?? 'simple-gray'
  const draEmailBgOverlay = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailBgOverlay ?? 'simple-gray'
  const draEmailBgAlert = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailBgAlert ?? 'white'
  const draEmailBtnStyle = context?.siteStyles?.digitalRiskAssessment?.draScoreSection?.draEmailBtnStyle ?? 'orange-border-btn'
  const highScoreBgCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.highScoreBgCol ?? 'success'
  const lowScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.lowScoreTextCol ?? 'err-input-border'
  const mediumScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.mediumScoreTextCol ?? 'extra-dark-yellow'
  const highScoreTextCol = context?.siteStyles?.digitalRiskAssessment?.draResultSection?.highScoreTextCol ?? 'success'

  let lowScoreBgColor = `bg-${lowScoreBgCol}`
  let mediumScoreBgColor = `bg-${mediumScoreBgCol}`
  let highScoreBgColor = `bg-${highScoreBgCol}`
  let lowScoreTextColor = `text-${lowScoreTextCol}`
  let mediumScoreTextColor = `text-${mediumScoreTextCol}`
  let highScoreTextColor = `text-${highScoreTextCol}`
  let lightDescriptionColor = `text-${lightDescriptionCol}`
  let descriptionColor = `text-${descriptionCol}`

  let emailBgOverlay = `bg-${draEmailBgOverlay}`
  let emailBgAlert = `bg-${draEmailBgAlert}`
  let sendMailToLoginedUser = false;

  const [sendMailFail, setPopupMessage] = useState(mailsendingstatus);

  const [message, setMessage] = useState(mailsendingstatus ? draEmailMessageSuccess : draEmailMessageFail);

  useEffect(() => {
    setPopupMessage(mailsendingstatus); 
    setMessage(mailsendingstatus ? draEmailMessageFail : draEmailMessageSuccess);
  }, [mailsendingstatus, draEmailMessageFail, draEmailMessageSuccess]);
 
  const handlePopupVisibility = () => {
    setPopupMessage(undefined);
    setMessage("");
  };
  useEffect(() => { 
    document.body.style.overflow = sendMailFail !== undefined ? "hidden" : "auto";
  }, [sendMailFail])

  return (
    <div className='w-full space-y-7 py-7'>
      <div className={`w-full space-y-3`}>
        <h1 className='font-semibold font-Publico text-4xl tracking-wide '>{draScoreSectionTitle}</h1>
        <h2 className='text-lg tracking-wide font-SansPro'>{draScoreSectionTitleDesription}</h2>
        <div className="w-full flex justify-center text-center pb-4.5 border-b border-extra-lite-gray"></div>

        <div>
          {sendMailFail !== undefined && (
            <div className={`fixed inset-0 flex items-center justify-center z-50 ${emailBgOverlay} bg-opacity-50`}>
              <div className={`${emailBgAlert} font-SansPro rounded-lg shadow-lg p-4 w-300 m-5`}>
                <p>{message}</p>

                <div className="mt-4 flex justify-center">
                  <button className={draEmailBtnStyle}
                    onClick={handlePopupVisibility} >{draEmailBtnText}</button>
                </div>
              </div>
            </div>

          )}
        </div>
      </div>
      <div className='flex flex-col space-y-9'>
        <div className="flex flex-col md:flex-row">
          <div className="order-last md:order-first w-full mr-5 md:w-3/5">
            <h2 className="text-md mb-4">{draScoreSectionSubTitle}</h2>
            <div className={`w-full space-y-3 text-sm ${lightDescriptionColor}   pt-4 pr-95`}>
              {
                draScoreSectionDescription.map((description, index) =>
                  <p className='font-medium font-SansPro' key={index} dangerouslySetInnerHTML={{ __html: description }} />)
              }
            </div>
            <CyberSecurityReport
              handleContentChange={handleContentChange}
            />
          </div>
          <div className="order-first md:order-last w-full md:w-2/5 mb-5">

            <div className='w-full border border-gray-300 shadow-md p-6 relative mt-8'>
              <div className="absolute -top-7 left-0 w-full flex justify-center">
                <div className="bg-black text-white font-bold text-center w-8/12">
                  <h2 className="text-md py-4"> {titleScore}</h2>
                </div>
              </div>
              <div className='w-56 h-56 mx-auto rounded-full mt-10'>
                {chartData && chartOptions && grandTotal && totalMarkScored && userScoreRange &&
                  <DoughnutChart
                    data={chartData}
                    options={chartOptions}
                    isSliced={true}
                    hasIndicator={true}
                    range={[scoreStaringRange, grandTotal]}
                    totalMarkScored={totalMarkScored}
                    userScoreRange={userScoreRange}
                    isSmallChart={false}
                    chartWidth={scoreChartWidth}
                    handleContentChange={handleContentChange}
                  />}
              </div>
              {sendMailToLoginedUser ?
              <div className="w-full flex flex-col justify-center mb-4">
                <button onClick={() => { handleSendEmailToLoginedUser(); }} className="text-orange-500 hover:text-blue-700 underline"> {draScoreSectionEmailReport} </button>
              </div>
                : null}

              <div className='w-full space-y-5'>
                {
                  Object.entries(draResultClasification).map((result) => (
                    <div className='w-full space-y-2.5 bg-gray-100 p-6' key={result[1].type}>
                      <div className='flex items-center space-x-2.5'>
                        <p className={`h-3.5 w-3.5 rounded-full ${result[1].type === 'poor' ? lowScoreBgColor : result[1].type === 'good' ? mediumScoreBgColor : highScoreBgColor} min-w-max`}></p>
                        <h1 className={`${result[1].type === 'poor' ? lowScoreTextColor : result[1].type === 'good' ? mediumScoreTextColor : highScoreTextColor} text-lg font-normal font-Publico pt-1`}>{`${result[1].title} (${result[1].type === 'poor' ?
                          `0-${poorRangeScore}` : result[1].type === 'good' ? `${poorRangeScore + 1}-${goodRangeScore}` : `${goodRangeScore + 1}-${grandTotal}`})`}</h1>
                      </div>
                      <p className={`text-sm font-normal ${descriptionColor}`}>{result[1].description}</p>
                    </div>
                  ))
                }
              </div>
            </div>

          </div>

        </div>

        <div className='w-full space-y-7'>

        </div>
      </div>
    </div>
  )
}

export default AssessmentScore