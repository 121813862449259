import React from 'react'

const CircleLoader = () => {
  return (
    <div className='animate-spin'>
        <img src="/images/loader.png" alt="loader-ellipse" />
    </div>
  )
}

export default CircleLoader