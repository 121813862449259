
/**
 * @type {UserConfig}
 */

const PluginConfig = {
    current_lang: 'en',
    autoclear_cookies: true,
    page_scripts: true,
    cookie_domain: window.location.hostname,
    cookie_path: '/',  
    languages: {}
  };
  
  export default PluginConfig;
  